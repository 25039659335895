/*
 * File: Activity.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 11:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import {
	Card, IconButton, JournalWidget, LocaleLink, Padding
} from '../../../components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled(Padding)`
	position: relative;
	width: 100%;
	flex-direction: column;
	padding-top: 30px;
	padding-bottom: 90px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
`;

export const Decoration = styled.div`
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;
	left: 0px;
	bottom: 0px;
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/green-wave-decoration.png');
	background-size: cover;
	background-position: 50% 40vh;
	background-repeat: no-repeat;
`;

export const ActivityCard = styled(Card)`
	padding: 34px;
	display: flex;
	flex-direction: column;
	height: 100%;
	z-index: 2;
	border-radius: 26px;
	position: relative;
	overflow: hidden;

	.zIndex3 {
		z-index: 3;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 34px 40px 40px;
	}
`;

export const ActionBar = styled.div`
	display: flex;
	position: relative;
	z-index: 2;
	flex-wrap: wrap;
	gap: 10px;

	button {
		min-width: 140px;
		height: 50px;
	}
	.hideFeedback {
		display: none;
	}
	.showFeedback {
		display: flex;
	}
	.hideButton {
		display: none;
	}
	.audioButton {
		width: 170px;
	}
`;

export const Topbar = styled.div`
	display: flex;
	position: relative;
	z-index: 2;
	padding-bottom: 25px;
	text-align: center;
	align-items: center;
	justify-content: center;

	p {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
	button {
		padding: 0px;
	}
`;

export const ActivityContent = styled.div`
	width: 100%;
	height: 100%;
	flex-grow: 1;
	opacity: ${({ $activeTransition }) => ($activeTransition ? 0 : 1)};
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	z-index: 2;
	position: relative;
`;

export const Step = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 10px;
	gap: 30px;

	${({ $showPerspective }) => $showPerspective && css`
		padding-left: 20px;
		padding-right: 20px;
	`}
`;

export const CompleteContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		font-size: 3.5rem;
		margin: 0px 0px 30px;
		color: ${({ theme }) => theme.brandTertiaryBase};
	}
	p {
		margin-top: 15px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const CompleteBackground = styled.div`
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/white-gold-stars-decoration.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 25px;
	left: 50px;
`;

export const IconContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100px;
`;

export const ActivityIntro = styled.div`
	max-width: 800px;
	width: 100%;

	* {
		text-align: center !important;
	}
	.paragraphContent {
		* {
			font-size: 1.3rem;
		}
	}
	.alterAlign {
		text-align: ${({ $isColumnLayout }) => ($isColumnLayout ? 'left' : 'center')} !important;
	}
`;

export const Activity = styled.div`
	max-width: 800px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 40px;
	gap: 30px;
	position: relative;
	z-index: 2;
`;

export const ActivityTableOfContents = styled.div`
	max-width: 800px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	padding-bottom: 40px;
	gap: 30px;
	position: relative;
	z-index: 2;
	background-color: ${({ theme }) => theme.primaryBackground};

	h3 {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const TitleContainer = styled.div`
	h3 {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const ContentContainer = styled.div`
	margin: 10px 0px 0px;
	width: 100%;

	&.solidBg {
		background-color: ${({ theme }) => theme.primaryBackground};
		border-radius: 10px;
	}
	* {
		font-size: 1.1rem;
		text-align: left;
	}
`;

export const MediaContainer = styled.div`
	img {
		width: 100%;
		max-width: 100%;
		border-radius: 10px;
		margin: 0px auto;
		display: block;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		max-width: 100%;
	}
	.video {
		width: 100%;
		max-width: 800px;

		video {
			width: 100%;
		}
	}
`;

export const Image = styled.img``;

export const ActionsContainer = styled.div`
	margin-top: 30px;
`;

export const TimeEstimateContainer = styled.div`
	margin-top: 30px;
`;

export const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: 10px 0px 0px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
		gap: 40px;
	}
`;

export const IntroImage = styled.img`
	margin: 8px 0px 0px;
	border-radius: 4px;
`;

export const ActionsInStep = styled.div`
	display: flex;
	gap: 15px;
	justify-content: center;
	margin: 15px 0px 10px;

	svg {
		color: ${({ theme }) => theme.brandSecondaryBase};
		font-size: 1.5rem;
	}
`;

export const ReflectionContainer = styled.div`
	text-align: left;
	width: 100%;

	textarea {
		margin-top: 15px;
	}
	${({ $isInline }) => $isInline && css`
		* {
			display: inline-block;
		}
		input {
			border-radius: 8px;
			width: 250px;
			display: inline-block;
		}
	`}
`;

export const Bumper = styled.div`
	width: 10px;
	height: 10px;
	display: inline-block;
`;

export const EmbedContainer = styled.div`
	margin-top: 15px;
`;

export const QuizContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	width: 100%;
`;

export const QuizScoreContainer = styled.div`
	padding: 16px;
	background-color: ${({ theme }) => theme.brandPrimaryLightest};
	color: ${({ theme }) => theme.brandPrimaryDark} !important;
	border-radius: 8px;
	margin-top: 20px;

	& > div {
		margin: 0px;
	}
	* {
		font-size: 0.86rem;
		font-weight: 500;
	}
`;

export const QuizQuestion = styled.div`
	p {
		text-align: left;
	}
`;

export const QuizAnswerContainer = styled.div`
	margin: 15px 0px 0px;
`;

export const QuestionFeedbackContainer = styled.div``;

export const QuestionFeedback = styled.div`
	padding: 16px;
	background-color: ${({ theme }) => theme.brandPrimaryLightest};
	border-radius: 8px;
	margin-top: 20px;

	& > div {
		margin: 0px;
	}
	* {
		font-size: 0.86rem;
		color: ${({ theme }) => theme.brandPrimaryDark} !important;
	}
`;

export const AnswerFeedback = styled.div`
	margin-bottom: 10px !important;
	display: flex;
	gap: 8px;
	align-items: center;

	svg {
		font-size: 1.1rem;

		* {
			color: ${({ theme }) => theme.brandPrimaryBase} !important;
		}
	}
	span {
		font-size: 1.0rem;
	}
	* {
		color: ${({ theme }) => theme.brandPrimaryDark};
	}
`;

export const MatchSuccessFeedback = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 16px;
	background-color: ${({ theme }) => theme.brandPrimaryLightest};
	border-radius: 8px;

	svg {
		font-size: 1.1rem;

		* {
			color: ${({ theme }) => theme.brandPrimaryBase} !important;
		}
	}
	span {
		font-size: 1.0rem;
	}
	* {
		color: ${({ theme }) => theme.brandPrimaryDark};
	}
`;

export const JournalContainer = styled.div`
	margin-top: 15px;
	text-align: left;
	width: 100%;
`;

export const Journal = styled(JournalWidget)`
	margin-top: 40px;
`;

export const AllApplyQuizContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 22px;
	width: 100%;
`;

export const QuizStatement = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-bottom: 22px;

	${({ $showBorder }) => $showBorder && css`
		border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
	`}
`;

export const FlexRow = styled.div`
	display: flex;

	${({ $switchOnMobile }) => ($switchOnMobile ? css`
		flex-direction: column;
		gap: 10px;
	` : css`
		flex-direction: row;
		align-items: center;
		gap: 10px;
	`)}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
		align-items: center;
	}
`;

export const StatementContent = styled.div`
	cursor: pointer;
`;

export const StatementOptions = styled.div`
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		gap: 20px;
		flex-direction: row;
	}
`;

export const TermMatchingContainer = styled.div`
	width: 100%;
	display: flex;
	// flex-direction: row;
	flex-direction: column;
	gap: 35px;

	@media only screen and (min-width: 36em) {
		flex-direction: row;
	}
`;

export const TermContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	// gap: 40px;
	width: 100%;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		gap: 15px;
	}
`;

export const MatchTerm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const Term = styled.div`
	padding: 12px;
	background-color: ${({ theme }) => theme.brandSecondaryBase};
	color: ${({ theme }) => theme.primaryBackground};
	border-radius: 8px;
`;

export const MatchLine = styled.div`
	flex-grow: 1;
	border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
`;

export const MatchDefinition = styled.div`
	width: 160px;
	//width: 100%;
	min-height: 60px;
	border-radius: 8px;
	background-image: ${(props) => `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${props.theme.layoutBorder.replace('#', '')}' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='16' stroke-linecap='square'/%3e%3c/svg%3e")`};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		width: 260px;
	}
`;

export const DefinitionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 160px;
	// width: 100%;
	flex-shrink: 0;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		width: 260px;
	}
`;

export const Definition = styled.div`
	padding: 12px;
	border: 1px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 8px;
	cursor: grab;
	background-color: ${({ theme }) => theme.primaryBackground};
`;

export const MatchError = styled.div`
	* {
		color: ${({ theme }) => theme.statusDangerBase};
	}
`;

export const DNDButton = styled.div`
	appearance: none;
	-webkit-touch-callout: "none";
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
	touch-action: none;
`;

export const MatchFeedback = styled.div`
	padding: 16px;
	background-color: ${({ theme }) => theme.brandPrimaryLightest};
	border-radius: 8px;
	margin-bottom: 20px;

	& > div {
		margin: 0px;
	}
	* {
		font-size: 0.86rem;
		color: ${({ theme }) => theme.brandPrimaryDark} !important;
	}
`;

export const CloseButton = styled(IconButton)`
	position: absolute;
	top: 2px;
	right: 0px;
	color: ${({ theme }) => theme.secondaryButton};
	z-index: 9;
`;

export const CheckboxRow = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const ChildView = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	border: 2px solid ${({ theme }) => theme.brandPrimaryBase};
	border-radius: 10px;
	left: 0px;
	top: -20px;
`;

export const ParentView = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	border: 2px solid ${({ theme }) => theme.brandSecondaryBase};
	border-radius: 10px;
	left: 0px;
	top: -20px;
`;

export const ChildViewBadge = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-align: center;
	padding: 10px;
	position: relative;
	z-index: 3;
	margin-bottom: 10px;

	p {
		color: ${({ theme }) => theme.primaryBackground};
	}
`;

export const ParentViewBadge = styled.div`
	background-color: ${({ theme }) => theme.brandSecondaryBase};
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-align: center;
	padding: 10px;
	position: relative;
	z-index: 3;
	margin-bottom: 10px;

	p {
		color: ${({ theme }) => theme.primaryBackground};
	}
`;

export const FeaturePrompt = styled(LocaleLink)`
	display: flex;
	border: 2px solid ${({ theme }) => theme.brandSecondaryBaseDarker};
	padding: 1.2625rem 1.5625rem;
	border-radius: 30px;
	text-decoration: none;
	gap: 10px;
	align-items: center;
	opacity: 1;

	* {
		color: ${({ theme }) => theme.brandSecondaryBaseDarker};
	}
	&:hover {
		opacity: 0.8;
	}
`;

export const TableOfContents = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const TableOfContentContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;

	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
		font-size: 1.3rem;
	}
`;
