/*
 * File: Answers.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 3:21 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card, Padding } from '../../../components';

// Constants
import { mediumBreakpoint, mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled(Padding)`
	position: relative;
	width: 100%;
	flex-direction: column;
	padding-top: 40px;
	padding-bottom: 40px;
`;

export const Decoration = styled.div`
	position: absolute;
	z-index: 1;
	width: 100%;
	min-height: 70vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/green-bump-decoration.png');
	background-size: cover;
	background-position: 50% 0%;
	background-repeat: no-repeat;
`;

export const TitleWidget = styled.div`
	width: 100%;
	min-height: 62px;
	height: auto;
	border-radius: 20px;
	border: 2px solid ${({ theme }) => theme.brandSecondaryBaseDarker};
	display: flex;
	align-items: center;
	z-index: 2;
	flex-shrink: 0;
	flex-direction: column;
	padding: 15px;
	gap: 10px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
		padding: 0px;
		border-radius: 100px;
		height: 62px;
	}
	h4 {
		color: ${({ theme }) => theme.brandSecondaryBaseDarker};
		text-align: left;
		margin-left: 30px;
		flex-grow: 1;
	}
	button {
		height: 62px;
		right: 0px;
		position: relative;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			right: -2px;
		}
	}
`;

export const ContentContainer = styled.div`
	height: 100%; // Centers loader
	text-align: left;
	z-index: 2;
	margin-bottom: 100px;
`;

export const Header = styled.div`
	margin: 30px 0px 0px;
	width: 100%;
	max-width: 100%;

	* {
		text-align: left;
		line-height: 1.6;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		max-width: 75%;
	}
`;

export const QuestionsSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 30px 0px 30px 0px;
`;

export const QuestionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding-top: 5px;
`;

export const QuestionCard = styled(Card)`
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin: 8px 0px 0px;
	cursor: pointer;
`;

export const Question = styled.div`
	display: flex;
	gap: 10px;

	p {
		flex-grow: 1;
	}
	svg {
		flex-shrink: 0;
		color: ${({ theme }) => theme.secondaryButton};
		margin-top: 4px;
	}
`;

export const Answer = styled.div`
	max-width: 100%;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 1100px;
	}
	img {
		width: 100%;
		max-width: 500px;
	}
	.video {
		width: 100%;
		max-width: 800px;
	}
`;

export const QuestionsModule = styled.div`
	* {
		text-align: left;
	}
	& > p {
		color: ${({ theme }) => theme.secondaryText};
		margin-left: 10px;
	}
`;
