/*
 * File: fontawesome.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 27, 2022 at 12:17 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 27, 2024 at 3:59 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { library } from '@fortawesome/fontawesome-svg-core';

// Icons
import {
	faHomeLgAlt,
	faBell,
	faCompass,
	faSearch,
	faPlus,
	faBars,
	faTimes,
	faUserCircle,
	faEllipsisV,
	faHeart,
	faComment,
	faClone,
	faCheck,
	faLongArrowLeft,
	faHashtag,
	faMapMarker,
	faAtlas,
	faGlobeAmericas,
	faBackpack,
	faBinoculars,
	faStar,
	faStars,
	faShare,
	faUserGroup,
	faThumbsUp,
	faCalendar,
	faMaskFace,
	faDesktop,
	faUsers,
	faPeoplePulling,
	faUserChef,
	faChessRook,
	faWheelchair,
	faBabyCarriage,
	faPawSimple,
	faBusSimple,
	faBaby,
	faPersonPregnant,
	faKitMedical,
	faWavePulse,
	faArrowUpFromBracket,
	faFolderArrowUp,
	faLifeRing,
	faBookBlank,
	faHandWave,
	faClock,
	faBullhorn,
	faMountainSun,
	faCircleCheck,
	faBook,
	faScrewdriverWrench,
	faPersonHiking,
	faUser,
	faTrophy,
	faFire,
	faBooks,
	faQuestionCircle,
	faList,
	faMoon,
	faSun,
	faSignOut,
	faLinkSimple,
	faLink,
	faLightbulbOn,
	faLockKeyhole,
	faTreasureChest,
	faPencil,
	faUnlock,
	faVideo,
	faImagePolaroid,
	faImage,
	faSquareList,
	faCrown,
	faFaceSmile,
	faPause,
	faPlay
} from '@fortawesome/pro-light-svg-icons';
import {
	faHomeLgAlt as faHomeLgAltSolid,
	faPlus as faPlusSolid,
	faBell as faBellSolid,
	faCompass as faCompassSolid,
	faUserCircle as faUserCircleSolid,
	faHeart as faHeartSolid,
	faCheckCircle as faCheckCircleSolid,
	faCheck as faCheckSolid,
	faAtlas as faAtlasSolid,
	faGlobeAmericas as faGlobeAmericasSolid,
	faHashtag as faHashtagSolid,
	faStar as faStarSolid,
	faLink as faLinkSolid,
	faCode as faCodeSolid,
	faPaperPlane as faPaperPlaneSolid,
	faPaperPlaneTop as faPaperPlaneTopSolid,
	faEnvelope as faEnvelopeSolid,
	faUser as faUserSolid,
	faPersonWalking as faPersonWalkingSolid,
	faChartSimple as faChartSimpleSolid,
	faCircleCheck as faCircleCheckSolid,
	faBadgeCheck as faBadgeCheckSolid,
	faWifi as faWifiSolid,
	faPlay as faPlaySolid,
	faCircleXmark as faCircleXmarkSolid,
	faCircleX as faCircleXSolid,
	faArrowRight as faArrowRightSolid,
	faArrowLeft as faArrowLeftSolid,
	faTriangleExclamation as faTriangleExclamationSolid,
	faQuestion as faQuestionSolid,
	faAngleRight as faAngleRightSolid,
	faAngleLeft as faAngleLeftSolid,
	faMagnifyingGlass as faMagnifyingGlassSolid,
	faXmark as faXmarkSolid,
	faChevronDown as faChevronDownSolid,
	faChevronUp as faChevronUpSolid,
	faBooks as faBooksSolid,
	faQuestionCircle as faQuestionCircleSolid,
	faBook as faBookSolid,
	faList as faListSolid,
	faLightbulbOn as faLightbulbOnSolid,
	faTreasureChest as faTreasureChestSolid,
	faArrowUpLeft as faArrowUpLeftSolid,
	faCircle as faCircleSolid
} from '@fortawesome/pro-solid-svg-icons';
import {
	faHashtag as faHashtagRegular,
	faMapMarker as faMapMarkerRegular,
	faLandmark as faLandmarkRegular,
	faCircle as faCircleRegular,
	faCheckCircle as faCheckCircleRegular,
	faQuestionCircle as faQuestionCircleRegular,
	faMoon as faMoonRegular,
	faSun as faSunRegular,
	faUser as faUserRegular,
	faSlidersHSquare as faSlidersHSquareRegular,
	faCog as faCogRegular,
	faSignOut as faSignOutRegular,
	faGlobeAmericas as faGlobeAmericasRegular,
	faHeart as faHeartRegular,
	faComment as faCommentRegular,
	faClone as faCloneRegular,
	faClock as faClockRegular,
	faFlagAlt as faFlagAltRegular,
	faBan as faBanRegular,
	faCheck as faCheckRegular,
	faLongArrowLeft as faLongArrowLeftRegular,
	faChevronDown as faChevronDownRegular,
	faChevronUp as faChevronUpRegular,
	faBell as faBellRegular,
	faStar as faStarRegular,
	faPlus as faPlusRegular,
	faLink as faLinkRegular,
	faFolderArrowUp as faFolderArrowUpRegular,
	faArrowDownRight as faArrowDownRightRegular,
	faLightbulbOn as faLightbulbOnRegular
} from '@fortawesome/pro-regular-svg-icons';
import {
	faFacebookF,
	faFacebook,
	faTwitter,
	faInstagram,
	faTiktok,
	faWhatsapp,
	faLinkedin,
	faLinkedinIn,
	faRedditAlien,
	faPinterestP
} from '@fortawesome/free-brands-svg-icons';
import {
	faLifeRing as faLifeRingDuo
} from '@fortawesome/pro-duotone-svg-icons';


/**
 * Init
 */

export function loadIcons() {

	// Add icons to library
	library.add(

		/**
		 * Regular Icons
		 */

		faHashtagRegular,
		faMapMarkerRegular,
		faLandmarkRegular,
		faCircleRegular,
		faCheckCircleRegular,
		faQuestionCircleRegular,
		faMoonRegular,
		faSunRegular,
		faUserRegular,
		faSlidersHSquareRegular,
		faCogRegular,
		faSignOutRegular,
		faGlobeAmericasRegular,
		faHeartRegular,
		faCommentRegular,
		faCloneRegular,
		faClockRegular,
		faFlagAltRegular,
		faBanRegular,
		faCheckRegular,
		faLongArrowLeftRegular,
		faChevronDownRegular,
		faChevronUpRegular,
		faBellRegular,
		faStarRegular,
		faPlusRegular,
		faLinkRegular,
		faFolderArrowUpRegular,
		faArrowDownRightRegular,
		faLightbulbOnRegular,

		/**
		 * Light Icons
		 */

		faHomeLgAlt,
		faSearch,
		faBell,
		faCompass,
		faPlus,
		faBars,
		faTimes,
		faUserCircle,
		faEllipsisV,
		faComment,
		faClone,
		faCheck,
		faLongArrowLeft,
		faHashtag,
		faMapMarker,
		faAtlas,
		faGlobeAmericas,
		faBackpack,
		faBinoculars,
		faStar,
		faStars,
		faHeart,
		faShare,
		faUserGroup,
		faThumbsUp,
		faCalendar,
		faMaskFace,
		faDesktop,
		faUsers,
		faPeoplePulling,
		faUserChef,
		faChessRook,
		faWheelchair,
		faBabyCarriage,
		faPawSimple,
		faBusSimple,
		faBaby,
		faPersonPregnant,
		faKitMedical,
		faWavePulse,
		faArrowUpFromBracket,
		faFolderArrowUp,
		faLifeRing,
		faBookBlank,
		faHandWave,
		faClock,
		faBullhorn,
		faMountainSun,
		faCircleCheck,
		faBook,
		faScrewdriverWrench,
		faPersonHiking,
		faUser,
		faTrophy,
		faFire,
		faBooks,
		faQuestionCircle,
		faList,
		faMoon,
		faSun,
		faSignOut,
		faLinkSimple,
		faLink,
		faLightbulbOn,
		faLockKeyhole,
		faTreasureChest,
		faPencil,
		faUnlock,
		faVideo,
		faImagePolaroid,
		faImage,
		faSquareList,
		faCrown,
		faFaceSmile,
		faPause,
		faPlay,

		/**
		 * Solid Icons
		 */

		faHomeLgAltSolid,
		faPlusSolid,
		faBellSolid,
		faCompassSolid,
		faUserCircleSolid,
		faHeartSolid,
		faCheckCircleSolid,
		faAtlasSolid,
		faGlobeAmericasSolid,
		faHashtagSolid,
		faStarSolid,
		faLinkSolid,
		faCodeSolid,
		faPaperPlaneSolid,
		faPaperPlaneTopSolid,
		faEnvelopeSolid,
		faUserSolid,
		faPersonWalkingSolid,
		faChartSimpleSolid,
		faCircleCheckSolid,
		faBadgeCheckSolid,
		faWifiSolid,
		faPlaySolid,
		faCircleXmarkSolid,
		faCircleXSolid,
		faArrowRightSolid,
		faArrowLeftSolid,
		faTriangleExclamationSolid,
		faQuestionSolid,
		faAngleRightSolid,
		faAngleLeftSolid,
		faMagnifyingGlassSolid,
		faXmarkSolid,
		faChevronDownSolid,
		faChevronUpSolid,
		faBooksSolid,
		faQuestionCircleSolid,
		faBookSolid,
		faListSolid,
		faLightbulbOnSolid,
		faTreasureChestSolid,
		faCheckSolid,
		faArrowUpLeftSolid,
		faCircleSolid,

		/**
		 * Brand Icons
		 */

		faFacebookF,
		faFacebook,
		faTwitter,
		faInstagram,
		faTiktok,
		faWhatsapp,
		faLinkedin,
		faLinkedinIn,
		faRedditAlien,
		faPinterestP,

		/**
		 * Duotone Icons
		 */

		faLifeRingDuo
	);
}
