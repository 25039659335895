/*
 * File: journal.slice.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on June 18, 2024 at 2:04 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: June 18, 2024 at 2:34 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Services
import {
	fetchJournalEntries as getJournalEntries,
	fetchJournalPrompts as getJournalPrompts
} from '../../../services/journal';


/**
 * Functions
 */

export const fetchJournalEntries = createAsyncThunk('journal/fetchJournalEntries', async () => {
	try {

		// Fetch entries
		const { data } = await getJournalEntries();

		// Return entries
		return data.entries;

	} catch (e) {

		// Return null
		return [];
	}
});

export const fetchJournalPrompts = createAsyncThunk('journal/fetchJournalPrompts', async () => {
	try {

		// Fetch prompts
		const { data } = await getJournalPrompts();

		// Return prompts
		return data.prompts;

	} catch (e) {

		// Return null
		return [];
	}
});


/**
 * Slices
 */

export const journalSlice = createSlice({
	name: 'journal',
	initialState: {
		entries: {
			status: 'idle',
			error: null,
			value: [],
		},
		prompts: {
			status: 'idle',
			error: null,
			value: [],
		}
	},
	reducers: {
		updateJournalEntries: (state, action) => {

			// Set state
			state.entries.value = action.payload;
			state.entries.status = 'succeeded';
		},
		clearJournalEntries: (state) => {

			// Clear state
			state.entries.value = [];
		},
		updateJournalPrompts: (state, action) => {

			// Set state
			state.prompts.value = action.payload;
			state.prompts.status = 'succeeded';
		},
		clearJournalPrompts: (state) => {

			// Clear state
			state.prompts.value = [];
		},
	},
	extraReducers: {
		[fetchJournalEntries.pending]: (state) => {
			state.entries.status = 'loading';
		},
		[fetchJournalEntries.fulfilled]: (state, action) => {
			state.entries.status = 'succeeded';
			state.entries.value = action.payload;
		},
		[fetchJournalEntries.rejected]: (state, action) => {
			state.entries.status = 'failed';
			state.entries.error = action.error.message;
		},
		[fetchJournalPrompts.pending]: (state) => {
			state.prompts.status = 'loading';
		},
		[fetchJournalPrompts.fulfilled]: (state, action) => {
			state.prompts.status = 'succeeded';
			state.prompts.value = action.payload;
		},
		[fetchJournalPrompts.rejected]: (state, action) => {
			state.prompts.status = 'failed';
			state.prompts.error = action.error.message;
		},
	},
});


/**
 * Exports
 */

export const {
	updateJournalEntries, clearJournalEntries, updateJournalPrompts, clearJournalPrompts
} = journalSlice.actions;

export default journalSlice.reducer;
