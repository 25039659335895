/*
 * File: rewards.slice.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on May 13, 2024 at 11:55 AM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: May 13, 2024 at 12:01 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { fetchRewards as getRewards } from '../../../services/reward';


/**
 * Functions
 */

export const fetchRewards = createAsyncThunk('rewards/fetchRewards', async () => {
	try {

		// Fetch rewards
		const { data } = await getRewards();

		// Return rewards
		return data.rewards;

	} catch (e) {

		// Return null
		return null;
	}
});


/**
 * Slices
 */

export const rewardSlice = createSlice({
	name: 'rewards',
	initialState: {
		status: 'idle',
		error: null,
		value: [],
	},
	reducers: {
		updateRewards: (state, action) => {

			// Set state
			state.value = action.payload;
			state.status = 'succeeded';
		},
		clearRewards: (state) => {

			// Clear state
			state.value = [];
		},
	},
	extraReducers: {
		[fetchRewards.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchRewards.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchRewards.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});


/**
 * Exports
 */

export const { updateRewards, clearRewards } = rewardSlice.actions;

export default rewardSlice.reducer;
