/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on May 13, 2024 at 11:53 AM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: May 13, 2024 at 11:54 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const fetchRewards = (data) => api.get('/rewards', data);

export const createReward = (data) => api.post('/rewards', data);
