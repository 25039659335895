/*
 * File: Join.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on April 8, 2024 at 8:51 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:24 PM
 * Modified By: Brendan Michaelsen
 */

// Modules
import styled from 'styled-components';

// Components
import { Card, Padding } from '../../../components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
	text-align: center;

	.uiModeWidget {
		position: absolute;
		z-index: 2;
		top: 10px;
		right: 10px;
	}
`;

export const Logo = styled.img`
	width: 80px;
	margin: 40px auto;
	text-align: center;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		width: 100px;
		margin: 60px auto;
	}
`;

export const Decoration = styled.div`
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;
	left: 0px;
	bottom: 0px;
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/green-wave-decoration.png');
	background-size: cover;
	background-position: 50% 40vh;
	background-repeat: no-repeat;
`;

export const StepContainer = styled.div`
	position: relative;
	overflow: hidden;
	z-index: 2;
	height: ${({ stepHeight }) => (stepHeight ? `${stepHeight}px` : 'auto')};
`;

export const Step = styled.div`
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 0px;
	pointer-events: none;
	width: 100%;

	&.show {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.3s;
		position: relative;
		pointer-events: initial;
	}
`;

export const StepInner = styled(Padding)`
	width: 100%;
	justify-content: center;

	.cardPadding {
		padding: 50px;
	}
	@media only screen {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 20px;
		padding-bottom: 30px;
	}
`;

export const StepCard = styled(Card)`
	position: relative;
	z-index: 3;
`;
