/*
 * File: Topbar.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:37 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useRef
} from 'react';
import { useSelector } from 'react-redux';

// Utilities
import { renderLogo } from '../../../utilities/image';

// Components
import { Padding } from '../../Padding';
import { LocaleLink } from '../../LocaleLink';
import { UIModeWidget } from '../../UIModeWidget';

// Styles
import * as S from './Topbar.styles';
import { Typography } from '../../Typography';


/**
 * Component
 */

export const Topbar = () => {

	// Create references for elements
	const isMounted = useRef(true);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle actions on component load
	useEffect(() => {

		// Initialize mounted state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<S.Topbar>
			<Padding left right dynamic>
				<S.TopbarWrapper>
					<LocaleLink to="/">
						<S.LogoImage src={renderLogo(uiMode)} alt="Let's Talk Logo" title="Let's Talk Logo" />
					</LocaleLink>
				</S.TopbarWrapper>
				<S.TopbarWrapper fullWidth />
				<S.TopbarWrapper>
					<S.NavigationLink className="isNotMobile" to="/login">
						<Typography weight="semibold" variation="2">Log In</Typography>
					</S.NavigationLink>
					<UIModeWidget />
				</S.TopbarWrapper>
			</Padding>
		</S.Topbar>
	);
};


/**
 * Configuration
 */

Topbar.displayName = 'Topbar';
Topbar.propTypes = {};
Topbar.defaultProps = {};
