/*
 * File: WelcomeStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 12, 2024 at 9:11 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	forwardRef, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { parseFirstLastName } from '../../../../../utilities/utilities';

// Components
import {
	Button, Card, Typography
} from '../../../../components';

// Constants
import { REASON_FOR_USING_OPTIONS, ROLES } from '../../../../../Constants';

// Styles
import * as S from './WelcomeStep.styles';


/**
 * Component
 */

export const WelcomeStep = forwardRef(({
	updateStep, role, className
}, ref) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Handle next function
	const handleNext = async () => {

		// Update step
		if (role === ROLES.PARENT) {
			updateStep(4);
		} else {
			updateStep(3);
		}
	};

	// Render reason text
	const reasonText = useMemo(() => {
		if (user?.reasonForUsing) {
			return REASON_FOR_USING_OPTIONS.find(({ reason }) => reason === user?.reasonForUsing).explanation;
		}
		return 'This app can help you become more comfortable talking with your child about puberty, bodies, and relationships.';
	}, [user?.reasonForUsing]);

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'hand-wave']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h2" weight="bold">
					Welcome,
					{' '}
					{parseFirstLastName(user?.name).firstName}
					!
				</Typography>

				{/* Instructions */}
				<Typography tag="p" variation="1" weight="medium">
					{role === ROLES.PARENT && (
						<>
							{reasonText}
							{' '}
							Now let’s show you how it works!
						</>
					)}
					{role === ROLES.CHILD && (
						<>
							Let’s get you started with using Let’s Talk. Next we are going to show you some cool features, to help you get started.
						</>
					)}
				</Typography>

				{/* Next Button */}
				<Button onClick={handleNext} size="large">Next</Button>

				{/* Back Button */}
				<Button
					onClick={() => { updateStep(role === ROLES.PARENT ? 2 : 1); }}
					size="small"
					variant="text"
					style={{ margin: '10px 0px -5px' }}
				>
					<FontAwesomeIcon icon={['fas', 'arrow-left']} />
					<Typography variation="button-medium" weight="semibold">Go Back</Typography>
				</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

WelcomeStep.displayName = 'WelcomeStep';
WelcomeStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	role: PropTypes.string
};
WelcomeStep.defaultProps = {
	className: null,
	role: null
};
