/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on September 30, 2024 at 11:18 AM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const recordAction = (data) => api.post('/analytics/action', data);
