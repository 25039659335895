/*
 * File: Checkbox.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:58 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 8, 2024 at 2:51 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: inline-flex;
	flex-shrink: 0;
	cursor: pointer;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
	display: block;
	appearance: none;
	cursor: pointer;
	height: 27px;
	width: 27px;
	border-radius: 8px;
	background-color: transparent;
	border: 2px solid ${({ theme }) => theme.brandPrimaryBase};
	opacity: 1;

	&:checked {
		border-color: ${({ theme }) => theme.brandPrimaryBase};
		background-color: ${({ theme }) => theme.brandPrimaryBase};
	}
	${({ disabled }) => disabled && css`
		opacity: 0.45;
		cursor: default;
	`};
`;

export const CheckboxWrapper = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	.checkmark {
		position: absolute;
		width: 1rem;
		height: 1rem;
		color: white;
		pointer-events: none !important;
		opacity: 0;
		cursor: pointer;
	}

	${Checkbox}:checked + .checkmark {
		opacity: 1;
	}
`;
