/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 5:02 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 29, 2024 at 2:35 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const loginUser = (data) => api.post('/login', data, { withCredentials: true });

export const registerUser = ({ email, pass, code }) => api.post('/register', { email, pass, code }, { withCredentials: true });

export const logoutUser = (data) => api.post('/logout', data, { withCredentials: true });
