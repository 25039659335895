/*
 * File: Join.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on April 8, 2024 at 8:51 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { renderLogo } from '../../../utilities/image';

// Components
import {
	Meta, Spinner, UIModeWidget
} from '../../../components';

// Step Components
import { AccessCodeStep } from './AccessCodeStep';
import { SignUpStep } from './SignUpStep';

// Constants
import { ROLES } from '../../../../Constants';

// Styles
import * as S from './Join.styles';


/**
 * Component
 */

const Join = ({
	meta, locale, user
}) => {

	// Create reference for step components
	const [accessCodeStepHeight, setAccessCodeStepHeight] = useState(0);
	const [signUpStepHeight, setSignUpStepHeight] = useState(0);
	const accessCodeStepRef = useRef();
	const signUpStepRef = useRef();

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	const userStatus = useSelector((state) => state.user.status);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Create state handlers
	const [currentStep, setCurrentStep] = useState(1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [accessCode, setAccessCode] = useState(null);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Check if user has required role
	const userHasRequiredRole = userObj?.role
		&& userObj?.role?.primary
		&& (userObj?.role?.primary === ROLES.CHILD || userObj?.role?.primary === ROLES.PARENT || userObj?.role?.primary === ROLES.ADMIN);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setAccessCodeStepHeight(accessCodeStepRef?.current?.clientHeight);
			setSignUpStepHeight(signUpStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setAccessCodeStepHeight(accessCodeStepRef?.current?.clientHeight);
		setSignUpStepHeight(signUpStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return accessCodeStepHeight;
			case 2:
				return signUpStepHeight;
			default:
				return 0;
		}
	};

	// Render component function
	const renderComponent = () => {

		// Render loading state
		if (userStatus !== 'succeeded') {
			return <Spinner showMeta meta={meta} />;
		}

		// Check user state
		if (userObj?.isAnonymous !== true && userObj?.onboardingComplete === true) {

			// Redirect to dashboard
			return <Navigate to={`${localeObj.localePath}/dashboard`} />;
		}
		if (userObj?.isAnonymous !== true) {

			// Redirect to onboarding
			return <Navigate to={`${localeObj.localePath}/join/onboarding`} />;
		}
		if (userObj?.isActive !== true) {

			// Redirect to logout
			window.location = '/logout';
			return null;
		}
		if (!userHasRequiredRole) {

			// Redirect to logout
			window.location = '/logout';
			return null;
		}

		// Render component
		return (
			<>
				{/* Meta */}
				<Meta meta={meta} locale={localeObj} />

				{/* Component Content */}
				<S.Wrapper>

					{/* Logo */}
					<S.Logo src={renderLogo(uiMode)} title="Let's Talk Logo" alt="Let's Talk Logo" />

					{/* Card */}
					<S.StepInner left right>
						<S.StepCard className="cardPadding">

							{/* Step Container */}
							<S.StepContainer className="animate" stepHeight={getStepHeight()}>
								<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
									<AccessCodeStep
										ref={accessCodeStepRef}
										updateStep={setCurrentStep}
										updateHeight={setShouldUpdateHeight}
										isVisible={currentStep === 1}
										setAccessCode={setAccessCode}
									/>
								</S.Step>
								<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
									<SignUpStep
										ref={signUpStepRef}
										updateStep={setCurrentStep}
										updateHeight={setShouldUpdateHeight}
										isVisible={currentStep === 2}
										accessCode={accessCode}
									/>
								</S.Step>
							</S.StepContainer>
						</S.StepCard>
					</S.StepInner>

					{/* UI Mode Widget */}
					<UIModeWidget className="uiModeWidget" />

					{/* Decoration */}
					<S.Decoration />

				</S.Wrapper>
			</>
		);
	};

	// Render component
	return renderComponent();
};


/**
 * Configuration
 */

Join.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	user: PropTypes.shape(),
};
Join.defaultProps = {
	meta: {},
	locale: {},
	user: null,
};


/**
 * Exports
 */

export default Join;
