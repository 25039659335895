/*
 * File: Error.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { renderLogo } from '../../../utilities/image';

// Components
import {
	Meta, ErrorComponent, Padding, UIModeWidget
} from '../../../components';

// Styles
import * as S from './Error.styles';


/**
 * Component
 */

const Error = ({ meta, locale }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper>

				{/* Logo */}
				<S.Logo src={renderLogo(uiMode)} title="Let's Talk Logo" alt="Let's Talk Logo" />

				{/* Error Component */}
				<Padding left right>
					<ErrorComponent />
				</Padding>

				{/* UI Mode Widget */}
				<UIModeWidget className="uiModeWidget" />

			</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

Error.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
Error.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Error;
