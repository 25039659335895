/*
 * File: ProfileImageModal.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 4:50 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useCallback, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { useWindowResize } from 'beautiful-react-hooks';
import { useSelector } from 'react-redux';

// Utilities
import { toastError, toastSuccess } from '../../utilities/toaster';
import { dataURItoBlob } from '../../utilities/image';

// Services
import { uploadUserProfileAvatar } from '../../services/user';

// Constants
import { ACCEPTABLE_FILE_TYPES, FILE_UPLOAD_LIMITS } from '../../../Constants';

// Components
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Typography } from '../Typography';
import { DragDrop } from '../DragDrop';

// Styles
import * as S from './ProfileImageModal.styles';
import 'react-image-crop/dist/ReactCrop.css';


/**
 * Component
 */

export const ProfileImageModal = ({
	className, isOpen, handleClose, updateUser
}) => {

	// Create reference for step components
	const [uploadStepHeight, setUploadStepHeight] = useState(0);
	const [editStepHeight, setEditStepHeight] = useState(0);
	const uploadStepRef = useRef();
	const editStepRef = useRef();

	// Create reference for components
	const isMounted = useRef(true);

	// Create state handlers
	const [currentStep, setCurrentStep] = useState(1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [crop, setCrop] = useState(null);
	const [completedCrop, setCompletedCrop] = useState(null);

	// Create references
	const uploadedUrl = useRef(null);
	const cropImageRef = useRef();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle initial crop for image
	const onImageLoad = () => {

		// Get parameters from image
		const { naturalWidth: width, naturalHeight: height } = cropImageRef.current;

		// Create crop
		const cropObj = centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 100,
				},
				1,
				width,
				height
			),
			width,
			height
		);

		// Update crop
		setCrop(cropObj);
	};

	// Handle image processing
	const getCroppedImg = async (image, cropObj) => {

		// Create canvas
		const canvas = document.createElement('canvas');

		// Get canvas context
		const ctx = canvas.getContext('2d');

		// Get scale parameters
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;

		// DevicePixelRatio slightly increases sharpness on retina devices
		// at the expense of slightly slower render times and needing to
		// size the image back down if you want to download/upload and be
		// true to the images natural size.
		const pixelRatio = window.devicePixelRatio;

		// Set canvas dimensions
		canvas.width = Math.floor(cropObj.width * scaleX * pixelRatio);
		canvas.height = Math.floor(cropObj.height * scaleY * pixelRatio);

		// Set context parameters
		ctx.scale(pixelRatio, pixelRatio);
		ctx.imageSmoothingQuality = 'high';

		// Create crop parameters
		const cropX = cropObj.x * scaleX;
		const cropY = cropObj.y * scaleY;
		const centerX = image.naturalWidth / 2;
		const centerY = image.naturalHeight / 2;

		// Save context
		ctx.save();

		// Move the crop origin to the canvas origin (0,0)
		ctx.translate(-cropX, -cropY);

		// Move the origin to the center of the original position
		ctx.translate(centerX, centerY);

		// Scale the image
		ctx.scale(1, 1);

		// Move the center of the image to the origin (0,0)
		ctx.translate(-centerX, -centerY);
		ctx.drawImage(
			image,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
		);

		// Restore the context
		ctx.restore();

		// Create image blob from image
		return new Promise((resolve) => {
			const imageData = canvas.toDataURL('image/jpeg');
			const blob = dataURItoBlob(imageData);
			resolve(blob);
		});
	};

	// Handle move back a step
	const previousStep = () => {
		if (currentStep === 2) { // Move from edit to upload

			// Update state
			setCrop(null);
			setCurrentStep(1);
		}
	};

	// Handle move forward a step
	const nextStep = () => {
		if (currentStep === 1) { // Move from upload to edit

			// Validate file upload
			if (!uploadedFile) {
				toastError(uiMode, 'Please upload a new profile picture to continue.');
				return;
			}

			// Initialize crop
			onImageLoad();

			// Update state
			setCurrentStep(2);
		}
	};

	// Handle uploading image and updating user profile
	const handleUploadUpdate = async () => {

		// Validate image crop
		if (!cropImageRef.current || !crop) {
			toastError(uiMode, 'Please crop your new profile picture to continue.');
			return;
		}

		// Update state
		setIsLoading(true);

		// Attempt to process and upload image
		try {

			// Generate cropped file
			const file = await getCroppedImg(cropImageRef.current, completedCrop);

			// Update user profile
			const { data } = await uploadUserProfileAvatar({ file });

			// Show success toast
			toastSuccess(uiMode, 'Success! Your profile picture has been updated.');

			// Set loading state
			setIsLoading(false);

			// Update user
			if (updateUser) updateUser(data.user);

			// Handle completion actions
			setTimeout(() => {
				if (handleClose) handleClose();
			}, 2500);

		} catch ({ response }) {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your profile picture. Please try again.');
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble connecting your profile picture. Please try again.');
			}
		}
	};

	// Create dropzone callback
	const onDrop = useCallback(async (acceptedFiles) => {
		for (let i = 0; i < acceptedFiles.length; i += 1) {
			const file = acceptedFiles[i];

			// Set uploaded file
			setUploadedFile(file);
		}
	}, []);

	// Create accepted file types
	const accept = {};
	ACCEPTABLE_FILE_TYPES.PROFILE_IMAGE.MIME_TYPES.forEach((type) => {
		accept[type] = [];
	});

	// Create dropzone from hook
	const dropzone = useDropzone({
		multiple: false,
		maxFiles: 1,
		minSize: FILE_UPLOAD_LIMITS.PROFILE_IMAGE.min,
		maxSize: FILE_UPLOAD_LIMITS.PROFILE_IMAGE.max,
		accept,
		onDrop
	});

	// Handle supported file types render
	const renderSupportedTypes = () => {
		const fileTypes = ACCEPTABLE_FILE_TYPES.PROFILE_IMAGE.EXTENSIONS.map((extension) => extension.toUpperCase()).join(', ');
		return `Let's Talk supports ${fileTypes}`;
	};

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle actions on component open
	useEffect(() => {
		if (isOpen) {

			// Initialize state
			setCurrentStep(1);
			setShouldUpdateHeight(true);
			setUploadedFile(null);
			setCrop(null);
		}
	}, [isOpen]);

	// Handle actions on uploaded image changed
	useEffect(() => {

		// Update file url
		uploadedUrl.current = uploadedFile ? URL.createObjectURL(uploadedFile) : null;

	}, [uploadedFile]);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setUploadStepHeight(uploadStepRef?.current?.clientHeight);
			setEditStepHeight(editStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setUploadStepHeight(uploadStepRef?.current?.clientHeight);
		setEditStepHeight(editStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return uploadStepHeight;
			case 2:
				return editStepHeight;
			default:
				return 0;
		}
	};

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper variant="large">
			<S.ModalInner>
				<S.StepContainer className="animate" stepHeight={getStepHeight()}>

					{/* Upload Step */}
					<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
						<S.SizeContainer ref={uploadStepRef}>
							<DragDrop dropzone={dropzone}>
								<S.DragZoneInner>
									{uploadedFile
										? (
											<S.FilePreview>
												<S.RemoveButton onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setUploadedFile(null);
												}}
												>
													<FontAwesomeIcon icon={['fal', 'times']} />
												</S.RemoveButton>
												<S.FileContainer src={uploadedUrl.current} />
											</S.FilePreview>
										) : (
											<>
												<FontAwesomeIcon icon={['fal', 'arrow-up-from-bracket']} />
												<Typography tag="h5" weight="bold">Select a profile picture to upload</Typography>
												<Typography tag="h5" weight="light">or drag and drop a file here</Typography>
												<Typography tag="p" variation="3" weight="light">{renderSupportedTypes()}</Typography>
												<Button>Select Files</Button>
											</>
										)}
								</S.DragZoneInner>
							</DragDrop>
						</S.SizeContainer>
					</S.Step>

					{/* Edit Step */}
					<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
						<S.SizeContainer ref={editStepRef}>
							<S.CropContainer>
								<ReactCrop
									crop={crop}
									aspect={1}
									onChange={(_, percentageCrop) => setCrop(percentageCrop)}
									onComplete={(c) => setCompletedCrop(c)}
									keepSelection
								>
									<img src={uploadedUrl.current || ''} onLoad={onImageLoad} alt="Cropped preview" ref={cropImageRef} />
								</ReactCrop>
							</S.CropContainer>
						</S.SizeContainer>
					</S.Step>

				</S.StepContainer>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} variant="outline" variation="secondary" onClick={currentStep === 1 ? handleClose : previousStep}>{currentStep === 1 ? 'Cancel' : 'Back'}</Button>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" onClick={currentStep === 1 ? nextStep : handleUploadUpdate}>{currentStep === 1 ? 'Continue' : 'Update'}</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

ProfileImageModal.displayName = 'ProfileImageModal';
ProfileImageModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	updateUser: PropTypes.func
};
ProfileImageModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	updateUser: null
};
