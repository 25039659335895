/*
 * File: JourneyStone.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: August 6, 2024 at 1:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Typography } from '../Typography';

// Constants
import { ACTIVITY_MODULE_STATUSES, ROLES } from '../../../Constants';

// Styles
import * as S from './JourneyStone.styles';
import { parseFirstLastName } from '../../../utilities/utilities';


/**
 * Constants
 */

const STONES_ASSETS = {
	ACTIVE: `${process.env.CDN_URL}/public/assets/decorations/stones/active.png`,
	COMPLETE: `${process.env.CDN_URL}/public/assets/decorations/stones/complete.png`,
	LOCKED: `${process.env.CDN_URL}/public/assets/decorations/stones/locked.png`
};
const STONE_SIZE = 0.23;
const AVATAR_CONTAINER_TOP = 0.030;
const AVATAR_CONTAINER_GAP = 0.048;
const PARENT_AVATAR_OFFSET = 0.0025;
const PARENT_AVATAR_HEIGHT = 0.048;
const CHILD_AVATAR_HEIGHT = 0.043;
const COMPLETE_LABEL_OFFSET = 0.026;
const PARENT_AVATAR_DECORATION_SIZE = 0.048;
const CHILD_AVATAR_DECORATION_SIZE = 0.043;
const PARENT_AVATAR_DECORATION_TOP = 0.005;
const CHILD_AVATAR_DECORATION_TOP = 0.005;
const LABEL_SIZE = 0.01;
const NAME_LABEL_SIZE = 0.008;
const ICON_SIZE = 0.017;


/**
 * Component
 */

export const JourneyStone = ({
	className, activityModule, position, containerWidth, handleAction
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Check if user is parent
	const isParent = useMemo(() => user?.role?.primary === ROLES.PARENT, [user]);

	// Get primary user module progress
	const primaryUserModuleProgress = useMemo(() => {
		if (activityModule) {

			// Get user parameters
			const { journey } = user;

			// Get module parameters
			const { id: moduleId, unitId } = activityModule;

			// Get unit
			const unit = journey?.find((unitObj) => unitObj.unit === unitId);

			// Get module progress
			const progress = unit?.modules?.find((moduleObj) => moduleObj.module === moduleId) || { status: ACTIVITY_MODULE_STATUSES.NOT_STARTED };

			// Return module progress
			return progress;
		}
		return {};
	}, [user, activityModule]);

	// Get secondary user module progress
	const secondaryUsersModuleProgress = useMemo(() => {
		if (activityModule) {

			// Get module parameters
			const { id: moduleId, unitId } = activityModule;

			// Create progress for user role
			if (user.role.primary === ROLES.CHILD && user.parent) {

				// Get parent parameters
				const { journey } = user.parent;

				// Get unit
				const unit = journey?.find((unitObj) => unitObj.unit === unitId);

				// Get module progress
				const progress = unit?.modules?.find((moduleObj) => moduleObj.module === moduleId) || { status: ACTIVITY_MODULE_STATUSES.NOT_STARTED };

				// Return progress
				return [{ ...progress, user: user.parent }];

			} if (user.role.primary === ROLES.PARENT && user.children) {

				// Build progress objects for children
				return user.children.map((child) => {

					// Get parent parameters
					const { journey } = child;

					// Get unit
					const unit = journey?.find((unitObj) => unitObj.unit === unitId);

					// Get module progress
					const progress = unit?.modules?.find((moduleObj) => moduleObj.module === moduleId) || { status: ACTIVITY_MODULE_STATUSES.NOT_STARTED };

					// Return progress
					return { ...progress, user: child };

				});
			}
		}
		return [];
	}, [user, activityModule]);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Handle render primary avatar
	const renderAvatar = () => (
		<S.AvatarContainer style={{
			top: `${AVATAR_CONTAINER_TOP * containerWidth}px`,
			gap: `${AVATAR_CONTAINER_GAP * containerWidth}px`
		}}
		>
			{primaryUserModuleProgress?.isCurrentStep && (
				<S.AvatarOption style={{ top: isParent ? `-${PARENT_AVATAR_OFFSET * containerWidth}px` : '0px' }}>
					<S.Avatar
						src={user.avatarImage.image}
						alt="I am here"
						title="I am here"
						style={{ height: `${(isParent ? PARENT_AVATAR_HEIGHT : CHILD_AVATAR_HEIGHT) * containerWidth}px` }}
					/>
					<S.AvatarDecoration
						style={{
							height: `${(isParent ? PARENT_AVATAR_DECORATION_SIZE : CHILD_AVATAR_DECORATION_SIZE) * containerWidth}px`,
							width: `${(isParent ? PARENT_AVATAR_DECORATION_SIZE : CHILD_AVATAR_DECORATION_SIZE) * containerWidth}px`,
							top: `-${(isParent ? PARENT_AVATAR_DECORATION_TOP : CHILD_AVATAR_DECORATION_TOP) * containerWidth}px`
						}}
					/>
					<Typography
						tag="p"
						variation="3"
						weight="medium"
						style={{ fontSize: `${NAME_LABEL_SIZE * containerWidth}px` }}
					>
						{parseFirstLastName(user?.name).firstName}
					</Typography>
				</S.AvatarOption>
			)}
			{secondaryUsersModuleProgress.map(({ isCurrentStep, user: secondaryUser }) => (
				isCurrentStep && (secondaryUser?.name || secondaryUser?.assignedName) ? (
					<S.AvatarOption key={secondaryUser?.id} style={{ top: !isParent ? `-${PARENT_AVATAR_OFFSET * containerWidth}px` : '0px' }}>
						<S.Avatar
							src={secondaryUser?.avatarImage?.image || `${process.env.CDN_URL}/public/assets/avatars/default-avatar.png`}
							alt="User is here"
							title="User is here"
							style={{ height: `${(isParent ? CHILD_AVATAR_HEIGHT : PARENT_AVATAR_HEIGHT) * containerWidth}px` }}
						/>
						<S.AvatarDecoration
							style={{
								height: `${(isParent ? CHILD_AVATAR_DECORATION_SIZE : PARENT_AVATAR_DECORATION_SIZE) * containerWidth}px`,
								width: `${(isParent ? CHILD_AVATAR_DECORATION_SIZE : PARENT_AVATAR_DECORATION_SIZE) * containerWidth}px`,
								top: `-${(isParent ? CHILD_AVATAR_DECORATION_TOP : PARENT_AVATAR_DECORATION_TOP) * containerWidth}px`
							}}
						/>
						<Typography
							tag="p"
							variation="3"
							weight="medium"
							style={{ fontSize: `${NAME_LABEL_SIZE * containerWidth}px` }}
						>
							{parseFirstLastName(secondaryUser?.name || secondaryUser?.assignedName)?.firstName}
						</Typography>
					</S.AvatarOption>
				) : false
			)).filter(Boolean)}
		</S.AvatarContainer>
	);

	// Handle render variant
	const renderVariant = () => {
		if (primaryUserModuleProgress?.status === ACTIVITY_MODULE_STATUSES.COMPLETE || primaryUserModuleProgress?.isCompleted === true) {
			return (
				<>
					<S.Stone
						src={STONES_ASSETS.COMPLETE}
						title="Journey Stone"
						alt="Journey Stone"
						onClick={handleAction}
					/>
					{renderAvatar()}
					<S.Content style={{ top: `${COMPLETE_LABEL_OFFSET * containerWidth}px`, position: 'relative' }}>
						<Typography
							tag="p"
							variation="3"
							weight="semibold"
							style={{ fontSize: `${LABEL_SIZE * containerWidth}px` }}
						>
							{activityModule.name}
						</Typography>
					</S.Content>
				</>
			);
		}
		if (primaryUserModuleProgress?.status === ACTIVITY_MODULE_STATUSES.PENDING_START || primaryUserModuleProgress?.status === ACTIVITY_MODULE_STATUSES.IN_PROGRESS) {
			return (
				<>
					<S.Stone
						src={STONES_ASSETS.ACTIVE}
						title="Journey Stone"
						alt="Journey Stone"
						onClick={handleAction}
					/>
					{renderAvatar()}
					<S.Content>
						<Typography
							tag="p"
							variation="3"
							weight="semibold"
							style={{ fontSize: `${LABEL_SIZE * containerWidth}px` }}
						>
							{activityModule.name}
						</Typography>
					</S.Content>
				</>
			);
		}
		if (primaryUserModuleProgress?.status === ACTIVITY_MODULE_STATUSES.LOCKED) {
			return (
				<>
					<S.Stone
						src={STONES_ASSETS.LOCKED}
						title="Journey Stone"
						alt="Journey Stone"
						onClick={handleAction}
					/>
					{renderAvatar()}
					<S.Content>
						<S.LockIcon
							icon={['fal', 'lock-keyhole']}
							style={{ fontSize: `${ICON_SIZE * containerWidth}px` }}
						/>
						<Typography
							tag="p"
							variation="3"
							weight="semibold"
							style={{ fontSize: `${LABEL_SIZE * containerWidth}px` }}
						>
							{activityModule.name}
						</Typography>
					</S.Content>
				</>
			);
		}
		if (primaryUserModuleProgress?.status === ACTIVITY_MODULE_STATUSES.NOT_STARTED) {
			return (
				<>
					<S.Stone
						src={STONES_ASSETS.LOCKED}
						title="Journey Stone"
						alt="Journey Stone"
						onClick={handleAction}
					/>
					{renderAvatar()}
					<S.Content>
						<Typography
							tag="p"
							variation="3"
							weight="semibold"
							style={{ fontSize: `${LABEL_SIZE * containerWidth}px` }}
						>
							{activityModule.name}
						</Typography>
					</S.Content>
				</>
			);
		}
		return null;
	};

	// Render component
	return (
		<S.Wrapper
			className={className}
			style={{
				left: `${position.left * containerWidth}px`,
				top: `${position.top * containerWidth}px`,
				width: `${STONE_SIZE * containerWidth}px`,
				height: `${STONE_SIZE * containerWidth}px`
			}}
		>
			{renderVariant()}
			<S.Target
				name={`step-${primaryUserModuleProgress?.module}`}
				id={primaryUserModuleProgress?.isCurrentStep ? 'current-active-step' : null}
			/>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

JourneyStone.displayName = 'JourneyStone';
JourneyStone.propTypes = {
	className: PropTypes.string,
	activityModule: PropTypes.shape(),
	position: PropTypes.shape(),
	containerWidth: PropTypes.number,
	handleAction: PropTypes.func
};
JourneyStone.defaultProps = {
	className: null,
	activityModule: null,
	position: {},
	containerWidth: 1500,
	handleAction: null
};
