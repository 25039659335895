/*
 * File: Badge.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';


/**
 * Constants
 */

const SIZES = {
	small: {
		square: css`
			padding: 0.275rem 0.5275rem;
		`,
		pill: css`
			padding: 0.275rem 0.5275rem;
		`,
	},
	medium: {
		square: css`
			padding: 0.35rem 0.5625rem;
		`,
		pill: css`
			padding: 0.35rem 0.875rem;
		`,
	},
	large: {
		square: css`
			padding: 0.35rem 0.5625rem;
		`,
		pill: css`
			padding: 0.45rem 1.1rem;
		`,
	},
};

const ICON_SIZES = {
	small: '0.9rem',
	medium: '1.125rem',
};

const VARIATIONS = {
	square: css`
		border-radius: 0.25rem;
	`,
	pill: css`
		border-radius: 9999px;
	`,
};

const TYPES = {
	primary: {
		solid: css`
			background-color: ${({ theme }) => theme.brandPrimaryBase};
			border-color: ${({ theme }) => theme.brandPrimaryBase};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.brandPrimaryBase};
			color: ${({ theme }) => theme.brandPrimaryBase};
		`,
	},
	neutral: {
		solid: css`
			background-color: ${({ theme }) => theme.secondaryText};
			border-color: ${({ theme }) => theme.layoutBorder};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.layoutBorder};
			color: ${({ theme }) => theme.secondaryText};
		`,
	},
	danger: {
		solid: css`
			background-color: ${({ theme }) => theme.statusDangerBase};
			border-color: ${({ theme }) => theme.statusDangerBase};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.statusDangerBase};
			color: ${({ theme }) => theme.statusDangerBase};
		`,
	},
	warning: {
		solid: css`
			background-color: ${({ theme }) => theme.statusWarningBase};
			border-color: ${({ theme }) => theme.statusWarningBase};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.statusWarningBase};
			color: ${({ theme }) => theme.statusWarningBase};
		`,
	},
	success: {
		solid: css`
			background-color: ${({ theme }) => theme.statusSuccessBase};
			border-color: ${({ theme }) => theme.statusSuccessBase};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${({ theme }) => theme.statusSuccessBase};
			color: ${({ theme }) => theme.statusSuccessBase};
		`,
	},
};


/**
 * Styles
 */

export const Badge = styled.span`
	display: inline-flex;
 	justify-content: center;
 	align-items: center;
 	color: white;
 	user-select: none;
 	cursor: default;
 	border: 1px solid transparent;
 	white-space: nowrap;
	font-weight: 400;

 	${({ iconPlacement }) => (iconPlacement === 'right'
		? css`
			.badge-icon {
				margin-left: 4px;
				order: 1;
			}
		   `
		: css`
			.badge-icon {
				margin-right: 4px;
			}
		`)};

 	${({
		size, type, variation, variant
	}) => css`
	 	.badge-icon {
		 	width: ${ICON_SIZES[size]};
		 	height: ${ICON_SIZES[size]};
	 	}

	 	${SIZES[size][variation]}
	 	${TYPES[type][variant]}
	 	${VARIATIONS[variation]}
 	`}
`;
