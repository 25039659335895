/*
 * File: TutorialFlowStep.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 20, 2024 at 4:36 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mediumBreakpoint, mobileBreakpoint } from '../../../../styles/constants';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: 0px auto;

	@media only screen {
		padding-top: 0px;
		padding-bottom: 30px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 45px;
		padding-bottom: 30px;
	}
	.cardPadding {
		padding: 50px 30px 20px;
		min-height: 600px;
		display: flex;
		flex-direction: column;
	}
`;

export const Footer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40px 0px 0px;
	flex-direction: column-reverse;
	gap: 10px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
`;

export const LogoContainer = styled.div`
	width: 106px;
`;

export const Logo = styled.img`
	height: 42px;
	width: 58px;
	margin: 0px 0px 0px auto;
`;

export const Spacer = styled.div`
	width: 106px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ButtonContainer = styled.div`
	flex-grow: 1;

	button {
		width: 200px;
		margin: 0px auto;
	}
`;

export const SlideContainer = styled.div`
	flex-grow: 1;
	padding: 0px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: ${({ $activeTransition }) => ($activeTransition ? 0 : 1)};
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 10px 30px 0px;
	}
`;

export const Slide = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	flex-grow: 1;
	align-items: center;
	justify-content: center;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
	& > div {
		display: flex;
 	 	justify-content: center;
	}
`;

export const ImageColumn = styled.div`
	display: flex;
	align-items: center;
	flex-basis: 100%;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-basis: 63%;
	}
`;

export const VideoColumn = styled.div`
	display: flex;
	align-items: center;
	flex-basis: 100%;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-basis: 63%;
	}
`;

export const Image = styled.img`
	width: 100%;
	border-radius: 8px;
`;

export const ContentColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-basis: 100%;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-basis: ${({ $columnMode }) => ($columnMode ? '37%' : '100%')};
	}
	h2 {
		text-align: center;
		margin: 10px 0px 15px;
	}
	h3 {
		text-align: center;
		margin: 10px 0px 15px;
	}
	p {
		text-align: center;
		color: ${({ theme }) => theme.secondaryText};
		font-size: 1.05rem;
		white-space: pre-line;
	}
	& > div {
		max-width: 500px;
		width: 100%;
	}
`;

export const IconContainer = styled.div`
	text-align: center;
	color: ${({ theme, $iconColor }) => theme[$iconColor]};
	transform: rotate(${({ $iconAngle }) => $iconAngle}deg);
	margin-bottom: 20px;

	svg {
		font-size: 3.5rem;
	}
`;
