/*
 * File: SchemaScript.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:32 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';


/**
 * Component
 */

export const SchemaScript = ({ schema }) => (
	<script
		type="application/ld+json"
		// eslint-disable-next-line react/no-danger
		dangerouslySetInnerHTML={{
			__html: (() => JSON.stringify(schema))()
		}}
	/>
);


/**
 * Configuration
 */

SchemaScript.displayName = 'SchemaScript';
SchemaScript.propTypes = {
	schema: PropTypes.shape().isRequired,
};
SchemaScript.defaultProps = {};
