/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 11:11 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 22, 2024 at 1:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getCurrentUser = (data) => api.get('/users/current', data);

export const deleteCurrentUser = () => api.delete('/users/current', { withCredentials: true });

export const getUserProfile = (data) => api.get(`/users/${data.id}`);

export const validateGeneralAccessAttempt = ({ token }) => api.get('/user/account/access', { params: { token } });

export const updateUserProfile = (data) => api.post('/user/profile', data);

export const createChildAccount = (data) => api.post('/user/child/account', data);

export const uploadUserProfileAvatar = ({ file, filename = `${(new Date()).getTime().toString()}.jpg`, onProgress = () => {} }) => {

	// Create form data
	const formData = new FormData();
	formData.append('file', file, filename);

	// Post media
	return api.post('/user/profile/media/avatar', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		onUploadProgress: onProgress
	});
};
