/*
 * File: LocaleLink.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 9:36 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 1, 2024 at 11:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styles
import * as S from './LocaleLink.styles';


/**
 * Component
 */

export const LocaleLink = forwardRef(({
	to, className, back, identifier, data, ...rest
}, ref) => {

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Get location state
	const location = useLocation();

	// Get if link is external
	const isExternal = to != null && (to.startsWith('http://') || to.startsWith('https://') || to.startsWith('www.'));

	// Return component
	if (isExternal) {

		// Return component
		return <S.BaseLink {...rest} href={to} ref={ref} rel="noopener" className={className ? `${className} animate` : 'animate'} />;

	} if (to != null && (to.startsWith('mailto:') || to.startsWith('tel:'))) {

		// Return component
		return <S.BaseLink {...rest} href={to} ref={ref} rel="noopener" className={className ? `${className} animate` : 'animate'} />;
	}

	// Return component
	return <S.LocaleLink {...rest} to={`${locale.localePath}${to}`} state={{ back: { path: location.pathname, title: back?.title } }} ref={ref} className={className ? `${className} animate` : 'animate'} />;
});


/**
 * Configuration
 */

LocaleLink.displayName = 'LocaleLink';
LocaleLink.propTypes = {
	to: PropTypes.string,
	className: PropTypes.string,
	target: PropTypes.string,
	identifier: PropTypes.string,
	back: PropTypes.shape(),
	data: PropTypes.shape(),
};
LocaleLink.defaultProps = {
	to: null,
	className: null,
	target: null,
	identifier: null,
	back: {},
	data: {}
};
