/*
 * File: RadioGroup.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: August 6, 2024 at 10:35 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: ${({ $variant }) => ($variant === 'vertical' ? 'column' : 'row')};
	gap: ${({ $variant }) => ($variant === 'vertical' ? 12 : 20)}px;
	position: relative;
`;

export const RadioRow = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const Radio = styled.input`
	cursor: pointer;
	-webkit-appearance: none;
	appearance: none;
	background-color: ${({ theme }) => theme.primaryBackground}; /* For iOS < 15 to remove gradient background */
	margin: 0;
	font: inherit;
	color: ${({ theme }) => theme.layoutBorder};
	width: 20px;
	height: 20px;
	border: 2px solid ${({ theme }) => theme.layoutBorder};
	border-radius: 50%;
	display: grid;
  	place-content: center;
	flex-shrink: 0;

	&:checked {
		border: 2px solid ${({ theme }) => theme.brandPrimaryBase};
	}
	&::before {
		content: "";
		width: 12px;
		height: 12px;
		border-radius: 50%;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em ${({ theme }) => theme.brandPrimaryBase};
		background-color: CanvasText; /* Windows High Contrast Mode */
	}
	&:checked::before {
		transform: scale(1);
	}
	&:focus {
		outline: max(2px, 0.15em) solid ${({ theme }) => theme.layoutBorder};
		outline-offset: max(2px, 0.15em);
	}
`;

export const LabelContainer = styled.div`
	flex-grow: 1;
	cursor: pointer;

	${({ $isCorrect }) => $isCorrect && css`
		p {
			color: ${({ theme }) => theme.statusSuccessBase};
		}
	`}
	${({ $isIncorrect }) => $isIncorrect && css`
		p {
			color: ${({ theme }) => theme.statusDangerBase};
		}
	`}
	.subLabel {
		color: ${({ theme }) => theme.secondaryText};
		margin: -3px 0px 0px 0px;
	}
`;

export const FloatingWrapper = styled.div`
 	position: absolute;
 	bottom: -20px !important;
 	color: ${({ theme }) => theme.statusDangerBase} !important;
 	max-width: 100%;
	left: 0px;

 	p {
		text-align: left;
	 	white-space: nowrap;
	 	text-overflow: ellipsis;
	 	overflow: hidden;
		color: ${({ theme }) => theme.statusDangerBase} !important;
		margin: 0px !important;
		display: block !important;
		font-size: 0.6875rem !important;
 	}
`;

export const CorrectIndicator = styled.div`
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.statusSuccessBase};
	display: flex;
	align-items: center;
	justify-content: center;

	* {
		color: ${({ theme }) => theme.primaryBackground};
	}
	svg {
		font-size: 0.8rem;
	}
`;

export const IncorrectIndicator = styled.div`
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.statusDangerBase};
	display: flex;
	align-items: center;
	justify-content: center;

	* {
		color: ${({ theme }) => theme.primaryBackground};
	}
	svg {
		font-size: 0.8rem;
	}
`;
