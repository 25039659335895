/*
 * File: Sidebar.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 9, 2024 at 12:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { renderLogo } from '../../../utilities/image';

// Components
import { Padding } from '../../Padding';
import { Typography } from '../../Typography';
import { Badge } from '../../Badge';

// Constants
import { ROLES } from '../../../../Constants';

// Styles
import * as S from './Sidebar.styles';


/**
 * Component
 */

export const Sidebar = ({
	toggleOverlay, isOverlay, className,
}) => {

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get location
	const location = useLocation();

	// Get journal from hook
	const journalEntries = useSelector((state) => state.journal.entries.value);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get user role
	const userRole = user?.role?.primary;

	// Get new journal entries state
	const showNewJournalEntries = useMemo(() => {
		if (journalEntries && userRole === ROLES.PARENT) {
			const newEntries = journalEntries.filter(({ response }) => !response);
			return newEntries.length > 0;
		}
		return false;
	}, [journalEntries]);

	// Check if link is action function
	const isLinkActive = (to) => location.pathname === to || location.pathname === `${locale.localePath}${to}`;

	// Render component
	return (
		<S.Sidebar className={className} isOverlay={isOverlay}>
			<Padding>

				{/* Mobile Navigation */}
				{isOverlay && (
					<S.MobileNavigation>
						<S.NavigationToggle icon={['fal', 'times']} size={1.35} onClick={() => { toggleOverlay(); }} />
					</S.MobileNavigation>
				)}

				{/* Logo */}
				<S.LogoContainer>
					<S.Logo
						src={renderLogo(uiMode)}
						title="Let's Talk Logo"
						alt="Let's Talk Logo"
					/>
				</S.LogoContainer>

				{/* Navigation */}
				<S.SidebarSection showBorder={false}>
					<S.LinkSection>
						<S.Link to="/dashboard" $isActive={isLinkActive('/dashboard')}>
							<FontAwesomeIcon icon={[isLinkActive('/dashboard') ? 'fas' : 'fal', 'home-lg-alt']} />
							<Typography>Home</Typography>
						</S.Link>
						<S.Link to="/dashboard/answers" $isActive={isLinkActive('/dashboard/answers')}>
							<FontAwesomeIcon icon={[isLinkActive('/dashboard/answers') ? 'fas' : 'fal', 'question-circle']} />
							<Typography>Questions</Typography>
						</S.Link>
						<S.Link to="/dashboard/terms" $isActive={isLinkActive('/dashboard/terms')}>
							<FontAwesomeIcon icon={[isLinkActive('/dashboard/terms') ? 'fas' : 'fal', 'list']} />
							<Typography>Terms List</Typography>
						</S.Link>
						<S.Link to="/dashboard/resources" $isActive={isLinkActive('/dashboard/resources')}>
							<FontAwesomeIcon icon={[isLinkActive('/dashboard/resources') ? 'fas' : 'fal', 'books']} />
							<Typography>Resources</Typography>
						</S.Link>
						<S.Link to="/dashboard/journal" $isActive={isLinkActive('/dashboard/journal')}>
							<FontAwesomeIcon icon={[isLinkActive('/dashboard/journal') ? 'fas' : 'fal', 'book']} />
							<Typography>Journal</Typography>
							{showNewJournalEntries && (
								<Badge icon={{ source: ['fas', 'circle'], placement: 'left' }} size="small" className="promptBadge">
									New Entry
								</Badge>
							)}
						</S.Link>
					</S.LinkSection>
				</S.SidebarSection>
			</Padding>
		</S.Sidebar>
	);
};


/**
 * Configuration
 */

Sidebar.displayName = 'Sidebar';
Sidebar.propTypes = {
	className: PropTypes.string,
	toggleOverlay: PropTypes.func,
	isOverlay: PropTypes.bool
};
Sidebar.defaultProps = {
	className: null,
	toggleOverlay: null,
	isOverlay: false
};
