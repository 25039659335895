/*
 * File: content.slice.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on May 21, 2024 at 10:34 AM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: July 12, 2024 at 10:59 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Services
import {
	fetchQA as getQA,
	fetchResources as getResources,
	fetchTerms as getTerms,
	fetchFunFacts as getFunFacts
} from '../../../services/content';


/**
 * Functions
 */

export const fetchQA = createAsyncThunk('content/fetchQA', async () => {
	try {

		// Fetch QA
		const { data } = await getQA();

		// Return qa
		return data.questions;

	} catch (e) {

		// Return null
		return null;
	}
});

export const fetchResources = createAsyncThunk('content/fetchResources', async () => {
	try {

		// Fetch resources
		const { data } = await getResources();

		// Return resources
		return data.resources;

	} catch (e) {

		// Return null
		return null;
	}
});

export const fetchTerms = createAsyncThunk('content/fetchTerms', async () => {
	try {

		// Fetch terms
		const { data } = await getTerms();

		// Return terms
		return data.terms;

	} catch (e) {

		// Return null
		return null;
	}
});

export const fetchFunFacts = createAsyncThunk('content/fetchFunFacts', async () => {
	try {

		// Fetch fun facts
		const { data } = await getFunFacts();

		// Return fun facts
		return data.funFacts;

	} catch (e) {

		// Return null
		return null;
	}
});


/**
 * Slices
 */

export const contentSlice = createSlice({
	name: 'content',
	initialState: {
		qa: {
			status: 'idle',
			value: [],
			error: null
		},
		resources: {
			status: 'idle',
			value: [],
			error: null
		},
		terms: {
			status: 'idle',
			value: [],
			error: null
		},
		funfacts: {
			status: 'idle',
			value: [],
			error: null
		},
	},
	reducers: {
		updateQA: (state, action) => {

			// Set state
			state.qa.value = action.payload;
			state.qa.status = 'succeeded';
		},
		updateResources: (state, action) => {

			// Set state
			state.resources.value = action.payload;
			state.resources.status = 'succeeded';
		},
		updateTerms: (state, action) => {

			// Set state
			state.terms.value = action.payload;
			state.terms.status = 'succeeded';
		},
		updateFunFacts: (state, action) => {

			// Set state
			state.funfacts.value = action.payload;
			state.funfacts.status = 'succeeded';
		},
		clearQA: (state) => {

			// Clear state
			state.qa.value = [];
		},
		clearResources: (state) => {

			// Clear state
			state.resources.value = [];
		},
		clearTerms: (state) => {

			// Clear state
			state.terms.value = [];
		},
		clearFunFacts: (state) => {

			// Clear state
			state.funfacts.value = [];
		},
		clearContent: (state) => {

			// Clear state
			state.qa.value = [];
			state.resources.value = [];
			state.terms.value = [];
			state.funfacts.value = [];
		},
	},
	extraReducers: {
		[fetchQA.pending]: (state) => {
			state.qa.status = 'loading';
		},
		[fetchQA.fulfilled]: (state, action) => {
			state.qa.status = 'succeeded';
			state.qa.value = action.payload;
		},
		[fetchQA.rejected]: (state, action) => {
			state.qa.status = 'failed';
			state.qa.error = action.error.message;
		},
		[fetchResources.pending]: (state) => {
			state.resources.status = 'loading';
		},
		[fetchResources.fulfilled]: (state, action) => {
			state.resources.status = 'succeeded';
			state.resources.value = action.payload;
		},
		[fetchResources.rejected]: (state, action) => {
			state.resources.status = 'failed';
			state.resources.error = action.error.message;
		},
		[fetchTerms.pending]: (state) => {
			state.terms.status = 'loading';
		},
		[fetchTerms.fulfilled]: (state, action) => {
			state.terms.status = 'succeeded';
			state.terms.value = action.payload;
		},
		[fetchTerms.rejected]: (state, action) => {
			state.terms.status = 'failed';
			state.terms.error = action.error.message;
		},
		[fetchFunFacts.pending]: (state) => {
			state.funfacts.status = 'loading';
		},
		[fetchFunFacts.fulfilled]: (state, action) => {
			state.funfacts.status = 'succeeded';
			state.funfacts.value = action.payload;
		},
		[fetchFunFacts.rejected]: (state, action) => {
			state.funfacts.status = 'failed';
			state.funfacts.error = action.error.message;
		},
	},
});


/**
 * Exports
 */

export const {
	updateQA,
	updateResources,
	updateTerms,
	updateFunFacts,
	clearQA,
	clearResources,
	clearTerms,
	clearFunFacts,
	clearContent
} = contentSlice.actions;

export default contentSlice.reducer;
