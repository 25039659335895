/*
 * File: JournalEntryWidget.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 9:33 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useState, useRef, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { Howl } from 'howler';

// Utilities
import { toastError } from '../../utilities/toaster';

// Services
import { fetchJournalEntries, submitJournalEntry } from '../../services/journal';

// Slices
import { updateJournalEntries } from '../../store/slices/journal/journal.slice';

// Components
import { Button } from '../Button';
import { Typography } from '../Typography';
import { TextArea } from '../TextArea';
import { ConfettiBurst } from '../ConfettiBurst';
import { Select } from '../Select';

// Styles
import * as S from './JournalEntryWidget.styles';


/**
 * Component
 */

export const JournalEntryWidget = ({
	className, prompts, handleComplete, handleNewEntry
}) => {

	// Create state handlers
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [contentError, setContentError] = useState(null);
	const [inputValues, setInputValues] = useState({});
	const [showConfirmation, setShowConfirmation] = useState(false);

	// Use hooks
	const dispatch = useDispatch();

	// Create refs
	const confettiTargetRef = useRef();

	// Create sound hooks
	const fanfareSound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/fanfare.mp3`],
		html5: true,
		volume: 0.1
	}), []);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle submit action
	const handleSubmit = async () => {

		// Get parameters
		const { content } = inputValues;

		// Validate parameters
		if (!content || validator.isEmpty(content, { ignore_whitespace: true })) {
			setContentError({ message: 'Please enter a thought or question for your parent before updating your journal' });
			return;
		}

		// Update submission state
		setIsSubmitting(true);

		// Submit entry
		try {

			// Submit journal entry
			await submitJournalEntry({ prompt: content });

			// Fetch journal entries
			const { data: { entries } } = await fetchJournalEntries();
			dispatch(updateJournalEntries(entries));

			// Trigger handle new entry
			if (handleNewEntry) handleNewEntry(content);

			// Update submission state
			setIsSubmitting(false);

			// Show confirmation
			setShowConfirmation(true);

			// Fire confetti
			setFireConfetti(true);

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 4000);

		} catch (e) {

			// Update submission state
			setIsSubmitting(false);

			// Show error message
			toastError(uiMode, 'Whoops. We\'re having trouble saving your journal entry. Give it another go.');
		}
	};

	// Handle complete action
	const handleCompleteAction = (entryAdded) => {

		// Reset state
		setShowConfirmation(false);
		setInputValues({});

		// Handle complete
		if (handleComplete) handleComplete(entryAdded);
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<S.JournalEntryWidget className={className}>

			{/* Close Button */}
			<S.CloseButton icon={['fal', 'times']} size={1.1} onClick={() => { handleCompleteAction(false); }} />

			{/* Submission Panel */}
			{!showConfirmation && (
				<S.SubmissionPanel>

					{/* Content */}
					<S.PromptContainer>
						<Typography tag="h4" weight="bold">
							What&apos;s on your mind?
						</Typography>
						<Typography tag="p" variation="1" weight="light" className="subtitle">
							Write your thoughts below to send to your parent
						</Typography>
					</S.PromptContainer>

					{/* Prompt Selector */}
					<S.PromptSelector>
						<Select
							name="selectedPrompt"
							placeholder="Select a question to send to your parent or write one below."
							options={prompts.map(({ prompt }) => ({ label: prompt, value: prompt }))}
							isSearchable={false}
							values={[]}
							onChange={(e) => {
								setInputValues({ content: e.target.value });
							}}
						/>
					</S.PromptSelector>

					{/* Text Area */}
					<TextArea
						placeholder="This is a space to send your parent a question or comment. Give it a try!"
						name="content"
						type="text"
						rows={12}
						error={contentError}
						value={inputValues.content || ''}
						containerClassName="formInput"
						onFocus={() => { setContentError(null); }}
						onKeyUp={() => { setContentError(null); }}
						onBlur={() => { setContentError(null); }}
						onChange={handleOnChange}
					/>

					{/* Actions */}
					<S.ActionContainer>
						<Button disabled={isSubmitting} isLoading={isSubmitting} variant="solid" size="medium" onClick={handleSubmit}>
							<Typography tag="p" variant="2" weight="medium">Send to Parent</Typography>
						</Button>
						<S.TextAreaPadding />
					</S.ActionContainer>

				</S.SubmissionPanel>
			)}

			{/* Confirmation Panel */}
			{showConfirmation && (
				<S.ConfirmationPanel>

					{/* Icon */}
					<S.ConfirmationIconContainer>
						<FontAwesomeIcon icon={['fal', 'check-circle']} />
						<S.ConfettiTarget ref={confettiTargetRef} />
					</S.ConfirmationIconContainer>

					{/* Title */}
					<Typography tag="h3" weight="bold">You&apos;ve created a new journal entry!</Typography>

					{/* Instructions */}
					<Typography tag="p" variation="1" weight="medium">
						Thank you for sharing your thoughts. We&apos;ll share your entry with your parent in their journal for them to respond.
					</Typography>

					{/* Next Button */}
					<Button onClick={() => { handleCompleteAction(true); }} size="large">Complete</Button>

				</S.ConfirmationPanel>
			)}

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
					className="zIndex3"
					audioHook={fanfareSound}
				/>
			)}
			{showConfirmation && <S.ConfirmationBackground />}
		</S.JournalEntryWidget>
	);
};


/**
 * Configuration
 */

JournalEntryWidget.displayName = 'JournalEntryWidget';
JournalEntryWidget.propTypes = {
	className: PropTypes.string,
	handleComplete: PropTypes.func,
	handleNewEntry: PropTypes.func,
	prompts: PropTypes.arrayOf(PropTypes.shape())
};
JournalEntryWidget.defaultProps = {
	className: null,
	handleComplete: null,
	handleNewEntry: null,
	prompts: []
};
