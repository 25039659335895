/*
 * File: Video.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 29, 2024 at 12:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import * as S from './Video.styles';


/**
 * Component
 */

export const Video = ({
	className, url, onPlaying
}) => {

	// Create state handlers
	const [videoPlaying, setVideoPlaying] = useState(false);

	// Create video references
	const videoRef = useRef();

	// Handle play video
	const playVideo = () => {
		videoRef?.current?.play();
	};

	// Handle actions on component load
	useEffect(() => {

		// Add listeners for video
		videoRef?.current?.addEventListener('play', () => {
			setVideoPlaying(true);
			onPlaying?.(true);
		});
		videoRef?.current?.addEventListener('pause', () => {
			setVideoPlaying(false);
			onPlaying?.(false);
		});

	}, []);

	// Render component
	return (
		<S.Wrapper className={className}>
			<S.Video
				preload="metadata"
				controls
				controlsList="nodownload"
				ref={videoRef}
			>
				<track kind="caption" />
				<source src={url} type="video/mp4" />
			</S.Video>
			{!videoPlaying && (
				<S.VideoOverlay>
					<S.PlayButton onClick={playVideo}>
						<FontAwesomeIcon icon={['fas', 'play']} />
					</S.PlayButton>
				</S.VideoOverlay>
			)}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Video.displayName = 'Video';
Video.propTypes = {
	className: PropTypes.string,
	url: PropTypes.string,
	onPlaying: PropTypes.func
};
Video.defaultProps = {
	className: null,
	url: null,
	onPlaying: null
};
