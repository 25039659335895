/*
 * File: Dashboard.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 14, 2024 at 11:24 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useMemo, useRef, useState, forwardRef,
	useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent, useTransformEffect } from 'react-zoom-pan-pinch';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useSearchParams } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Hooks
import useSize from '../../../hooks/useSize';

// Store
import { fetchActivityUnits } from '../../../store/slices/activities/activities.slice';

// Components
import {
	Meta,
	AppNavigation,
	FunFactWidget,
	RewardsProgressWidget,
	JourneyStone,
	UnitProgress,
	JourneyGoal,
	StepExplanationWidget,
	TrialCompleteModal,
	Spinner
} from '../../../components';

// Constants
import { ACTIVITY_MODULE_STATUSES, ROLES } from '../../../../Constants';
import { AppNavTopbarHeight, mobileBreakpoint } from '../../../styles/constants';

// Styles
import * as S from './Dashboard.styles';


/**
 * Constants
 */

const STONE_POSITIONS = [
	{ top: 0.04, left: 0.15 },
	{ top: 0.16, left: 0.6 },
	{ top: 0.38, left: 0.48 },
	{ top: 0.55, left: 0.07 },
	{ top: 0.74, left: 0.07 },
	{ top: 0.91, left: 0.24 },
	{ top: 1.12, left: 0.6 },
	{ top: 1.32, left: 0.52 },
	{ top: 1.45, left: 0.28 },
	{ top: 1.67, left: 0.03 },
	{ top: 1.87, left: 0.17 },
	{ top: 2.02, left: 0.37 },
	{ top: 2.25, left: 0.66 },
	{ top: 2.46, left: 0.64 },
	{ top: 2.59, left: 0.45 },
	{ top: 2.78, left: 0.06 },
	{ top: 2.98, left: 0.15 },
	{ top: 3.16, left: 0.34 },
];

const UNIT_PROGRESS_POSITIONS = [
	{ top: 0.24, left: 0.39 },
	{ top: 0.73, left: 0.39 },
	{ top: 1.22, left: 0.39 },
	{ top: 1.72, left: 0.39 },
	{ top: 2.41, left: 0.39 },
	{ top: 2.89, left: 0.39 },
];

const GOAL_POSITIONS = {
	[ROLES.PARENT]: { top: 3.34, left: 0.55 },
	[ROLES.CHILD]: { top: 2.71, left: 0.27 },
};

const AUTOZOOM_LIMIT = 1200;


/**
 * Layer Component
 */

const LayerWrapper = forwardRef(({
	className, modules, units, layerWidth, handleStepAction, updateMapPosition
}, ref) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Use transform effect
	useTransformEffect(({ state }) => {

		// Update map position
		updateMapPosition(state);

		// Unmount transform
		return () => {};
	});

	// Return layers
	return (
		<S.LayerWrapper ref={ref} className={className}>

			{/* Journey Stones */}
			{modules?.map((activityModule, index) => (
				<JourneyStone
					key={activityModule?.id || `${index}`}
					activityModule={activityModule}
					position={STONE_POSITIONS[index]}
					containerWidth={layerWidth}
					handleAction={() => { handleStepAction(activityModule, STONE_POSITIONS[index]); }}
				/>
			))}

			{/* Unit Progress */}
			{units?.map((activityUnit, index) => (
				<UnitProgress
					key={activityUnit?.id}
					activityUnit={activityUnit}
					position={UNIT_PROGRESS_POSITIONS[index]}
					containerWidth={layerWidth}
					isDarkBackground={index > 3}
				/>
			))}

			{/* Journey Goal */}
			<JourneyGoal
				containerWidth={layerWidth}
				position={GOAL_POSITIONS[user.role.primary]}
			/>

			{/* Map */}
			<S.JourneyLayer src={user.role.primary === ROLES.CHILD
				? `${process.env.CDN_URL}/public/assets/decorations/path/backgrounds/module-combined-child.png`
				: `${process.env.CDN_URL}/public/assets/decorations/path/backgrounds/module-combined-parent.png`}
			/>
		</S.LayerWrapper>
	);

});


/**
 * Configuration
 */

LayerWrapper.propTypes = {
	className: PropTypes.string,
	modules: PropTypes.arrayOf(PropTypes.shape()),
	units: PropTypes.arrayOf(PropTypes.shape()),
	layerWidth: PropTypes.number,
	handleStepAction: PropTypes.func,
	updateMapPosition: PropTypes.func
};
LayerWrapper.defaultProps = {
	className: null,
	modules: [],
	units: [],
	layerWidth: 500,
	handleStepAction: null,
	updateMapPosition: null
};


/**
 * Component
 */

const Dashboard = ({ meta, locale, data }) => {

	// Set state
	const [isExplanationWidgetOpen, toggleExplanationWidgetOpen] = useState(false);
	const [activeModule, setActiveModule] = useState(null);
	const [activeModulePosition, setActiveModulePosition] = useState({});
	const [transformCoordinates, setTransformCoordinates] = useState({});
	const [transformX, setTransformX] = useState(0);
	const [transformY, setTransformY] = useState(0);
	const [transformScale, setTransformScale] = useState(1);
	const [isTrialCompleteModalVisible, toggleTrialCompleteModalVisible] = useState(false);
	const [scrollBarPosition, setScrollBarPosition] = useState(0);
	const [scrollBarSize, setScrollBarSize] = useState(0);
	const [isDraggingScrollBar, setIsDraggingScrollBar] = useState(false);
	const [dragOffset, setDragOffset] = useState(0);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get activity units from hook
	const units = useSelector((state) => state.activities.units.value);
	const activitiesUnitsStatus = useSelector((state) => state.activities.units.status);

	// Define modules
	const modules = useMemo(() => {
		const arr = [];
		if (units) {
			units.forEach((unit) => {
				arr.push(...unit.modules);
			});
		}
		return arr;
	}, [units]);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Initialize window parameters
	const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 1000;

	// Check if mobile screen size
	const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}em)`);

	// Use hooks
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams({});

	// Create reference for components
	const isMounted = useRef(true);
	const layerWrapperRef = useRef();
	const transformerRef = useRef();
	const explanationWidgetOpenRef = useRef(false);
	const audioPlayerRef = useRef();
	const scrollBarRef = useRef();

	// Set layout state
	const layerSize = useSize(layerWrapperRef);

	// Handle auto-zoom
	const performAutozoom = (animate = true, isActive = true, moduleId = null) => {

		// Get client width
		const clientWidth = layerWrapperRef?.current?.clientWidth || 500;

		// Auto-center gameboard
		transformerRef?.current?.zoomToElement(isActive ? 'current-active-step' : document.getElementsByName(`step-${moduleId}`)[0], clientWidth < AUTOZOOM_LIMIT ? AUTOZOOM_LIMIT / clientWidth : 1, animate ? 600 : 0);
	};

	// Handle play audio
	const playAudio = async () => {
		try {
			await audioPlayerRef?.current?.audioEl?.current?.play();
		} catch (e) {}
	};

	// Handle scrollbar mouse move function
	const onMouseMove = useCallback((e) => {

		// Prevent default
		e.preventDefault();

		// Calculate parameters
		const scrollBarHeight = (windowHeight - AppNavTopbarHeight) * 0.98;
		const scrollBarPercentage = (e.clientY - dragOffset - (scrollBarRef?.current?.getBoundingClientRect()?.top || 0)) / scrollBarHeight;
		const scrollBarSizePercentage = scrollBarSize / scrollBarHeight;
		let newPosition = (layerSize?.height || 1) * transformScale * scrollBarPercentage;

		// Constrain position
		if (scrollBarPercentage < 0) newPosition = 0;
		if ((scrollBarPercentage + scrollBarSizePercentage) > 1) newPosition = ((layerSize?.height || 1) * transformScale) - ((layerSize?.height || 1) * transformScale * scrollBarSizePercentage);

		// Move view
		transformerRef?.current?.setTransform(transformX, -newPosition, transformScale, 0, null);

	}, [windowHeight, dragOffset, layerSize, transformScale, transformX, scrollBarSize]);

	// Handle scrollbar mouse up function
	const onMouseUp = useCallback((e) => {

		// Prevent default
		e.preventDefault();
		e.stopPropagation();

		// Update state
		setIsDraggingScrollBar(false);

	}, []);

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (activitiesUnitsStatus === 'idle') {

			// Fetch data state for page
			dispatch(fetchActivityUnits());
		}

		// Autozoom if page loading is complete
		if (activitiesUnitsStatus === 'succeeded') {

			// Auto-center gameboard
			performAutozoom(true);

			// Show explanation widget for current step
			setTimeout(() => {
				if (!explanationWidgetOpenRef.current === true && !isMobile) {

					// Get current active step
					let activeStep = null;
					user.journey.forEach(({ modules: modulesArray }) => {
						modulesArray.forEach((moduleObj) => {
							if (moduleObj.status === ACTIVITY_MODULE_STATUSES.PENDING_START || moduleObj.status === ACTIVITY_MODULE_STATUSES.IN_PROGRESS) {
								activeStep = moduleObj;
							}
						});
					});

					// Ensure active step exists
					if (activeStep) {

						// Find module parameters
						const moduleObj = modules.find(({ id }) => id === activeStep.module);
						const index = modules.findIndex(({ id }) => id === activeStep.module);

						// Set state
						setActiveModule(moduleObj);
						setActiveModulePosition(STONE_POSITIONS[index]);
						toggleExplanationWidgetOpen(true);
						explanationWidgetOpenRef.current = true;
					}
				}
			}, 2000);
		}

	}, [activitiesUnitsStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Set component dimensions
		setTimeout(() => {

			// Auto-center gameboard
			performAutozoom(true);

		}, 100);

		// Get query parameters
		const queryObj = Object.fromEntries([...searchParams]);

		// Check for trial query
		if (searchParams.has('trial')) {

			// Get trial message
			const trialMessage = decodeURIComponent(searchParams.get('trial'));

			// Clear trial query parameters
			const { trial, ...additionalParams } = queryObj;
			setSearchParams(additionalParams);

			// Display trial complete modal
			if (trialMessage === 'complete') {
				toggleTrialCompleteModalVisible(true);
			}
		}

		// Play background audio
		playAudio();

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle actions on window resize
	useEffect(() => {

		// Auto-center gameboard
		performAutozoom(true);

	}, [layerSize]);

	// Handle actions for scrollbar resize
	useEffect(() => {

		// Calculate parameters
		const scrollBarHeight = (windowHeight - AppNavTopbarHeight) * 0.98;
		const thumbHeight = ((scrollBarHeight / (layerSize?.height || 1)) / transformScale) * scrollBarHeight;
		const thumbPosition = ((-transformY / (layerSize?.height || 1)) / transformScale) * scrollBarHeight;

		// Set scroll bar parameters
		setScrollBarSize(thumbHeight);
		setScrollBarPosition(thumbPosition);

	}, [windowHeight, layerSize, transformScale, transformY]);

	// Handle actions on dragging scroll bar
	useEffect(() => {
		if (isDraggingScrollBar) {

			// Add event listeners
			document.addEventListener('mousemove', onMouseMove, false);
			document.addEventListener('mouseup', onMouseUp, false);
			document.addEventListener('dragend', onMouseUp, false);

		} else {

			// Remove event listeners
			document.removeEventListener('mousemove', onMouseMove, false);
			document.removeEventListener('mouseup', onMouseUp, false);
			document.removeEventListener('dragend', onMouseUp, false);

		}
	}, [isDraggingScrollBar]);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation data={data}>
				<S.Wrapper onMouseDown={playAudio}>

					{/* Widget Container */}
					<S.WidgetContainer>
						<FunFactWidget />
						<S.RewardsProgressCard className="isNotMobile">
							<RewardsProgressWidget />
						</S.RewardsProgressCard>
					</S.WidgetContainer>

					{/* Loader */}
					{activitiesUnitsStatus !== 'succeeded' && <S.LoadingContainer><Spinner /></S.LoadingContainer>}

					{/* Scroll Bar */}
					<S.ScrollBar className="isNotMobile" ref={scrollBarRef}>
						<S.Thumb
							style={{ top: `${scrollBarPosition}px`, height: `${scrollBarSize}px` }}
							onMouseDown={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setIsDraggingScrollBar(true);
								setDragOffset(e.clientY - e.target.getBoundingClientRect().top);
							}}
						/>
					</S.ScrollBar>

					{/* Gameboard */}
					<TransformWrapper
						maxScale={3}
						ref={transformerRef}
						panning={{
							wheelPanning: true
						}}
						doubleClick={{
							disabled: true
						}}
					>
						<TransformComponent wrapperStyle={{ height: `${windowHeight - AppNavTopbarHeight}px` }}>
							<LayerWrapper
								ref={layerWrapperRef}
								modules={modules}
								units={units}
								layerWidth={layerSize?.width}
								handleStepAction={(module, modulePosition) => {

									// Update state
									setActiveModule(module);
									setActiveModulePosition(modulePosition);
									toggleExplanationWidgetOpen(true);
									explanationWidgetOpenRef.current = true;

									// Auto-center gameboard
									performAutozoom(true, false, module.id);
								}}
								updateMapPosition={(coordinates) => {

									// Update state
									setTransformScale(coordinates.scale);
									setTransformCoordinates(coordinates);
									setTransformX(coordinates.positionX);
									setTransformY(coordinates.positionY);
								}}
							/>

						</TransformComponent>
					</TransformWrapper>

					{/* Explanation Widget */}
					<StepExplanationWidget
						activityModule={activeModule}
						modulePosition={activeModulePosition}
						transformCoordinates={transformCoordinates}
						transformScale={transformScale}
						transformX={transformX}
						transformY={transformY}
						layerWidth={layerSize?.width}
						isOpen={isExplanationWidgetOpen}
						handleClose={() => {
							toggleExplanationWidgetOpen(false);
							explanationWidgetOpenRef.current = false;
						}}
					/>

					{/* Trial Complete Modal */}
					<TrialCompleteModal
						isOpen={isTrialCompleteModalVisible}
						handleClose={() => { toggleTrialCompleteModalVisible(false); }}
					/>

				</S.Wrapper>
			</AppNavigation>

			{/* Audio Player */}
			<ReactAudioPlayer
				ref={audioPlayerRef}
				src={`${process.env.CDN_URL}/public/assets/audio/nature-loop.mp3`}
				volume={1}
				controls={false}
				loop
			/>
		</>
	);
};


/**
 * Configuration
 */

Dashboard.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
Dashboard.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default Dashboard;
