/*
 * File: RewardsProgressWidget.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 9:33 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Howl } from 'howler';

// Components
import { Typography } from '../Typography';
import { ConfettiBurst } from '../ConfettiBurst';

// Utilities
import { hexToRgb } from '../../../utilities/color';
import { parseFirstLastName } from '../../../utilities/utilities';

// Hooks
import ProgressProvider from '../../hooks/ProgressProvider';

// Slices
import { updateConfettiState } from '../../store/slices/confetti/confetti.slice';

// Constants
import { ROLES, UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import { DarkTheme, LightTheme } from '../../styles/colors';
import * as S from './RewardsProgressWidget.styles';
import 'react-circular-progressbar/dist/styles.css';


/**
 * Component
 */

export const RewardsProgressWidget = ({
	className
}) => {

	// Create state handlers
	const [fireConfetti, setFireConfetti] = useState(false);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get actions from hooks
	const dispatch = useDispatch();

	// Create sound hooks
	const fanfareSound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/fanfare.mp3`],
		html5: true,
		volume: 0.1
	}), []);

	// Get confetti fired state from hook
	const { RewardsProgressWidget: RewardsProgressWidgetConfettiState } = useSelector((state) => state.confetti.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;
	const primaryColor = hexToRgb(currentTheme.brandPrimaryBase);
	const primaryColorLighter = hexToRgb(currentTheme.brandPrimaryLighter);

	// Create refs
	const confettiTargetRef = useRef();

	// Calculate earned stars
	const earnedStars = useMemo(() => {
		let stars = 0;
		if (user) {
			if (user.role.primary === ROLES.PARENT && user.children && user.children.length > 0) {
				stars = user.children[0].starsEarned;
			} else {
				stars = user.starsEarned || 0;
			}
		}
		return stars;
	}, [user]);

	// Calculate total stars
	const totalStars = useMemo(() => {
		let total = 0;
		if (user) {
			if (user.role.primary === ROLES.PARENT && user.children && user.children.length > 0) {
				user.children[0].journey.forEach(({ modules }) => {
					modules.forEach(({ earnsStars }) => {
						if (earnsStars) {
							total += 1;
						}
					});
				});
			} else {
				user.journey.forEach(({ modules }) => {
					modules.forEach(({ earnsStars }) => {
						if (earnsStars) {
							total += 1;
						}
					});
				});
			}
		}
		return total;
	}, [user]);

	// Perform actions on visibility change
	useEffect(() => {
		if (earnedStars === totalStars && !RewardsProgressWidgetConfettiState.shown) {

			// Update state
			dispatch(updateConfettiState({ RewardsProgressWidget: { shown: true } }));

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 500);

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 4000);
		}
	}, [earnedStars, totalStars]);

	// Render component
	return (
		<S.Wrapper className={className}>

			{/* Title */}
			{/* <Typography tag="p" variation="2" weight="bold">{user?.role?.primary === ROLES.PARENT ? 'Child\'s Reward Stats' : 'Reward Stats'}</Typography> */}

			{/* Current Reward Balance */}
			<S.RewardsBalanceContainer>

				{/* Rewards Progress Circle */}
				<ProgressProvider valueStart={0} valueEnd={(earnedStars / totalStars) * 100.0}>
					{(value) => (
						<CircularProgressbarWithChildren
							value={value}
							strokeWidth={6}
							styles={buildStyles({
								strokeLinecap: 'round',
								pathTransitionDuration: 0.5,
								pathColor: `rgba(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b}, 1)`,
								textColor: currentTheme.primaryText,
								trailColor: `rgba(${primaryColorLighter.r}, ${primaryColorLighter.g}, ${primaryColorLighter.b}, 1)`,
								backgroundColor: currentTheme.brandPrimaryBase,
							})}
						>

							{/* Rewards Balance */}
							<S.RewardsBalance>
								<S.RewardsInner>
									<Typography tag="span" weight="light" className="animate">
										{earnedStars}
									</Typography>
									<Typography tag="p" weight="semibold" className="animate" variation="2">
										{earnedStars === 1 ? 'Star' : 'Stars'}
									</Typography>
									<S.ConfettiTarget ref={confettiTargetRef} />
								</S.RewardsInner>
							</S.RewardsBalance>


							{/* Confetti */}
							{fireConfetti && (
								<ConfettiBurst
									fireAway={fireConfetti}
									targetRef={confettiTargetRef}
									force={0.4}
									duration={3000}
									particleCount={80}
									audioHook={fanfareSound}
								/>
							)}

						</CircularProgressbarWithChildren>
					)}
				</ProgressProvider>
			</S.RewardsBalanceContainer>

			{/* Status Content */}
			{(earnedStars === totalStars) ? (
				<S.CompletedContent>
					{user?.role?.primary === ROLES.CHILD ? (
						<>
							<FontAwesomeIcon icon={['fas', 'star']} />
							<Typography tag="p" weight="medium">
								Way to go
								{' '}
								{parseFirstLastName(user?.name).firstName}
								! You&apos;ve earned
								{' '}
								<Typography weight="bold">{user?.primaryReward?.description}</Typography>
							</Typography>
						</>
					) : (
						<>
							<FontAwesomeIcon icon={['fas', 'star']} />
							<Typography tag="p" weight="medium">
								Way to go
								{' '}
								{parseFirstLastName(user?.name).firstName}
								! Your child earned
								{' '}
								<Typography weight="bold">{user?.primaryReward?.description}</Typography>
							</Typography>
						</>
					)}
				</S.CompletedContent>
			) : (
				<S.StatusContent>
					<FontAwesomeIcon icon={['fal', 'lock-keyhole']} />
					<Typography tag="p" weight="medium">
						{totalStars - earnedStars}
						{' '}
						{totalStars - earnedStars === 1 ? 'star' : 'stars'}
						{' '}
						left to unlock
						<br />
						<Typography weight="bold">{user?.primaryReward?.description}</Typography>
					</Typography>
				</S.StatusContent>
			)}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

RewardsProgressWidget.displayName = 'RewardsProgressWidget';
RewardsProgressWidget.propTypes = {
	className: PropTypes.string
};
RewardsProgressWidget.defaultProps = {
	className: null,
};
