/*
 * File: InviteChild.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on April 8, 2024 at 8:51 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: October 8, 2024 at 2:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

// Utilities
import { renderLogo } from '../../../utilities/image';

// Components
import {
	AppNavigation,
	Meta,
} from '../../../components';

// Step Components
import { CompletionStep } from './CompletionStep';
import { CreateAccountIntroductionStep } from './CreateAccountIntroductionStep';
import { CreateAccountStep } from './CreateAccountStep';

// Styles
import * as S from './InviteChild.styles';


/**
 * Component
 */

const InviteChild = ({
	meta, locale, user
}) => {

	// Get component parameters
	const { childId } = useParams();

	// Create state for step components
	const [completionStepHeight, setCompletionStepHeight] = useState(0);
	const [createAccountIntroductionStepHeight, setCreateAccountIntroductionStepHeight] = useState(0);
	const [createAccountStepHeight, setCreateAccountStepHeight] = useState(0);

	// Create references for step components
	const completionStepRef = useRef();
	const createAccountIntroductionStepRef = useRef();
	const createAccountStepRef = useRef();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get actions from hooks
	const navigate = useNavigate();

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Create state handlers
	const [currentStep, setCurrentStep] = useState(1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [authenticationParams, setAuthenticationParams] = useState({});

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setCompletionStepHeight(completionStepRef?.current?.clientHeight);
			setCreateAccountIntroductionStepHeight(createAccountIntroductionStepRef?.current?.clientHeight);
			setCreateAccountStepHeight(createAccountStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setCompletionStepHeight(completionStepRef?.current?.clientHeight);
		setCreateAccountIntroductionStepHeight(createAccountIntroductionStepRef?.current?.clientHeight);
		setCreateAccountStepHeight(createAccountStepRef?.current?.clientHeight);
	});

	// Handle actions on component load
	useEffect(() => {

		// Navigate if necessary
		if (userObj.children.find((child) => child.id === childId)?.childAccountSetupComplete === true) {
			navigate(`${localeObj.localePath}/dashboard`);
		}

	}, []);

	// Get step height for step component
	const getStepHeight = () => {
		switch (currentStep) {
			case 1:
				return createAccountIntroductionStepHeight;
			case 2:
				return createAccountStepHeight;
			case 3:
				return completionStepHeight;
			default:
				return 0;
		}
	};

	// Render component
	if (!childId || !userObj.children.some((child) => child.id === childId)) {
		return <Navigate to={`${localeObj.localePath}/dashboard`} />;
	}
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={localeObj} />

			{/* App Navigation */}
			<AppNavigation hideSidebar>

				{/* Component Content */}
				<S.Wrapper>

					{/* Logo */}
					<S.Logo
						src={renderLogo(uiMode)}
						title="Let's Talk Logo"
						alt="Let's Talk Logo"
						className="animate"
					/>

					{/* Step Container */}
					<S.StepContainer className="animate" stepHeight={getStepHeight()}>
						<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
							<S.StepInner left right>
								<CreateAccountIntroductionStep
									ref={createAccountIntroductionStepRef}
									updateStep={setCurrentStep}
									updateHeight={setShouldUpdateHeight}
									isVisible={currentStep === 1}
								/>
							</S.StepInner>
						</S.Step>
						<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
							<S.StepInner left right>
								<CreateAccountStep
									ref={createAccountStepRef}
									updateStep={setCurrentStep}
									updateHeight={setShouldUpdateHeight}
									isVisible={currentStep === 2}
									childId={childId}
									setAuthenticationParams={setAuthenticationParams}
								/>
							</S.StepInner>
						</S.Step>
						<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
							<S.StepInner left right>
								<CompletionStep
									ref={completionStepRef}
									updateStep={setCurrentStep}
									updateHeight={setShouldUpdateHeight}
									isVisible={currentStep === 3}
									authenticationParams={authenticationParams}
								/>
							</S.StepInner>
						</S.Step>
					</S.StepContainer>

					{/* Primary Decoration */}
					<S.Decoration />

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

InviteChild.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	user: PropTypes.shape(),
};
InviteChild.defaultProps = {
	meta: {},
	locale: {},
	user: null,
};


/**
 * Exports
 */

export default InviteChild;
