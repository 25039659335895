/*
 * File: FunFactWidget.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 3:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

// Store
import { fetchFunFacts } from '../../store/slices/content/content.slice';

// Components
import { Typography } from '../Typography';
import { FunFactModal } from '../FunFactModal';

// Styles
import * as S from './FunFactWidget.styles';


/**
 * Component
 */

export const FunFactWidget = ({
	className
}) => {

	// Create state handlers
	const [isModalOpen, toggleModalOpen] = useState(false);
	const [viewedFunFacts, setViewedFunFacts] = useState([]);
	const [selectedFunFact, setSelectedFunFact] = useState(null);

	// Get fun facts from hook
	const funFacts = useSelector((state) => state.content.funfacts.value);
	const funFactsStatus = useSelector((state) => state.content.funfacts.status);

	// Create reference for components
	const isMounted = useRef(true);

	// Use hooks
	const dispatch = useDispatch();

	// Handle display fun fact
	const displayFunFact = () => {

		// Get available fun facts
		let availableFacts = funFacts?.filter(({ id }) => !viewedFunFacts.includes(id)) || [];

		// Reset viewed if necessary
		if (availableFacts.length === 0) {
			setViewedFunFacts([]);
			availableFacts = [...funFacts];
		}

		// Get random fact
		const randomFact = availableFacts[Math.floor(Math.random() * availableFacts.length)];

		// Update state
		setViewedFunFacts([...viewedFunFacts, randomFact.id]);
		setSelectedFunFact(randomFact);

		// Open modal
		setTimeout(() => {
			toggleModalOpen(true);
		}, 300);
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (funFactsStatus === 'idle') {

			// Fetch data state for page
			dispatch(fetchFunFacts());
		}
	}, [funFactsStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<S.Wrapper className={className}>

			{/* Desktop Version */}
			<S.DesktopButton className="isNotMobile">
				<Typography
					tag="p"
					variation="3"
					weight="semibold"
					onClick={displayFunFact}
				>
					Click for something fun
				</Typography>
			</S.DesktopButton>
			{/* <S.DesktopCard className="isNotMobileFlex" onClick={displayFunFact}>

				{/ * Title * /}
				<Typography tag="p" variation="2" weight="bold">Motivation</Typography>

				{/ * Icon * /}
				<FontAwesomeIcon icon={['fas', 'lightbulb-on']} />

				{/ * Prompt Content * /}
				<S.PromptContent>
					<Typography tag="p" weight="medium">
						Click here for a fun fact, meme, or quote.
					</Typography>
				</S.PromptContent>
			</S.DesktopCard> */}

			{/* Mobile Version */}
			<S.MobileCard className="isMobileFlex" onClick={displayFunFact}>
				<FontAwesomeIcon icon={['fas', 'lightbulb-on']} />
			</S.MobileCard>

			{/* Modal */}
			<FunFactModal funFact={selectedFunFact} isOpen={isModalOpen} handleClose={() => { toggleModalOpen(false); }} />

		</S.Wrapper>
	);
};


/**
 * Configuration
 */

FunFactWidget.displayName = 'FunFactWidget';
FunFactWidget.propTypes = {
	className: PropTypes.string
};
FunFactWidget.defaultProps = {
	className: null,
};
