/*
 * File: StepExplanationWidget.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 12, 2024 at 4:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card } from '../Card';

// Constants
import { AppNavSidebarWidth, AppNavTopbarHeight, mobileBreakpoint } from '../../styles/constants';
import { UI_MODE_OPTIONS } from '../../../Constants';


/**
 * Styles
 */

export const Popover = styled(Card)`
	position: absolute;
	padding: 20px;
	width: 400px;
	top: 310px;
	left: 250px;
	z-index: 1;
	text-align: center;
`;

export const ModalInner = styled.div`
	padding: 0px 0px 25px;
	text-align: center;
	width: 100%;
	text-align: center;
	margin: 0px auto;
	display: flex;
`;

export const ModalContainer = styled.div`
	.adjustedModal {
		left: 0px;
		top: ${(AppNavTopbarHeight - 59.0) / 2.0}px;

		@media only screen and (min-width: ${mobileBreakpoint}em) {
			left: ${AppNavSidebarWidth / 2.0}px;
			top: ${AppNavTopbarHeight / 2.0}px;
		}
	}
`;

export const InnerContent = styled.div`
	width: 100%;

	svg {
		color: ${({ theme }) => theme.brandPrimaryBase};
		font-size: 1.8rem;
	}
`;

export const TitleContainer = styled.div`
	h5 {
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const ActionContainer = styled.div`
	margin: 20px 0px 0px;

	button {
		margin: 0px auto;
		width: 140px;

		svg {
			color: ${({ theme }) => theme.primaryBackground};
			font-size: inherit;
		}
	}
`;

export const Arrow = styled.svg`
	left: 30px;
	display: block;
	width: 24px;
	height: 20px !important;
	position: absolute;
	top: -12px;
	fill: ${({ uiMode, theme }) => (uiMode === UI_MODE_OPTIONS.LIGHT ? theme.primaryBackground : theme.secondaryBackground)} !important;
`;

export const PopoverContainer = styled.div``;

export const ActionOverlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
`;

export const ContentContainer = styled.div`
	flex-grow: 1;

	* {
		text-align: ${({ $isColumnLayout }) => ($isColumnLayout ? 'left' : 'center')} !important;
		font-size: 0.845rem;
	}
	p {
		line-height: 160% !important;

		&:not(:last-child) {
			margin-bottom: 2px !important;
		}
	}
`;

export const ColumnContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 10px 0px 0px;
`;

export const Image = styled.div`
	margin: 8px 0px 0px;
	max-width: 20%;
	min-width: 80px;
	min-height: 100px;
	border-radius: 4px;
	background-image: ${({ $image }) => `url('${$image}')`};
	background-size: cover;
	background-position: 50% 0%;
	background-repeat: no-repeat;
`;

export const ActionsContainer = styled.div`
	margin-top: 15px;
`;

export const TimeEstimateContainer = styled.div`
	margin-top: 15px;
`;

export const ActionsInStep = styled.div`
	display: flex;
	gap: 15px;
	justify-content: center;
	margin: 6px 0px 10px;

	svg {
		color: ${({ theme }) => theme.brandSecondaryBase};
		font-size: 1.5rem;
	}
`;
