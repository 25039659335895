/*
 * File: UIModeWidget.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:21 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	span {
		font-size: 0.65rem;
		font-weight: 600;
	}
	.badge-icon {
		width: 0.9rem;
		height: 0.9rem;
	}
	.uiBadge {
		cursor: pointer;
	}
`;
