/*
 * File: AboutGenderStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 12:50 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { updateUserProfile } from '../../../../services/user';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, Typography
} from '../../../../components';

// Constants
import { GENDER_OPTIONS, ROLES } from '../../../../../Constants';

// Styles
import * as S from './AboutGenderStep.styles';


/**
 * Component
 */

export const AboutGenderStep = forwardRef(({
	updateStep, role, className
}, ref) => {

	// Create state handlers
	const [isLoading, setIsLoading] = useState(false);
	const [selectedGenders, setSelectedGenders] = useState([]);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get action from hooks
	const dispatch = useDispatch();

	// Handle next function
	const handleNext = async () => {

		// Validate parameters
		if (!selectedGenders || selectedGenders.length === 0) {
			toastError(uiMode, 'Please select the genders you identify as to continue');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserProfile({
			genders: selectedGenders
		}).then(async ({ data: { user: newUser } }) => {

			// Update user state
			dispatch(updateUser(newUser));

			// Update step
			if (role === ROLES.PARENT) {
				updateStep(7);
			} else {
				updateStep(6);
			}

			// Set loading state
			setIsLoading(false);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
			}
		});
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'user']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h3" weight="bold">Tell Us About Yourself</Typography>

				{/* Instructions */}
				<Typography tag="p" variation="1" weight="medium">What is your gender?</Typography>

				{/* Selection Options */}
				<S.SelectionContainer>
					{GENDER_OPTIONS[role].map((option) => (
						<S.GenderOption
							key={option.value}
							className="animate"
							onClick={() => {
								let selected = [...selectedGenders];
								if (selected.includes(option.value)) {
									selected = selected.filter((s) => s !== option.value);
								} else {
									selected.push(option.value);
								}
								setSelectedGenders(selected);
							}}
							$isSelected={selectedGenders.includes(option.value)}
						>
							<Typography tag="p">{option.label}</Typography>
						</S.GenderOption>
					))}
				</S.SelectionContainer>

				{/* Next Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">Next</Button>

				{/* Back Button */}
				<Button
					disabled={isLoading}
					onClick={() => { updateStep(role === ROLES.PARENT ? 5 : 4); }}
					size="small"
					variant="text"
					style={{ margin: '10px 0px -5px' }}
				>
					<FontAwesomeIcon icon={['fas', 'arrow-left']} />
					<Typography variation="button-medium" weight="semibold">Go Back</Typography>
				</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

AboutGenderStep.displayName = 'AboutGenderStep';
AboutGenderStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	role: PropTypes.string,
};
AboutGenderStep.defaultProps = {
	className: null,
	role: null
};
