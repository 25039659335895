/*
 * File: confetti.slice.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on June 1, 2024 at 8:41 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: June 3, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		RewardsProgressWidget: { shown: false }
	},
};


/**
 * Slices
 */

export const confettiSlice = createSlice({
	name: 'confetti',
	initialState,
	reducers: {
		updateConfettiState: (state, action) => {

			// Set state
			state.value = {
				...state.value,
				...action.payload,
			};
		},
		clearConfettiState: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateConfettiState, clearConfettiState } = confettiSlice.actions;

export default confettiSlice.reducer;
