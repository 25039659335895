/*
 * File: ReasonStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 16, 2024 at 11:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError } from '../../../../utilities/toaster';
import { parseFirstLastName } from '../../../../../utilities/utilities';

// Services
import { updateUserProfile } from '../../../../services/user';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, Select, Typography
} from '../../../../components';

// Constants
import { REASON_FOR_USING_OPTIONS } from '../../../../../Constants';

// Styles
import * as S from './ReasonStep.styles';


/**
 * Component
 */

export const ReasonStep = forwardRef(({
	updateStep, className
}, ref) => {

	// Create state handlers
	const [reasonError, setReasonError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get action from hooks
	const dispatch = useDispatch();

	// Handle next function
	const handleNext = async () => {

		// Get parameters
		const { reason } = inputValues;

		// Validate parameters
		if (!reason || validator.isEmpty(reason, { ignore_whitespace: true })) {
			setReasonError({ message: 'Please select a reason to continue' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserProfile({
			reasonForUsing: reason
		}).then(async ({ data: { user: newUser } }) => {

			// Update user state
			dispatch(updateUser(newUser));

			// Update step
			updateStep(3);

			// Set loading state
			setIsLoading(false);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setReasonError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'hand-wave']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h2" weight="bold">
					Welcome,
					{' '}
					{parseFirstLastName(user?.name).firstName}
					!
				</Typography>

				{/* Instructions */}
				<Typography tag="p" variation="1" weight="medium">What is your #1 reason for using Let’s Talk?</Typography>

				{/* Inputs */}
				<Select
					name="reason"
					options={REASON_FOR_USING_OPTIONS.map(({ reason }) => ({ label: reason, value: reason }))}
					error={reasonError}
					values={Array.isArray(inputValues.reason) ? inputValues.reason : [inputValues.reason || '']}
					containerClassName="inputSpacer"
					onFocus={() => { setReasonError(null); }}
					onKeyUp={() => { setReasonError(null); }}
					onBlur={() => { setReasonError(null); }}
					onChange={handleOnChange}
				/>

				{/* Next Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">Next</Button>

				{/* Back Button */}
				<Button
					disabled={isLoading}
					onClick={() => { updateStep(1); }}
					size="small"
					variant="text"
					style={{ margin: '10px 0px -5px' }}
				>
					<FontAwesomeIcon icon={['fas', 'arrow-left']} />
					<Typography variation="button-medium" weight="semibold">Go Back</Typography>
				</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

ReasonStep.displayName = 'ReasonStep';
ReasonStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
};
ReasonStep.defaultProps = {
	className: null,
};
