/*
 * File: ForgotPassword.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:23 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { renderLogo } from '../../../utilities/image';

// Services
import { beginPasswordReset } from '../../../services/password';

// Components
import {
	Meta, Typography, TextInput, Button, Card,
	UIModeWidget
} from '../../../components';

// Styles
import * as S from './ForgotPassword.styles';


/**
 * Component
 */

const ForgotPassword = ({ meta, locale }) => {

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get actions from hooks
	const navigate = useNavigate();

	// Handle log in function
	const handleForgotPassword = async () => {

		// Get parameters
		const { email } = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your account email address' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Begin password reset process
		beginPasswordReset({ email }).then(() => {

			// Show success toast
			toastSuccess(uiMode, 'Success! Check your inbox for an email to reset your account password.', { duration: 3000 });

			// Clear email
			setInputValues({ ...inputValues, email: '' });

			// Handle completion actions
			setTimeout(() => {
				navigate(`${stateLocale.localePath}/login`);
			}, 3000);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setEmailError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, 'Whoops. We can\'t find an account with those details. Give it another go.');
			} else {
				toastError(uiMode, 'Whoops. We can\'t find an account with those details. Give it another go.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle log in action function
	const logInFunction = () => {
		navigate(`${stateLocale.localePath}/login`);
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle render content
	const renderContent = () => (
		<S.Form>

			{/* Card */}
			<Card className="cardPadding">

				{/* Content */}
				<Typography tag="h2" weight="bold">Forgot Password?</Typography>
				<Typography tag="p" variation="1" weight="regular">Enter your account email and we&apos;ll send you a link to reset your password.</Typography>

				{/* Inputs */}
				<TextInput
					label="Account Email"
					name="email"
					type="email"
					error={emailError}
					value={inputValues.email || ''}
					autoComplete="email"
					onFocus={() => { setEmailError(null); }}
					onKeyUp={() => { setEmailError(null); }}
					onBlur={() => { setEmailError(null); }}
					onChange={handleOnChange}
				/>

				{/* Send Button */}
				<S.ActionButton
					disabled={isLoading}
					isLoading={isLoading}
					onClick={handleForgotPassword}
					size="large"
				>
					Send Reset Link
				</S.ActionButton>

				{/* Log In Button */}
				<S.BottomActionContainer>
					<Button variant="text" size="small" onClick={logInFunction} disabled={isLoading}>
						<Typography tag="p" variation="2" weight="medium">
							Ready to log in?
							{' '}
							<Typography weight="bold">
								Back to Log In
							</Typography>
						</Typography>
					</Button>
				</S.BottomActionContainer>
			</Card>
		</S.Form>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper>

				{/* Logo */}
				<S.Logo src={renderLogo(uiMode)} title="Let's Talk Logo" alt="Let's Talk Logo" />

				{/* Form Container */}
				<S.FormContainer left right>
					{renderContent()}
				</S.FormContainer>

				{/* UI Mode Widget */}
				<UIModeWidget className="uiModeWidget" />

				{/* Decoration */}
				<S.Decoration />

			</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

ForgotPassword.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
ForgotPassword.defaultProps = {
	meta: {},
	locale: {},
};


/**
 * Exports
 */

export default ForgotPassword;
