/*
 * File: ConfirmationModal.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 4:49 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Typography } from '../Typography';

// Styles
import * as S from './ConfirmationModal.styles';


/**
 * Component
 */

export const ConfirmationModal = ({
	className, isOpen, handleClose, type, title, message, primaryButtonTitle, secondaryButtonTitle, primaryButtonAction, secondaryButtonAction, isLoading
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Icon */}
				<S.IconContainer $type={type}><FontAwesomeIcon icon={type === 'danger' ? ['fas', 'triangle-exclamation'] : ['fas', 'question']} /></S.IconContainer>

				{/* Content */}
				<Typography tag="h3" weight="bold">{title}</Typography>
				<Typography tag="p" variation="1" weight="light" className="subtitle">{message}</Typography>

				{/* Actions */}
				<S.ActionContainer>
					<Button disabled={isLoading} variant="outline" variation="secondary" onClick={secondaryButtonAction}>{secondaryButtonTitle}</Button>
					<Button disabled={isLoading} isLoading={isLoading} variant="solid" variation={type === 'danger' ? 'warning' : 'default'} onClick={primaryButtonAction}>{primaryButtonTitle}</Button>
				</S.ActionContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

ConfirmationModal.displayName = 'ConfirmationModal';
ConfirmationModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	type: PropTypes.oneOf(['normal', 'danger']),
	title: PropTypes.string,
	message: PropTypes.string,
	primaryButtonTitle: PropTypes.string,
	secondaryButtonTitle: PropTypes.string,
	primaryButtonAction: PropTypes.func,
	secondaryButtonAction: PropTypes.func,
	isLoading: PropTypes.bool
};
ConfirmationModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	type: 'normal',
	title: 'Are you sure?',
	message: 'Are you sure you want to do this? This cannot be undone?',
	primaryButtonTitle: 'Yes',
	secondaryButtonTitle: 'Cancel',
	primaryButtonAction: null,
	secondaryButtonAction: null,
	isLoading: false
};
