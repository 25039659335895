/*
 * File: Topbar.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:37 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { LocaleLink } from '../../LocaleLink';

// Colors
import { ColorValues } from '../../../styles/colors';

// Constants
import { AppNavTopbarHeight } from '../../../styles/constants';


/**
 * Styles
 */

export const Topbar = styled.div`
	height: ${AppNavTopbarHeight}px;
	min-height: ${AppNavTopbarHeight}px;
	display: flex;
	background-color: ${({ theme }) => theme.primaryBackground};
	z-index: 1000;
	box-shadow: ${ColorValues.navigationShadow};
	border-bottom: 1px solid ${({ theme }) => theme.navigationBorder};
	padding: 0.78rem 0;
	pointer-events: all;
	position: fixed !important;
	width: 100%;
	top: 0px !important;

	* :not(svg,path,div) {
		height: 100%;
	}
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;

	${({ fullWidth }) => fullWidth && css`
		justify-content: center;
		flex: 1;
	`}
`;

export const NavigationTabContainer = styled.div`
	height: ${AppNavTopbarHeight}px !important;

	& div {
		display: flex;
	}
`;

export const LogoImage = styled.img`
	padding: 0.1rem 0px 0.1rem 0;
`;

export const NavigationLink = styled(LocaleLink)`
	height: auto !important;
	text-decoration: none;
	margin-right: 20px;

	&:hover {
		opacity: 0.5;
	}
`;
