/*
 * File: UnitProgress.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: June 3, 2024 at 11:02 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 100px;
	left: 100px;
	width: 200px;
	height: 200px;
	z-index: 1;
`;

export const UnitProgressContainer = styled.div`
	width: 86%;
	margin: 0px auto;
`;

export const UnitProgressInner = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	* {
		color: ${({ theme, $isDarkBackground }) => ($isDarkBackground ? ColorValues.white : theme.brandQuaternaryBase)};
	}
`;
