/*
 * File: FunFactModal.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 1:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import 'isomorphic-fetch';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import {
	render, MARK_LINK, NODE_EMOJI, NODE_PARAGRAPH
} from 'storyblok-rich-text-react-renderer';

// Components
import { Modal } from '../Modal';
import { Spinner } from '../Spinner';
import { Typography } from '../Typography';
import { LocaleLink } from '../LocaleLink';
import { Emoji } from '../Emoji';
import { Video } from '../Video';

// Styles
import * as S from './FunFactModal.styles';


/**
 * Configuration
 */

storyblokInit({
	accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
	apiOptions: {
		region: 'us'
	},
	use: [apiPlugin]
});

const richTextConfiguration = {
	markResolvers: {
		[MARK_LINK]: (children, { href, ...rest }) => <LocaleLink {...rest} to={href} target="_blank">{children}</LocaleLink>
	},
	nodeResolvers: {
		[NODE_EMOJI]: (children, { name, emoji }) => <Emoji label={name} symbol={emoji} size={1.2} />,
		[NODE_PARAGRAPH]: (children) => <Typography tag="p">{children}</Typography>
	},
	blokResolvers: {
		video: ({ /* eslint-disable-line react/prop-types */ video }) => {
			const { filename } = video;
			return (
				<Video url={filename} />
			);
		}
	}
};


/**
 * Component
 */

export const FunFactModal = ({
	className, isOpen, handleClose, funFact
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<Modal className={className ? `${className} adjustedModal` : 'adjustedModal'} variant="medium" isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper showClose>
			<S.ModalInner>

				{/* Icon */}
				<S.IconContainer><FontAwesomeIcon icon={['fas', 'lightbulb-on']} /></S.IconContainer>

				{/* Title */}
				<Typography tag="h5" weight="bold">Motivation</Typography>

				{/* Content */}
				<S.ContentContainer>
					{funFact ? <S.Content className="paragraphContent">{render(funFact.content, richTextConfiguration)}</S.Content> : <Spinner />}
				</S.ContentContainer>

			</S.ModalInner>
		</Modal>
	);
};


/**
 * Configuration
 */

FunFactModal.displayName = 'FunFactModal';
FunFactModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	funFact: PropTypes.shape()
};
FunFactModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null,
	funFact: null
};
