/*
 * File: CompletionStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 9:33 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	forwardRef, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Howl } from 'howler';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { updateUserProfile } from '../../../../services/user';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, ConfettiBurst, Typography
} from '../../../../components';

// Constants
import { ROLES } from '../../../../../Constants';

// Styles
import * as S from './CompletionStep.styles';


/**
 * Component
 */

export const CompletionStep = forwardRef(({
	className, isVisible, role
}, ref) => {

	// Create state handlers
	const [isLoading, setIsLoading] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Create sound hooks
	const fanfareSound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/fanfare.mp3`],
		html5: true,
		volume: 0.1
	}), []);

	// Get action from hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Handle next function
	const handleNext = async () => {

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserProfile({
			onboardingComplete: true
		}).then(async ({ data: { user: newUser } }) => {

			// Update user state
			dispatch(updateUser(newUser));

			// Update step
			navigate(`${stateLocale.localePath}/dashboard`);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
			}
		});
	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible === true) {

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 500);

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 4000);
		}
	}, [isVisible]);

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<>
			<S.StepContainer ref={ref} className={className}>

				{/* Card */}
				<Card className="cardPadding">

					{/* Icon */}
					<S.IconContainer>
						<FontAwesomeIcon icon={['fal', 'fire']} />
						<S.ConfettiTarget ref={confettiTargetRef} />
					</S.IconContainer>

					{/* Title */}
					<Typography tag="h3" weight="bold">You are a Rockstar!</Typography>

					{/* Instructions */}
					<Typography tag="p" variation="1" weight="medium">
						{role === ROLES.CHILD ? (
							<>
								You’re all set to start learning about your body, growing up and relationships. Let’s dive in and have some fun!
							</>
						) : (
							<>
								You are all set up and ready to start your journey to better communication with your child about puberty, bodies, sex, and relationships. Get started now!
							</>
						)}
					</Typography>

					{/* Next Button */}
					<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">Continue</Button>

				</Card>
			</S.StepContainer>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
					audioHook={fanfareSound}
				/>
			)}
		</>
	);
});


/**
 * Configuration
 */

CompletionStep.displayName = 'CompletionStep';
CompletionStep.propTypes = {
	className: PropTypes.string,
	isVisible: PropTypes.bool,
	role: PropTypes.string
};
CompletionStep.defaultProps = {
	className: null,
	isVisible: false,
	role: null
};
