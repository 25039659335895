/*
 * File: JourneyGoal.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: June 23, 2024 at 7:46 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Constants
import { ACTIVITY_MODULE_STATUSES, ROLES } from '../../../Constants';

// Styles
import * as S from './JourneyGoal.styles';


/**
 * Constants
 */

const GOAL_SIZE = 0.112;
const AVATAR_CONTAINER_TOP = -0.024;
const AVATAR_CONTAINER_GAP = 0.048;
const AVATAR_HEIGHT = 0.036;
const AVATAR_DECORATION_SIZE = 0.032;
const AVATAR_DECORATION_TOP = 0.005;


/**
 * Component
 */

export const JourneyGoal = ({
	className, position, containerWidth
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get primary user module progress
	const primaryUserJourneyComplete = useMemo(() => {

		// Get user parameters
		const { journey } = user;

		// Check if all completed
		const allCompleted = journey?.every((unitObj) => unitObj.modules.every((moduleObj) => moduleObj.status === ACTIVITY_MODULE_STATUSES.COMPLETE || moduleObj.isCompleted === true));

		// Return module progress
		return allCompleted;

	}, [user]);

	// Get secondary user module progress
	const secondaryUsersJourneyComplete = useMemo(() => {

		// Create progress for user role
		if (user.role.primary === ROLES.CHILD && user.parent) {

			// Get parent parameters
			const { journey } = user.parent;

			// Check if all completed
			const allCompleted = journey?.every((unitObj) => unitObj.modules.every((moduleObj) => moduleObj.status === ACTIVITY_MODULE_STATUSES.COMPLETE || moduleObj.isCompleted === true));

			// Return progress
			return [{ allCompleted, user: user.parent }];

		} if (user.role.primary === ROLES.PARENT && user.children) {

			// Build progress objects for children
			return user.children.map((child) => {

				// Get parent parameters
				const { journey } = child;

				// Check if all completed
				const allCompleted = journey?.every((unitObj) => unitObj.modules.every((moduleObj) => moduleObj.status === ACTIVITY_MODULE_STATUSES.COMPLETE || moduleObj.isCompleted === true));

				// Return progress
				return { allCompleted, user: child };

			});
		}
		return false;
	}, [user]);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper
			className={className}
			style={{
				left: `${position.left * containerWidth}px`,
				top: `${position.top * containerWidth}px`,
				width: `${GOAL_SIZE * containerWidth}px`,
				height: `${GOAL_SIZE * containerWidth}px`
			}}
		>
			<S.Goal id={primaryUserJourneyComplete ? 'current-active-step' : 'end-goal'} src={`${process.env.CDN_URL}/public/assets/decorations/path/extras/finish.png`} title="Journey Goal" alt="Journey Goal" />
			{primaryUserJourneyComplete && (
				<S.AvatarContainer style={{
					top: `${AVATAR_CONTAINER_TOP * containerWidth}px`,
					gap: `${AVATAR_CONTAINER_GAP * containerWidth}px`
				}}
				>
					<S.AvatarOption>
						<S.Avatar
							src={user.avatarImage.image}
							alt="I am here"
							title="I am here"
							style={{ height: `${AVATAR_HEIGHT * containerWidth}px` }}
						/>
						<S.AvatarDecoration
							style={{
								height: `${AVATAR_DECORATION_SIZE * containerWidth}px`,
								width: `${AVATAR_DECORATION_SIZE * containerWidth}px`,
								top: `-${AVATAR_DECORATION_TOP * containerWidth}px`
							}}
						/>
					</S.AvatarOption>
					{secondaryUsersJourneyComplete.map(({ allCompleted, user: secondaryUser }) => (
						allCompleted && secondaryUser?.onboardingComplete ? (
							<S.AvatarOption>
								<S.Avatar
									src={secondaryUser?.avatarImage?.image}
									alt="User is here"
									title="User is here"
									style={{ height: `${AVATAR_HEIGHT * containerWidth}px` }}
								/>
								<S.AvatarDecoration style={{
									height: `${AVATAR_DECORATION_SIZE * containerWidth}px`,
									width: `${AVATAR_DECORATION_SIZE * containerWidth}px`,
									top: `-${AVATAR_DECORATION_TOP * containerWidth}px`
								}}
								/>
							</S.AvatarOption>
						) : false
					)).filter(Boolean)}
				</S.AvatarContainer>
			)}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

JourneyGoal.displayName = 'JourneyGoal';
JourneyGoal.propTypes = {
	className: PropTypes.string,
	position: PropTypes.shape(),
	containerWidth: PropTypes.number
};
JourneyGoal.defaultProps = {
	className: null,
	position: {},
	containerWidth: 1500
};
