/*
 * File: CookiePolicy.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 1, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';
import { setCookie } from '../../../../utilities/cookies';

// Slices
import { updateConsent } from '../../../../store/slices/consent/consent.slice';

// Constants
import {
	CONSENT_COOKIE_EXPIRATION,
	CONSENT_ACTION_TAKEN_COOKIE,
	CONSENT_PERFORMANCE_ENABLED_COOKIE,
	CONSENT_ANALYTICS_ENABLED_COOKIE,
	CONSENT_TARGETING_ENABLED_COOKIE
} from '../../../../../Constants';

// Components
import {
	Meta, ContentNavigation, LegalNavigation, Typography, Emoji, LocaleLink, Checkbox
} from '../../../../components';

// Styles
import * as S from './CookiePolicy.styles';
import { HighlightSection, HighlightTitle } from '../../../../components/LegalNavigation/LegalNavigation.styles';


/**
 * Component
 */

const CookiePolicy = ({ meta, locale }) => {

	// Get actions from hooks
	const dispatch = useDispatch();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);
	const currentConsent = useSelector((state) => state.consent.currentValue);

	// Get consent state
	const { performanceEnabled, analyticsEnabled, targetingEnabled } = currentConsent;

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, checked
		} = event.target;

		// Handle consent action
		switch (name) {
			case 'performanceEnabled':

				// Set cookie values
				setCookie(CONSENT_ACTION_TAKEN_COOKIE, 'true', CONSENT_COOKIE_EXPIRATION);
				setCookie(CONSENT_PERFORMANCE_ENABLED_COOKIE, `${checked}`, CONSENT_COOKIE_EXPIRATION);

				// Update consent state
				dispatch(updateConsent({
					consentActionTaken: true,
					performanceEnabled: checked,
					analyticsEnabled,
					targetingEnabled
				}));
				break;
			case 'analyticsEnabled':

				// Set cookie values
				setCookie(CONSENT_ACTION_TAKEN_COOKIE, 'true', CONSENT_COOKIE_EXPIRATION);
				setCookie(CONSENT_ANALYTICS_ENABLED_COOKIE, `${checked}`, CONSENT_COOKIE_EXPIRATION);

				// Update consent state
				dispatch(updateConsent({
					consentActionTaken: true,
					performanceEnabled,
					analyticsEnabled: checked,
					targetingEnabled
				}));
				break;
			case 'targetingEnabled':

				// Set cookie values
				setCookie(CONSENT_ACTION_TAKEN_COOKIE, 'true', CONSENT_COOKIE_EXPIRATION);
				setCookie(CONSENT_TARGETING_ENABLED_COOKIE, `${checked}`, CONSENT_COOKIE_EXPIRATION);

				// Update consent state
				dispatch(updateConsent({
					consentActionTaken: true,
					performanceEnabled,
					analyticsEnabled,
					targetingEnabled: checked
				}));
				break;
			default:
				break;
		}
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<ContentNavigation>
				<LegalNavigation>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="bold">
							Cookie Policy
						</Typography>

						{/* TL;DR */}
						<HighlightSection>
							<HighlightTitle tag="h5" weight="bold">
								TL;DR
								<Emoji symbol="👀" label="look" size={1.5} />
							</HighlightTitle>
							<Typography tag="p" weight="light">
								Cookies are small data files that are placed on your computer or mobile device when you visit a website. We use
								both first-party cookies (created by us) and third-party cookies (added by one of our software partners) to enable
								features, personalize the Let&apos;s Talk experience, fix issues, and serve ads. If you want to control which cookies are
								enabled on Let&apos;s Talk, check out our
								{' '}
								<LocaleLink to="/legal/cookie-policy#settings">cookie preferences</LocaleLink>
								{' '}
								at the bottom of this page.
							</Typography>
						</HighlightSection>

						{/* Updated Date */}
						<Typography tag="p" weight="light" fontStyle="italic">
							Last updated April 2024
						</Typography>

						{/* Introduction */}
						<Typography tag="p" weight="light">
							This Cookie Statement, provided by Let&apos;s Talk, (&quot;Let&apos;s Talk&quot;, &quot;we&quot;, &quot;us&quot;, and &quot;our&quot;),
							explains how we use cookies and similar technologies in the course of our business, including
							through our websites that link to this Cookie Statement, such as
							{' '}
							<LocaleLink target="_blank" to="https://www.lets-talk-app.com" rel="noreferrer">https://www.lets-talk-app.com</LocaleLink>
							, or any website or mobile application owned, operated or controlled by us (collectively,
							&quot;Let&apos;s Talk Sites&quot; or &quot;Let&apos;s Talk Site&quot; or &quot;Let&apos;s Talk Apps&quot;). It explains what these technologies
							are and why we use them, as well as your rights to control our use of them. This Cookie Statement is
							and will be treated as part of our
							{' '}
							<LocaleLink target="_blank" to="/legal/terms">Terms of Service</LocaleLink>
							,
							and is automatically incorporated therein. Capitalized terms used and not otherwise defined in this
							Cookie Statement have the meanings given to those terms in our
							{' '}
							<LocaleLink target="_blank" to="/legal/privacy">Privacy Policy</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							In some cases we may use cookies and other tracking technologies described in this Cookie Statement
							to collect Personal Information, or to collect information that becomes Personal Information if we
							combine it with other information. For more information about how we process your Personal Information,
							please consult our
							{' '}
							<LocaleLink target="_blank" to="/legal/privacy">Privacy Policy</LocaleLink>
							.
						</Typography>

						{/* What are cookies? */}
						<br />
						<Typography tag="h3" weight="bold">
							What are cookies?
						</Typography>
						<Typography tag="p" weight="light">
							Cookies are small data files that are placed on your computer or mobile device when you visit a website.
							Cookies are widely used by online service providers in order to (for example) make their websites or
							services work, or to work more efficiently, as well as to provide reporting information.
						</Typography>
						<Typography tag="p" weight="light">
							Cookies set by the website owner or service provider (in this case, Let&apos;s Talk) are called &quot;first-party
							cookies&quot;. Cookies set by parties other than the website owner are called &quot;third-party cookies&quot;.
							Third-party cookies enable third-party features or functionality to be provided on or through the
							website or service you are using (such as advertising, interactive content and analytics). The third
							parties that set these third-party cookies can recognize your computer both when it visits the website
							or service in question and also when it visits certain other websites or services.
						</Typography>

						{/* Why do we use cookies and other tracking technologies? */}
						<br />
						<Typography tag="h3" weight="bold">
							Why do we use cookies and other tracking technologies?
						</Typography>
						<Typography tag="p" weight="light">
							We use first-party and third-party cookies for several reasons. Some cookies are required for technical
							reasons in order for our Let&apos;s Talk Sites and Service to operate, and we refer to these as &quot;essential&quot;
							or &quot;strictly necessary&quot; cookies. Other cookies enable us and the third parties we work with to track
							and target the interests of Visitors to our Let&apos;s Talk Sites, and we refer to these as &quot;performance&quot; or
							&quot;functionality&quot; cookies. For example, we use cookies to tailor content and information that we may send
							or display to you and otherwise personalize your experience while interacting with our Let&apos;s Talk Sites
							and to otherwise improve the functionality of the Service we provide. Finally, third parties serve
							cookies through our Let&apos;s Talk Sites and Service for advertising, analytics, and other purposes.
							This is described in more detail below.
						</Typography>

						{/* Cookies served through our Let&apos;s Talk Sites */}
						<br />
						<Typography tag="h3" weight="bold">
							Cookies served through our Let&apos;s Talk Sites
						</Typography>
						<Typography tag="p" weight="light">
							The specific types of first and third-party cookies served through our Let&apos;s Talk Sites and the purposes
							they perform are described in further detail below:
						</Typography>

						{/* Essential Website Cookies */}
						<br />
						<Typography tag="h4" weight="bold">
							Essential Website Cookies
						</Typography>
						<Typography tag="p" weight="light">
							These cookies are strictly necessary to provide you with services available through our Let&apos;s Talk Sites
							and to use some of their features, such as access to secure areas. Because these cookies are strictly
							necessary to deliver the Let&apos;s Talk Sites, you cannot refuse them without impacting how our Let&apos;s Talk Sites
							function. You can block or delete them by changing your browser settings, as described under the
							heading &quot;How can I control cookies?&quot; in this Cookie Statement.
						</Typography>

						{/* Cookies Table */}
						<S.TableOverflow>
							<S.SettingsTable>
								<thead>
									<tr>
										<th>Categories</th>
										<th>Cookies</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>Let&apos;s Talk</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies are strictly necessary to provide you with services available
												through our Let&apos;s Talk Sites and to use some of its features, such as access to secure areas.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												e_s_token
												<br />
												c_action_taken
												<br />
												c_performance_enabled
												<br />
												c_analytics_enabled
												<br />
												c_targeting_enabled
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Cloudflare</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies are strictly necessary to allow Let&apos;s Talk to host content in global servers and load content quicker.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												_cfduid
												<br />
												_cflb
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Mapbox</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies are strictly necessary to allow Let&apos;s Talk to render interactive maps.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												_cioid
												<br />
												ajs_user_id
											</Typography>
										</td>
									</tr>
								</tbody>
							</S.SettingsTable>
						</S.TableOverflow>

						{/* Performance and Functionality Cookies */}
						<br />
						<Typography tag="h4" weight="bold">
							Performance and Functionality Cookies
						</Typography>
						<Typography tag="p" weight="light">
							These cookies are used to enhance the performance and functionality of our Let&apos;s Talk Sites. They help us
							to know which pages are the most and least popular and to see how visitors move around the Let&apos;s Talk Sites.
							All information these cookies collect is aggregated. Although important to us, these cookies are
							non-essential to the use of the Let&apos;s Talk Sites. However, without these cookies, certain functionality
							may become unavailable.
						</Typography>

						{/* Cookies Table */}
						<S.TableOverflow>
							<S.SettingsTable>
								<thead>
									<tr>
										<th>Categories</th>
										<th>Cookies</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>Let&apos;s Talk</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies are used to enhance the performance and functionality of our
												Let&apos;s Talk Sites but are non-essential to their use.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												pl_locale
												<br />
												ui_mode
												<br />
												p_ui_view_mode
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Google</strong>
											<br />
											<br />
											<Typography weight="light">
												Provides access to website options and services and protection from
												cross-site request forgery attacks.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												__Secure-*
												<br />
												SEARCH_SAMESITE
												<br />
												UULE
												<br />
												OTZ
											</Typography>
										</td>
									</tr>
								</tbody>
							</S.SettingsTable>
						</S.TableOverflow>

						{/* Analytics and Customization Cookies */}
						<br />
						<Typography tag="h4" weight="bold">
							Analytics and Customization Cookies
						</Typography>
						<Typography tag="p" weight="light">
							These cookies collect information that is used either in aggregate form to help us understand how
							our Let&apos;s Talk Sites are being used or how effective our marketing campaigns are, or to help us
							customize our Let&apos;s Talk Sites and application for you in order to enhance your experience. These
							cookies may be set by us or by third-party providers whose services we have added to our pages.
						</Typography>

						{/* Cookies Table */}
						<S.TableOverflow>
							<S.SettingsTable>
								<thead>
									<tr>
										<th>Categories</th>
										<th>Cookies</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>Google</strong>
											<br />
											<br />
											<Typography weight="light">
												These are set by Google Universal Analytics and Google Optimize. They are used
												to calculate visitor, session and campaign data for our Let&apos;s Talk Sites&apos; analytics reports.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												_gid
												<br />
												_gat
												<br />
												_ga
												<br />
												__Secure-3PSID
												<br />
												__Secure-3PAPISID
												<br />
												_gaexp
												<br />
												_gac_UA-131770671-1
												<br />
												AEC
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Hotjar</strong>
											<br />
											<br />
											<Typography weight="light">
												These are set by Hotjar. They are used to determine session activity for a user,
												anonymous actions taken, and feedback given on the Let&apos;s Talk Sites.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												_hjClosedSurveyInvites
												<br />
												_hjDonePolls
												<br />
												_hjMinimizedPolls
												<br />
												_hjShownFeedbackMessage
												<br />
												_hjid
												<br />
												_hjRecordingLastActivity
												<br />
												_hjTLDTest
												<br />
												_hjUserAttributesHash
												<br />
												_hjCachedUserAttributes
												<br />
												_hjLocalStorageTest
												<br />
												_hjIncludedInSample
												<br />
												_hjIncludedInSessionSample
												<br />
												_hjAbsoluteSessionInProgress
												<br />
												_BEAMER_
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Segment</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies are set for analytics and help count how many people visit
												our Let&apos;s Talk Sites by tracking if a user has visited before.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												ajs_anonymous_id
												<br />
												mkjs_user_id
												<br />
												mkjs_group_id
												<br />
												_mkto_trk
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Twitter</strong>
											<br />
											<br />
											<Typography weight="light">
												This cookie helps Twitter improve and understand how people use their services,
												including Twitter buttons and widgets, and Twitter Ads.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												lang
											</Typography>
										</td>
									</tr>
								</tbody>
							</S.SettingsTable>
						</S.TableOverflow>

						{/* Advertising (Targeting) Cookies */}
						<br />
						<Typography tag="h4" weight="bold">
							Advertising (Targeting) Cookies
						</Typography>
						<Typography tag="p" weight="light">
							These cookies are used to make advertising messages more relevant to you and your interests. They
							also perform functions like preventing the same ad from continuously reappearing, ensuring that ads
							are properly displayed, and in some cases selecting advertisements that are based on your interests. For
							further information, see the section of the Cookie Statement entitled &quot;Targeted online advertising&quot;.
						</Typography>

						{/* Cookies Table */}
						<S.TableOverflow>
							<S.SettingsTable>
								<thead>
									<tr>
										<th>Categories</th>
										<th>Cookies</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>Google</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies enable behavioral advertising and analytics by Google.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												APISID
												<br />
												SSID
												<br />
												NID
												<br />
												PREF
												<br />
												drt
												<br />
												SID
												<br />
												SAPISID
												<br />
												id
												<br />
												HSID
												<br />
												1P_JAR
												<br />
												ANID
												<br />
												SIDCC
												<br />
												DV
												<br />
												_gcl_au
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>YouTube</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies enable behavioral advertising and analytics by YouTube.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												SID
												<br />
												HSID
												<br />
												demographics
												<br />
												VISITOR_INFO1_LIVE
												<br />
												PREF
												<br />
												APISID
												<br />
												SSID
												<br />
												LOGIN_INFO
												<br />
												YSC
												<br />
												SAPISID
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Twitter</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies enable behavioral advertising and analytics by Twitter.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												auth_token
												<br />
												lang
												<br />
												twll
												<br />
												__utmz
												<br />
												secure_session
												<br />
												guest_id
												<br />
												__utma
												<br />
												remember_checked
												<br />
												remember_checked_on
												<br />
												__utmb
												<br />
												personalization_id
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Facebook</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies and tracking pixels enable behavioral advertising and analytics by Facebook.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												datr
												<br />
												x-src
												<br />
												fr
												<br />
												lu
												<br />
												locale
												<br />
												_fbp
												<br />
												c_user
												<br />
												wd
												<br />
												dpr
												<br />
												presence
												<br />
												sb
												<br />
												xs
												<br />
												act
												<br />
												spin
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>LinkedIn</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies enable behavioral advertising and analytics by LinkedIn.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												__utmb
												<br />
												JSESSIONID
												<br />
												UserMatchHistory
												<br />
												_bizo_cksm
												<br />
												_lipt
												<br />
												BizoID
												<br />
												lidc
												<br />
												bcookie
												<br />
												RT
												<br />
												__utma
												<br />
												__utmv
												<br />
												__utmz
												<br />
												_bizo_np_stats
												<br />
												__qca
												<br />
												lang
												<br />
												BizoData
												<br />
												BizoUserMatchHistory
												<br />
												leo_auth_token
												<br />
												visit
											</Typography>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Bing</strong>
											<br />
											<br />
											<Typography weight="light">
												These cookies enable behavioral advertising and analytics by Bing Ads.
											</Typography>
										</td>
										<td>
											<Typography weight="light">
												_uetvid
											</Typography>
										</td>
									</tr>
								</tbody>
							</S.SettingsTable>
						</S.TableOverflow>

						{/* Other tracking technologies */}
						<br />
						<Typography tag="h4" weight="bold">
							Other tracking technologies
						</Typography>
						<Typography tag="p" weight="light">
							We and our third-party partners may use other, similar technologies from time to time, like SDKs,
							pixels, (or &quot;clear gifs&quot;) and other tracking technologies. Pixels are tiny graphics files that
							contain a unique identifier that enable us to recognize when someone has visited our Let&apos;s Talk Sites or,
							in the case of SDKS, visited our Apps. This allows us, for example, to monitor the traffic patterns of
							Visitors from one page within our Let&apos;s Talk Sites to another, to deliver or communicate with cookies,
							to understand whether you have come to our Let&apos;s Talk Sites from an online advertisement displayed on a
							third-party website, to serve targeted advertisements to you and others like you, to improve site
							performance, and to measure the success of marketing campaigns. While you may not have the ability to
							specifically reject or disable these tracking technologies, in many instances, these technologies are
							reliant on cookies to function properly; accordingly, in those instances, declining cookies will impair
							the functionality of these technologies.
						</Typography>

						{/* Targeted online advertising */}
						<br />
						<Typography tag="h3" weight="bold">
							Targeted online advertising
						</Typography>
						<Typography tag="p" weight="light">
							We have engaged one or more third-party service providers to track and analyze both individualized usage
							and volume statistical information from interactions with our Let&apos;s Talk Sites. We also partner with
							one or more third parties (such as advertisers and ad networks) to display advertising on our
							Let&apos;s Talk Sites and/or to manage and serve advertising on other sites.
						</Typography>
						<Typography tag="p" weight="light">
							These third parties use cookies, web beacons, pixel tags and similar tracking technologies to collect and
							use certain information about your online activities, either on our Let&apos;s Talk Sites and/or other sites
							or mobile apps, to infer your interests and deliver you targeted advertisements that are more tailored
							to you based on your browsing activities and inferred interests (&quot;Interest Based Advertising&quot;). For
							more information about this practice, click here:
							{' '}
							<LocaleLink target="_blank" to="http://www.aboutads.info/choices/" rel="noreferrer">http://www.aboutads.info/choices/</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							Our third-party service providers may also use cookies or web beacons to collect information about your
							visits to our Let&apos;s Talk Sites and/or other sites to measure and track the effectiveness of advertisements
							and our online marketing activities (for example, by collecting data on how many times you click on one
							of our ads). The information collected by these third parties does not include personal information
							that enables you to be specifically identified (e.g. by reference to your name or email address).
						</Typography>
						<Typography tag="p" weight="light">
							<b>Facebook:</b>
							{' '}
							We also use the pixel function of Facebook Custom Audiences to identify users who have
							landed on certain pages for the purposes of target group-orientated remarketing and advertising.
							The Facebook pixel stores a cookie, which means that information about your visit to our Let&apos;s Talk Sites
							will be shared with Facebook. Also, as part of the &quot;advanced matching&quot; undertaken by Facebook, other
							personal information (such as telephone number, email address, Facebook ID) may be shared with Facebook
							in a protected (hashed) form. These cookies and the information collected are used to create target
							groups to help Facebook deliver advertising on our behalf to users who have previously visited our
							Let&apos;s Talk Sites when they are on Facebook or a digital platform powered by Facebook Advertising, and to
							track advertising success. You can modify your Facebook Ad settings to restrict these advertisements.
							Navigate to Settings/Adverts in your Facebook account to adjust your preferences. Learn more about the
							different types of Facebook cookies here:
							{' '}
							<LocaleLink target="_blank" to="https://www.facebook.com/policies/cookies/" rel="noreferrer">https://www.facebook.com/policies/cookies/</LocaleLink>
						</Typography>

						{/* How can I control cookies? */}
						<br />
						<Typography tag="h3" weight="bold">
							How can I control cookies?
						</Typography>
						<Typography tag="p" weight="light">
							You have the right to decide whether to accept or reject cookies.
						</Typography>
						<Typography tag="p" weight="light">
							<b>Website Cookie Preference Tool:</b>
							{' '}
							You can exercise preferences about what cookies are served on our
							Let&apos;s Talk Sites by visiting our cookie settings preference center, available at the
							{' '}
							<LocaleLink to="/cookie-statement#settings">bottom of this page</LocaleLink>
							. You can also exercise your Let&apos;s Talk Sites cookie preferences by visiting the TRUSTe preference center by clicking this link:
							{' '}
							<LocaleLink to="http://preferences-mgr.truste.com/" target="_blank" rel="noreferrer">http://preferences-mgr.truste.com/</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							<b>Browser Controls:</b>
							{' '}
							You can set or amend your web browser controls to accept or refuse cookies. If you
							choose to reject cookies, you may still use our Let&apos;s Talk Sites though your access to some
							functionality and areas of our Let&apos;s Talk Sites may be restricted. As the means by which you can refuse
							cookies through your web browser controls vary from browser-to-browser, you should visit your browser&apos;s
							help menu for more information.
						</Typography>
						<Typography tag="p" weight="light">
							<b>Disabling Most Interest Based Advertising:</b>
							{' '}
							Most advertising networks offer you a way to opt out of Interest Based Advertising. If you would like to find out more information, please visit
							{' '}
							<LocaleLink to="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">http://www.aboutads.info/choices/</LocaleLink>
							{' '}
							or
							{' '}
							<LocaleLink to="http://www.youronlinechoices.com" target="_blank" rel="noreferrer">http://www.youronlinechoices.com</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							<b>Mobile Advertising:</b>
							{' '}
							You can opt out of having your mobile advertising identifiers used for certain
							types of Interest Based Advertising, including those performed by us, by accessing the settings
							in your Apple or Android mobile device and following the most recent published instructions. If you
							opt out, we will remove all data about you and no further data collection or tracking will occur.
							The random ID we (or our third-party partners) had previously assigned to you will also be removed.
							This means that if at a later stage, you decide to opt-in, we will not be able to continue and track
							you using the same ID as before, and you will for all practical purposes be a new end user to our system.
						</Typography>
						<Typography tag="p" weight="light">
							<b>Do Not Track:</b>
							{' '}
							Some Internet browsers - like Internet Explorer, Firefox, and Safari - include the ability
							to transmit &quot;Do Not Track&quot; or &quot;DNT&quot; signals. Since uniform standards for &quot;DNT&quot; signals have not been
							adopted, our Let&apos;s Talk Sites, Connected Sites, Landing Pages, and Websites do not currently process
							or respond to &quot;DNT&quot; signals. Let&apos;s Talk takes privacy and meaningful choice seriously and will make
							efforts to continue to monitor developments around DNT browser technology and the implementation of
							a standard. To learn more about &quot;DNT&quot;, please visit
							{' '}
							<LocaleLink to="https://allaboutdnt.com/" target="_blank" rel="noreferrer">All About Do Not Track</LocaleLink>
							.
						</Typography>

						{/* How often will you update this Cookie Statement? */}
						<br />
						<Typography tag="h3" weight="bold">
							How often will you update this Cookie Statement?
						</Typography>
						<Typography tag="p" weight="light">
							We may update this Cookie Statement from time to time in order to reflect, for example, changes to
							the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit
							this Cookie Statement regularly to stay informed about our use of cookies and related technologies.
						</Typography>
						<Typography tag="p" weight="light">
							The date at the bottom of this Cookie Statement indicates when it was last updated.
						</Typography>

						{/* Where can I get further information? */}
						<br />
						<Typography tag="h3" weight="bold">
							Where can I get further information?
						</Typography>
						<Typography tag="p" weight="light">
							If you have any questions about our use of cookies or other technologies, please email us at
							{' '}
							<LocaleLink to="mailto:support@lets-talk-app.com?Subject=Support Question">support@lets-talk-app.com</LocaleLink>
							.
						</Typography>

						{/* Usability Blurb */}
						<br />
						<HighlightSection id="settings">
							<Typography tag="p" weight="light">
								We want to ensure you are comfortable with how your information is collected at Let&apos;s Talk. If you
								do decide to disable cookies below, you will still have access to the core functions of
								the Let&apos;s Talk platform, including creating trips and interacting with other users&apos; trips.
							</Typography>
						</HighlightSection>

						{/* Manage My Cookies */}
						<S.CookieSettingsContainer>
							<Typography tag="h3" weight="bold">
								Manage My Cookies
							</Typography>

							{/* Essential Website Cookies */}
							<S.CookiesSettingsRow>
								<div className="flexLeft">
									<Typography tag="h4" weight="bold">Essential Website Cookies</Typography>
									<Typography tag="p" weight="light">
										These cookies are strictly necessary to provide you with services available through our
										Let&apos;s Talk Sites and to use some of their features, such as access to secure areas.
									</Typography>
								</div>
								<div className="flexRight">
									<Checkbox
										value
										disabled
									/>
								</div>
							</S.CookiesSettingsRow>

							{/* Performance and Functionality Cookies */}
							<S.CookiesSettingsRow>
								<div className="flexLeft">
									<Typography tag="h4" weight="bold">Performance and Functionality Cookies</Typography>
									<Typography tag="p" weight="light">
										These cookies are used to enhance the performance and functionality of our Let&apos;s Talk Sites.
										They help us to know which pages are the most and least popular and to see how visitors move
										around the Let&apos;s Talk Sites.
									</Typography>
								</div>
								<div className="flexRight">
									<Checkbox
										name="performanceEnabled"
										value={performanceEnabled}
										onChange={handleOnChange}
									/>
								</div>
							</S.CookiesSettingsRow>

							{/* Analytics and Customization Cookies */}
							<S.CookiesSettingsRow>
								<div className="flexLeft">
									<Typography tag="h4" weight="bold">Analytics and Customization Cookies</Typography>
									<Typography tag="p" weight="light">
										These cookies collect information that is used either in aggregate form to help us understand
										how our Let&apos;s Talk Sites are being used.
									</Typography>
								</div>
								<div className="flexRight">
									<Checkbox
										name="analyticsEnabled"
										value={analyticsEnabled}
										onChange={handleOnChange}
									/>
								</div>
							</S.CookiesSettingsRow>

							{/* Advertising (Targeting) Cookies */}
							<S.CookiesSettingsRow>
								<div className="flexLeft">
									<Typography tag="h4" weight="bold">Advertising (Targeting) Cookies</Typography>
									<Typography tag="p" weight="light">
										These cookies are used to make advertising messages more relevant to you and your interests.
									</Typography>
								</div>
								<div className="flexRight">
									<Checkbox
										name="targetingEnabled"
										value={targetingEnabled}
										onChange={handleOnChange}
									/>
								</div>
							</S.CookiesSettingsRow>
						</S.CookieSettingsContainer>
					</S.Wrapper>
				</LegalNavigation>
			</ContentNavigation>
		</>
	);
};


/**
 * Configuration
 */

CookiePolicy.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape()
};
CookiePolicy.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default CookiePolicy;
