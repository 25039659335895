/*
 * File: ChildAboutAgeStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 12, 2024 at 9:20 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { updateUserProfile } from '../../../../services/user';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, Typography
} from '../../../../components';

// Constants
import { AGE_RANGE_OPTIONS, ROLES } from '../../../../../Constants';

// Styles
import * as S from './ChildAboutAgeStep.styles';


/**
 * Component
 */

export const ChildAboutAgeStep = forwardRef(({
	updateStep, className
}, ref) => {

	// Create state handlers
	const [isLoading, setIsLoading] = useState(false);
	const [selectedAgeRange, setSelectedAgeRange] = useState(null);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get action from hooks
	const dispatch = useDispatch();

	// Handle next function
	const handleNext = async () => {

		// Validate parameters
		if (!selectedAgeRange || validator.isEmpty(selectedAgeRange, { ignore_whitespace: true })) {
			toastError(uiMode, 'Please select your child\'s age range to continue');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserProfile({
			primaryChildAgeRange: selectedAgeRange
		}).then(async ({ data: { user: newUser } }) => {

			// Update user state
			dispatch(updateUser(newUser));

			// Update step
			updateStep(10);

			// Set loading state
			setIsLoading(false);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
			}
		});
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'user']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h3" weight="bold">Tell Us About Your Child</Typography>

				{/* Instructions */}
				<Typography tag="p" variation="1" weight="medium">What is their age?</Typography>

				{/* Inputs */}
				<S.SelectionContainer>
					{AGE_RANGE_OPTIONS[ROLES.CHILD].map((option) => (
						<S.AgeRangeOption
							key={option.value}
							className="animate"
							onClick={() => { setSelectedAgeRange(option.value); }}
							$isSelected={option.value === selectedAgeRange}
						>
							<Typography tag="p">{option.label}</Typography>
						</S.AgeRangeOption>
					))}
				</S.SelectionContainer>
				<S.IntentionWidget>
					<Typography tag="p" variation="2">This program is intended for kids in the 10-13 year old age range.</Typography>
				</S.IntentionWidget>

				{/* Next Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">Next</Button>

				{/* Back Button */}
				<Button
					disabled={isLoading}
					onClick={() => { updateStep(8); }}
					size="small"
					variant="text"
					style={{ margin: '10px 0px -5px' }}
				>
					<FontAwesomeIcon icon={['fas', 'arrow-left']} />
					<Typography variation="button-medium" weight="semibold">Go Back</Typography>
				</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

ChildAboutAgeStep.displayName = 'ChildAboutAgeStep';
ChildAboutAgeStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string
};
ChildAboutAgeStep.defaultProps = {
	className: null
};
