/*
 * File: NavigationTab.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 11, 2022 at 12:25 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:14 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { LocaleLink } from '../LocaleLink';
import { Typography } from '../Typography';

// Constants
import { AppNavTopbarHeight } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	height: ${AppNavTopbarHeight}px !important;
	cursor: pointer;
	padding: 0px 5px;
	margin: 0px 6px;
	text-align: center;
	align-items: center;
	${({ isActive, showBorder }) => (showBorder === true && isActive === true ? css`
		border-bottom: 3px solid ${({ theme }) => theme.brandPrimaryBase} !important;
 	` : css`
	 	border-bottom: 3px solid transparent;
 	`)}

	svg {
	 	position: relative;
	 	top: 3px;
		font-size: 1.45rem;
	 	${({ isActive }) => ((isActive === true) ? css`
		 	color: ${({ theme }) => theme.brandPrimaryBase};
	 	` : css`
		 	color: ${({ theme }) => theme.inactiveElement};
	 	`)}
 	}

 	${({ isActive }) => (isActive !== true && css`
	 	&:hover {
		 	opacity: 0.7;
	 	}
 	`)};
`;

export const Link = styled(LocaleLink)`
	height: ${AppNavTopbarHeight}px !important;
	text-decoration: none;
	${({ props }) => ((props.isActive === true) ? css`
		color: ${({ theme }) => theme.brandPrimaryBase};
	` : css`
		color: ${({ theme }) => theme.inactiveElement};
	`)}
`;

export const Label = styled(Typography)`
	margin: 7px 0px 3px;
	margin-left: -100%;
    margin-right: -100%;
`;
