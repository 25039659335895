/*
 * File: CompletionStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 8, 2024 at 2:45 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	forwardRef, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Howl } from 'howler';

// Components
import {
	Button, Card, ConfettiBurst, Typography,
	Video
} from '../../../../components';

// Styles
import * as S from './CompletionStep.styles';


/**
 * Component
 */

export const CompletionStep = forwardRef(({
	className, isVisible, updateHeight, authenticationParams
}, ref) => {

	// Create state handlers
	const [fireConfetti, setFireConfetti] = useState(false);

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Create sound hooks
	const fanfareSound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/fanfare.mp3`],
		html5: true,
		volume: 0.1
	}), []);

	// Get action from hooks
	const navigate = useNavigate();

	// Handle next function
	const handleComplete = async () => {

		// Move back to dashboard
		navigate(`${stateLocale.localePath}/dashboard`);

	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible === true) {

			// Update height
			updateHeight(true);

			// Fire confetti
			setTimeout(() => {
				setFireConfetti(true);
			}, 500);

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 4000);
		}
	}, [isVisible]);

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<>
			<S.StepContainer ref={ref} className={className}>

				{/* Card */}
				<Card className="cardPadding">

					{/* Icon */}
					<S.IconContainer>
						<FontAwesomeIcon icon={['fal', 'star']} />
						<S.ConfettiTarget ref={confettiTargetRef} />
					</S.IconContainer>

					{/* Title */}
					<Typography tag="h3" weight="bold">Keep going!</Typography>

					{/* Instructions */}
					<Typography tag="p" variation="1" weight="medium">
						Your child is all set up and can now log in to the Let&apos;s Talk app. Follow these instructions to help your child log in for the first time:
					</Typography>

					{/* Copyable Instructions */}
					<S.InstructionsContainer>
						<ol>
							<li>
								Log out of your parent account by clicking on your avatar in the top right corner of your screen or clicking the &quot;Let your child login now&quot; button below.
							</li>
							<li>
								Go to the Log In page (
								{process.env.APP_URL.replace('https://', '').replace('http://', '')}
								/login)
							</li>
							<li>
								Enter your child&apos;s new account credentials:
								<br />
								<br />
								<strong>Username: </strong>
								{' '}
								{authenticationParams.username}
								<br />
								<strong>Password: </strong>
								{' '}
								{authenticationParams.password}
							</li>
						</ol>
					</S.InstructionsContainer>

					{/* Instructions */}
					<Typography tag="p" variation="2" weight="medium" style={{ marginBottom: '20px' }}>
						Here is a short video you can show your child to explain Let’s Talk.
					</Typography>

					{/* Video Container */}
					<S.VideoContainer>
						<Video url={`${process.env.CDN_URL}/public/assets/video/lets-talk-intro-video.mp4`} className="video" />
					</S.VideoContainer>

					{/* Action Container */}
					<S.ActionContainer>

						{/* Now Button */}
						<Button onClick={() => { window.location = '/logout'; }} size="large" variant="outline">Let your child log in now</Button>

						{/* Later Button */}
						<Button onClick={handleComplete} size="large">Log in later</Button>

					</S.ActionContainer>

				</Card>
			</S.StepContainer>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
					audioHook={fanfareSound}
				/>
			)}
		</>
	);
});


/**
 * Configuration
 */

CompletionStep.displayName = 'CompletionStep';
CompletionStep.propTypes = {
	className: PropTypes.string,
	isVisible: PropTypes.bool,
	authenticationParams: PropTypes.shape(),
	updateHeight: PropTypes.func
};
CompletionStep.defaultProps = {
	className: null,
	isVisible: false,
	authenticationParams: null,
	updateHeight: null
};
