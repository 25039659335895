/*
 * File: AppNavigation.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:29 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:46 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useState
} from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'beautiful-react-hooks';

// Components
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { Bottombar } from './Bottombar';

// Constants
import { mobileBreakpoint } from '../../styles/constants';

// Styles
import * as S from './AppNavigation.styles';


/**
 * Component
 */

export const AppNavigation = ({
	children, hideSidebar
}) => {

	// Set state
	const [desktopSidebarOpen, setDesktopSidebarOpen] = useState(true);
	const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

	// Check if mobile screen size
	const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}em)`);

	// Toggle sidebar visibility function
	const toggleSidebar = () => {
		if (isMobile) {
			setMobileSidebarOpen(!mobileSidebarOpen);
		} else {
			setDesktopSidebarOpen(!desktopSidebarOpen);
		}
	};

	// Render component
	return (
		<S.Navigation>

			{/* Desktop Sidebar */}
			{!hideSidebar && (
				<S.DesktopSidebar
					isOverlay={false}
					className="isNotMobile animate"
					$isOpen={desktopSidebarOpen}
				/>
			)}

			{/* Application Window */}
			<S.AppWindow $isSidebarOpen={hideSidebar ? false : desktopSidebarOpen} className="animate">

				{/* Top Bar */}
				<Topbar toggleSidebar={toggleSidebar} hideSidebar={hideSidebar} />

				{/* Mobile Navigation */}
				{!hideSidebar && (
					<S.NavigationOverlay isOpen={mobileSidebarOpen} className="isMobile">
						<Sidebar
							isOverlay
							className="mobileSidebar"
							toggleOverlay={toggleSidebar}
							isOpen
						/>
						<S.Overlay isOpen={mobileSidebarOpen} onClick={toggleSidebar} />
					</S.NavigationOverlay>
				)}

				{/* Content */}
				<S.Wrapper>
					<S.Content id="scroller">

						{/* Content */}
						<S.ContentPadding>
							{children}
						</S.ContentPadding>

					</S.Content>
				</S.Wrapper>

				{/* Mobile Bottom Bar */}
				<Bottombar className="isMobile" />

			</S.AppWindow>

		</S.Navigation>
	);
};


/**
 * Configuration
 */

AppNavigation.displayName = 'AppNavigation';
AppNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	hideSidebar: PropTypes.bool
};
AppNavigation.defaultProps = {
	children: null,
	hideSidebar: false
};
