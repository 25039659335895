/*
 * File: StepExplanationWidget.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 10:03 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import {
	render, MARK_LINK, NODE_EMOJI, NODE_PARAGRAPH
} from 'storyblok-rich-text-react-renderer';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../Modal';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { LocaleLink } from '../LocaleLink';
import { Emoji } from '../Emoji';
import { Video } from '../Video';

// Constants
import { ACTIVITY_MODULE_STATUSES, ROLES } from '../../../Constants';

// Styles
import * as S from './StepExplanationWidget.styles';


/**
 * Constants
 */

const STONE_SIZE = 0.19;


/**
 * Configuration
 */

storyblokInit({
	accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
	apiOptions: {
		region: 'us'
	},
	use: [apiPlugin]
});

const richTextConfiguration = {
	markResolvers: {
		[MARK_LINK]: (children, { href, ...rest }) => <LocaleLink {...rest} to={href} target="_blank">{children}</LocaleLink>
	},
	nodeResolvers: {
		[NODE_EMOJI]: (children, { name, emoji }) => <Emoji label={name} symbol={emoji} size={1.2} />,
		[NODE_PARAGRAPH]: (children) => <Typography tag="p">{children}</Typography>
	},
	blokResolvers: {
		video: ({ /* eslint-disable-line react/prop-types */ video }) => {
			const { filename } = video;
			return (
				<Video url={filename} />
			);
		}
	}
};


/**
 * Component
 */

export const StepExplanationWidget = ({
	className, activityModule, isOpen, handleClose, modulePosition, transformX, transformY, transformScale, layerWidth
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Use actions from hooks
	const navigate = useNavigate();

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current active step
	const activeStep = useMemo(() => {
		let step = null;
		if (activityModule) {
			user.journey.forEach(({ modules: modulesArray }) => {
				modulesArray.forEach((moduleObj) => {
					if (moduleObj.module === activityModule.id) {
						step = moduleObj;
					}
				});
			});
		}
		return step;
	}, [user, activityModule]);

	// Get child account created
	const createChildAccount = useMemo(() => {
		if (activityModule) {
			return user?.role?.primary === ROLES.PARENT && activityModule.childAccountRequired && !user.childAccountSetupComplete;
		}
		return true;
	}, [user, activityModule]);

	// Handle start activity
	const startActivity = () => {
		navigate(`${stateLocale.localePath}/activity/${activityModule.slug}`);
	};

	// Handle child account creation
	const handleAccountCreation = () => {
		navigate(`${stateLocale.localePath}/dashboard/child-account/${user.children[0].id}`);
	};

	// Handle render active button
	const renderActionButton = () => {
		switch (activeStep?.status) {
			case ACTIVITY_MODULE_STATUSES.LOCKED:
				return (
					<Button disabled>
						<FontAwesomeIcon icon={['fal', 'lock-keyhole']} />
						<Typography variation="button-medium" weight="semibold">Locked</Typography>
					</Button>
				);
			case ACTIVITY_MODULE_STATUSES.NOT_STARTED:
				return (
					<Button onClick={startActivity}>
						<Typography variation="button-medium" weight="semibold">Start</Typography>
					</Button>
				);
			case ACTIVITY_MODULE_STATUSES.PENDING_START:
				return (
					<Button onClick={startActivity}>
						<Typography variation="button-medium" weight="semibold">Start</Typography>
					</Button>
				);
			case ACTIVITY_MODULE_STATUSES.IN_PROGRESS:
				return (
					<Button onClick={startActivity}>
						<Typography variation="button-medium" weight="semibold">Continue</Typography>
					</Button>
				);
			case ACTIVITY_MODULE_STATUSES.COMPLETE:
				return (
					<Button onClick={startActivity}>
						<Typography variation="button-medium" weight="semibold">Start Over</Typography>
					</Button>
				);
			default:
				return null;
		}
	};

	// Get activity intro
	const activityIntro = useMemo(() => {

		// Get content
		const content = activityModule?.content || [];

		// Return activity intro
		return content.find((contentObj) => contentObj.content.component === 'activity_intro');

	}, [activityModule]);

	// Get intro content structure
	const isColumnLayout = useMemo(() => activityIntro?.content?.image?.filename, [activityIntro]);

	// Define popover width
	const popoverWidth = useMemo(() => (isColumnLayout && !createChildAccount ? 420 : 300), [isColumnLayout, createChildAccount]);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render explanation content
	const renderExplanationContent = () => (createChildAccount ? (
		<S.InnerContent>

			{/* Title */}
			<S.TitleContainer>
				<FontAwesomeIcon icon={['fal', 'unlock']} style={{ marginBottom: '12px' }} />
				<Typography tag="h5" weight="semibold">Create Child Account</Typography>
			</S.TitleContainer>

			{/* Content */}
			<S.ContentContainer className="paragraphContent">
				<Typography tag="p" variation="2">
					It’s time for your child to join the fun! Create a child login for them to have access to the platform.
				</Typography>
			</S.ContentContainer>

			{/* Actions */}
			<S.ActionContainer>
				<Button onClick={handleAccountCreation}>
					<Typography variation="button-medium" weight="semibold">Create login</Typography>
				</Button>
			</S.ActionContainer>

		</S.InnerContent>
	) : (
		<S.InnerContent>

			{/* Title */}
			<S.TitleContainer>
				<Typography tag="h5" weight="semibold">{activityModule?.name}</Typography>
			</S.TitleContainer>

			{/* Column Container */}
			<S.ColumnContainer>

				{/* Image */}
				{isColumnLayout && !createChildAccount && <S.Image $image={activityIntro?.content?.image?.filename} />}

				{/* Content */}
				{activityIntro?.content && (
					<S.ContentContainer className="paragraphContent" $isColumnLayout={isColumnLayout && !createChildAccount}>
						{render(activityIntro?.content?.content, richTextConfiguration)}
					</S.ContentContainer>
				)}

			</S.ColumnContainer>

			{/* Time Estimate */}
			{activityModule.timeToComplete && (
				<S.TimeEstimateContainer>
					<Typography tag="p" variation="2" weight="semibold">Estimated time to complete</Typography>
					<Typography tag="p" variation="2">{activityModule.timeToComplete}</Typography>
				</S.TimeEstimateContainer>
			)}

			{/* Action In Step */}
			{/* activityIntro?.content?.actions_in_step && activityIntro?.content?.actions_in_step.length > 0 && (
				<S.ActionsContainer>
					<Typography tag="p" variation="2" weight="semibold">Actions in Step</Typography>
					<S.ActionsInStep>
						{activityIntro?.content?.actions_in_step.includes('video') && <FontAwesomeIcon icon={['fal', 'video']} />}
						{activityIntro?.content?.actions_in_step.includes('infographic') && <FontAwesomeIcon icon={['fal', 'image']} />}
						{activityIntro?.content?.actions_in_step.includes('image') && <FontAwesomeIcon icon={['fal', 'image']} />}
						{activityIntro?.content?.actions_in_step.includes('quiz') && <FontAwesomeIcon icon={['fal', 'square-list']} />}
					</S.ActionsInStep>
				</S.ActionsContainer>
			) */}

			{/* Actions */}
			<S.ActionContainer>{renderActionButton()}</S.ActionContainer>

		</S.InnerContent>
	));

	// Calculate left position
	const leftPosition = useMemo(() => {
		let left = modulePosition.left * layerWidth * transformScale;
		left += transformX;
		return left;
	}, [modulePosition, layerWidth, transformScale, transformX]);

	// Calculate left offset
	const leftOffset = useMemo(() => {
		const maxWidth = layerWidth - 250;
		const rightMargin = leftPosition + popoverWidth;
		return rightMargin < maxWidth ? 0 : (maxWidth - rightMargin);
	}, [layerWidth, leftPosition, popoverWidth]);

	// Calculate top position
	const topPosition = useMemo(() => (modulePosition.top * layerWidth * transformScale) + transformY + (STONE_SIZE * layerWidth * transformScale), [modulePosition, layerWidth, transformScale, transformY]);

	// Render component
	return (
		<>

			{/* Popover */}
			{isOpen && (
				<S.PopoverContainer className={className ? `${className} isNotMobile` : 'isNotMobile'}>
					<S.ActionOverlay onClick={() => { handleClose(); }} />
					<S.Popover style={{
						left: `${leftPosition + leftOffset}px`,
						top: `${topPosition}px`,
						width: `${popoverWidth}px`
					}}
					>
						<S.Arrow
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 8"
							width="2em"
							height="2em"
							verticalPropKey="top"
							uiMode={uiMode?.mode}
							style={{ left: `${((STONE_SIZE * layerWidth * transformScale) / 2.0) - leftOffset}px` }}
						>
							<path d="M0 8c7 0 10-8 12-8s5 8 12 8z" />
						</S.Arrow>
						{renderExplanationContent()}
					</S.Popover>
				</S.PopoverContainer>
			)}

			{/* Modal */}
			<S.ModalContainer className="isMobile">
				<Modal className={className ? `${className} adjustedModal` : 'adjustedModal'} variant="medium" isOpen={isOpen} handleClose={handleClose} clickOutsideClose useWrapper showClose>
					<S.ModalInner>
						{renderExplanationContent()}
					</S.ModalInner>
				</Modal>
			</S.ModalContainer>

		</>
	);
};


/**
 * Configuration
 */

StepExplanationWidget.displayName = 'StepExplanationWidget';
StepExplanationWidget.propTypes = {
	className: PropTypes.string,
	activityModule: PropTypes.shape(),
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	modulePosition: PropTypes.shape(),
	transformX: PropTypes.number,
	transformY: PropTypes.number,
	transformScale: PropTypes.number,
	layerWidth: PropTypes.number
};
StepExplanationWidget.defaultProps = {
	className: null,
	activityModule: null,
	isOpen: false,
	handleClose: null,
	modulePosition: {},
	transformX: 0,
	transformY: 0,
	transformScale: 1,
	layerWidth: 500
};
