/*
 * File: Bottombar.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 11:25 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 30, 2024 at 12:16 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useState, useRef
} from 'react';
import PropTypes from 'prop-types';

// Components
import { Padding } from '../../Padding';
import { NavigationTab } from '../../NavigationTab';

// Styles
import * as S from './Bottombar.styles';


/**
 * Component
 */

export const Bottombar = ({ className }) => {

	// Set state
	const [showBar, setShowBar] = useState(true);
	const [barHeight, setBarHeight] = useState(0);

	// Create references for elements
	const outerRef = useRef();
	const isMounted = useRef(true);

	// Handle actions on component load
	useEffect(() => {

		// Initialize temporary state
		isMounted.current = true;
		let scrollTopGoal = 0;
		let lastScrollTop = 0;

		// Attach scroll listener
		if (isMounted.current) {
			document.addEventListener('scroll', () => {
				if (isMounted.current) {

					// Update bottom bar position
					const st = document.scrollingElement.scrollTop;
					const diff = st - scrollTopGoal;

					if (st < lastScrollTop) {
						scrollTopGoal = st <= 0 ? 0 : st;
						setShowBar(true);
					} else if (diff > 80) {
						setShowBar(false);
					}
					lastScrollTop = st <= 0 ? 0 : st;
				}
			}, true);
		}

		// Set bar height
		setBarHeight(outerRef.current.clientHeight);

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<S.Bottombar ref={outerRef} showBar={showBar} barHeight={barHeight} className={className}>
			<Padding left right>
				<S.TabContainer>
					<NavigationTab to="/dashboard" icon={['fal', 'home-lg-alt']} activeIcon={['fas', 'home-lg-alt']} showBorder={false} />
				</S.TabContainer>
				<S.TabContainer>
					<NavigationTab to="/dashboard/resources" icon={['fal', 'books']} activeIcon={['fas', 'books']} showBorder={false} />
				</S.TabContainer>
				<S.TabContainer>
					<NavigationTab to="/dashboard/answers" icon={['fal', 'question-circle']} activeIcon={['fas', 'question-circle']} showBorder={false} />
				</S.TabContainer>
				<S.TabContainer>
					<NavigationTab to="/dashboard/rewards" icon={['fal', 'treasure-chest']} activeIcon={['fas', 'treasure-chest']} showBorder={false} />
				</S.TabContainer>
				<S.TabContainer>
					<NavigationTab to="/dashboard/journal" icon={['fal', 'book']} activeIcon={['fas', 'book']} showBorder={false} />
				</S.TabContainer>
			</Padding>
		</S.Bottombar>
	);
};


/**
 * Configuration
 */

Bottombar.displayName = 'Bottombar';
Bottombar.propTypes = {
	className: PropTypes.string
};
Bottombar.defaultProps = {
	className: null
};
