/*
 * File: position.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on June 2, 2022 at 12:48 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 4:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const scrollPageTo = (x, y, options = {}) => {
	if (typeof window !== 'undefined') {
		window.scroll({ top: y, left: x, ...options });
	}
};
