/*
 * File: ResetPassword.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 8, 2024 at 1:53 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { renderLogo } from '../../../utilities/image';

// Services
import { validatePasswordResetAttempt, resetPassword } from '../../../services/password';

// Components
import {
	Meta, Spinner, ErrorComponent, Typography, PasswordInput, TextInput,
	Card, UIModeWidget
} from '../../../components';

// Styles
import * as S from './ResetPassword.styles';


/**
 * Component
 */

const ResetPassword = ({ meta, locale }) => {

	// Get component parameters
	const { aToken, bToken } = useParams();

	// Create state handlers
	const [componentData, setComponentData] = useState({});
	const [errorData, setErrorData] = useState(null);
	const [pageStatus, setPageStatus] = useState('idle');
	const [passwordError, setPasswordError] = useState(null);
	const [passwordRulesError, setPasswordRulesError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Create references for components
	const isMounted = useRef(true);

	// Get actions from hooks
	const navigate = useNavigate();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Initialize component data function
	const validateResetAttempt = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch data
			const { data } = await validatePasswordResetAttempt({ aToken, bToken });

			// Ensure component is mounted
			if (isMounted.current) {

				// Set new data state
				setComponentData(data);

				// Update page status
				setPageStatus('success');
			}
		} catch ({ response }) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Set error message
				setErrorData(response?.data);

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle reset user password function
	const resetUserPassword = async () => {

		// Get parameters
		const {
			password, confirmPassword
		} = inputValues;

		// Validate parameters
		if (!password || validator.isEmpty(password, { ignore_whitespace: true }) || passwordError || passwordRulesError) {
			setPasswordError({ message: passwordError?.message ? passwordError?.message : 'Please create a password for your Let\'s Talk account' });
			return;
		}
		if (!confirmPassword || validator.isEmpty(confirmPassword, { ignore_whitespace: true }) || password !== confirmPassword) {
			setConfirmPasswordError({ message: 'Your passwords must match' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Reset user's password
		resetPassword({
			aToken, bToken, pass: password
		}).then(() => {

			// Show success toast
			toastSuccess(uiMode, 'Your password has successfully been updated! We\'ll direct you back to log in.', { duration: 3000 });

			// Clear inputs
			setInputValues({});

			// Handle completion actions
			setTimeout(() => {
				navigate(`${stateLocale.localePath}/login`);
			}, 3000);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setPasswordError(null);
			setConfirmPasswordError(null);

			// Clear inputs
			setInputValues({});

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble updating your password. Give it another go.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Validate reset password attempt
		validateResetAttempt();

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component function
	const renderContent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return (
				<Spinner
					showMeta
					meta={meta}
				/>
			);
		} if (pageStatus === 'error') {

			// Create title content
			let title = 'Your reset link has already been used or is no longer valid.';
			if (errorData?.error?.code === 402) {
				title = 'Your password reset link has expired.';
			}

			// Create description content
			const description = (
				<>
					To reset your password, follow the link below or contact support for more information.
				</>
			);

			// Return component
			return (
				<ErrorComponent
					locale={stateLocale}
					title={title}
					description={description}
					hideAnimation
					message=""
					buttons={[
						{
							link: '/forgot',
							size: 'large',
							text: 'Forgot Password'
						}
					]}
				/>
			);
		}
		return (
			<>
				{/* Content */}
				<Typography tag="h2" weight="bold">
					Hi,
					{' '}
					{componentData?.firstName || 'there'}
					.
				</Typography>
				<Typography tag="p" variation="1" weight="regular">
					Create a new password below for your Let&apos;s Talk account.
				</Typography>

				{/* Inputs */}
				<PasswordInput
					label="Create a new password"
					onChange={handleOnChange}
					setError={setPasswordError}
					setRulesError={setPasswordRulesError}
					rulesError={passwordRulesError}
					autoComplete="new-password"
					containerClassName="inputSpacer"
					value={inputValues.password || ''}
					disallowedContent={[
						componentData?.email
					].filter(Boolean)}
					error={passwordError}
				/>
				<TextInput
					label="Confirm your password"
					type="password"
					name="confirmPassword"
					error={confirmPasswordError}
					value={inputValues.confirmPassword || ''}
					containerClassName="inputSpacer"
					autoComplete="new-password"
					onFocus={() => { setConfirmPasswordError(null); }}
					onKeyUp={() => { setConfirmPasswordError(null); }}
					onBlur={() => { setConfirmPasswordError(null); }}
					onChange={handleOnChange}
				/>

				{/* Update Button */}
				<S.ActionButton
					disabled={isLoading}
					isLoading={isLoading}
					onClick={resetUserPassword}
					size="large"
				>
					Update Password
				</S.ActionButton>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper>

				{/* Logo */}
				<S.Logo src={renderLogo(uiMode)} title="Let's Talk Logo" alt="Let's Talk Logo" />

				{/* Form Container */}
				<S.FormContainer left right>
					<S.Form $maxWidth={pageStatus === 'error' ? 1100 : 600}>
						<Card className="cardPadding">
							{renderContent()}
						</Card>
					</S.Form>
				</S.FormContainer>

				{/* UI Mode Widget */}
				<UIModeWidget className="uiModeWidget" />

				{/* Decoration */}
				<S.Decoration />

			</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

ResetPassword.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
ResetPassword.defaultProps = {
	meta: {},
	locale: {},
};


/**
 * Exports
 */

export default ResetPassword;
