/*
 * File: CopyrightFooter.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:27 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { useSelector } from 'react-redux';

// Utilities
import { renderLogo } from '../../utilities/image';

// Components
import { Typography } from '../Typography';
import { Padding } from '../Padding';
import { LocaleLink } from '../LocaleLink';

// Styles
import * as S from './CopyrightFooter.styles';


/**
 * Component
 */

export const CopyrightFooter = () => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Render component
	return (
		<Padding left right>
			<S.CopyrightFooter>
				<LocaleLink to="/">
					<S.LogoMark alt="Let's Talk Logo" title="Let's Talk Logo" src={renderLogo(uiMode)} />
				</LocaleLink>
				<Typography tag="span">{`© ${new Date().getFullYear()} Let's Talk. All rights reserved.`}</Typography>
			</S.CopyrightFooter>
		</Padding>
	);
};


/**
 * Configuration
 */

CopyrightFooter.displayName = 'CopyrightFooter';
CopyrightFooter.propTypes = {};
CopyrightFooter.defaultProps = {};
