/*
 * File: Checkbox.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 24, 2022 at 9:59 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import * as S from './Checkbox.styles';


/**
 * Component
 */

export const Checkbox = forwardRef(({
	containerClassName, value, onChange, ...rest
}, ref) => (
	<S.Wrapper className={containerClassName}>
		<S.CheckboxWrapper>
			<S.Checkbox ref={ref} checked={value} onClick={onChange} onChange={() => {}} {...rest} />
			<FontAwesomeIcon className="checkmark" icon={['far', 'check']} />
		</S.CheckboxWrapper>
	</S.Wrapper>
));


/**
 * Configuration
 */

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
	value: PropTypes.bool,
	onChange: PropTypes.func,
	containerClassName: PropTypes.string
};
Checkbox.defaultProps = {
	value: false,
	onChange: null,
	containerClassName: null
};
