/*
 * File: Constants.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 16, 2021 at 9:11 PM
 * Copyright © 2021 Let's Talk. All rights reserved.
 *
 * Last Modified: September 30, 2024 at 11:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Roles
 */

export const ROLES = {
	ALL: 'all',
	CHILD: 'child',
	PARENT: 'parent',
	ADMIN: 'admin'
};


/**
 * Background Jobs
 */

export const BACKGROUND_JOBS = {
	HANDLE_REVOKABLE_SESSIONS: 'handle_revokable_sessions',
	PROCESS_PROFILE_IMAGE_VARIANTS: 'process_profile_image_variants',
};


/**
 * Available Locales
 */

export const AVAILABLE_LOCALES = ['en'];
export const DEFAULT_LOCALE = 'en';
export const AVAILABLE_I18N_NAMESPACES = {
	TRANSLATIONS: 'translations'
};


/**
 * UI Mode Options
 */

export const UI_MODE_OPTIONS = {
	LIGHT: 'light',
	DARK: 'dark'
};


/**
 * General
 */

export const DEFAULT_TIMEZONE = 'America/New_York';
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.{5,})/; // Enforce password with at least 5 char with at least 1 digit
export const STRONG_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/; // Enforce password with at least 8 char with at least 1 lower case, 1 upper case and 1 digit
export const USERNAME_LENGTH_LIMIT = 24;
export const MAX_QUERY_LIMIT = 1000;


/**
 * Placeholders
 */

export const PLACEHOLDER_USER_PROFILE_IMAGE_BASE = `${process.env.CDN_URL}/public/assets/profile/user-profile-image-904239848230`;


/**
 * Cookies
 */

export const SESSION_TOKEN_COOKIE = 'e_s_token';
export const LOCALE_COOKIE = 'pl_locale';
export const UI_MODE_COOKIE = 'ui_mode';
export const VIEW_MODE_COOKIE = 's_ui_view_mode';
export const CONSENT_ACTION_TAKEN_COOKIE = 'c_action_taken';
export const CONSENT_PERFORMANCE_ENABLED_COOKIE = 'c_performance_enabled';
export const CONSENT_ANALYTICS_ENABLED_COOKIE = 'c_analytics_enabled';
export const CONSENT_TARGETING_ENABLED_COOKIE = 'c_targeting_enabled';


/**
 * Cookie Domain
 */

export const COOKIE_DOMAIN = () => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return 'qa.lets-talk-app.com';
		case 'staging':
			return 'staging.lets-talk-app.com';
		case 'production':
			return 'lets-talk-app.com';
		default:
			return null;
	}
};


/**
 * Headers
 */

export const CLIENT_PLATFORM_HEADER = 'x-lets-talk-client-platform';
export const CLIENT_VERSION_HEADER = 'x-lets-talk-client-version';
export const SESSION_TOKEN_HEADER = 'x-lets-talk-e-s-token';
export const JOB_SECRET_HEADER = 'x-lets-talk-job-secret';


/**
 * Expirations
 */

export const SESSION_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const CONSENT_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const LOCALE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const UI_MODE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const RESET_REQUEST_EXPIRATION = 12 * 60 * 60 * 1000; // 12 hours
export const USER_INVITE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year


/**
 * Account Lockout Policy
 */

export const LOCKOUT_DURATION = 5; // Duration policy setting determines the number of minutes that a locked-out account remains locked out before automatically becoming unlocked.
export const LOCKOUT_THRESHOLD = 3; // Threshold policy setting determines the number of failed sign-in attempts that will cause a user account to be locked.


/**
 * File Upload Limits (Bytes)
 */

export const FILE_UPLOAD_LIMITS = {
	PROFILE_IMAGE: {
		min: 1000, // 1 KB
		max: 10000000, // 10 MB
	}
};


/**
 * Acceptable File Types
 */

export const ACCEPTABLE_FILE_TYPES = {
	PROFILE_IMAGE: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	}
};


/**
 * Admin Emails
 */

export const ADMIN_EMAILS = [
	'sabrina@teenhealthresearch.com',
	'lwidman@teenhealthresearch.com'
];


/**
 * Core Domain Whitelists
 */

export const CORE_DOMAIN_WHITELIST = () => [
	'https://lets-talk-web-*.herokuapp.com',
	'https://*.lets-talk-app.com',
	process.env.ENV === 'development' && [
		'http://localhost:*'
	]
].filter(Boolean);

export const API_DOMAIN_WHITELIST = () => [
	'api.lets-talk-app.com',
	'*.api.lets-talk-app.com',
	process.env.ENV === 'development' && [
		'localhost:*'
	]
].filter(Boolean);


/**
 * Alert Types
 */

export const ALERT_STYLES = {
	ERROR: 'error',
	INFO: 'info'
};


/**
 * Error Messages
 */

export const GENERAL_ERROR_MESSAGE = 'Something went wrong, try again later!';


/**
 * Email Templates
 */

export const EMAIL_TEMPLATES = {
	PASSWORD_RESET_EMAIL: 'passwordResetEmail',
	PARENT_WELCOME_EMAIL: 'parentWelcomeEmail'
};


/**
 * Reason for Using Options
 */

export const REASON_FOR_USING_OPTIONS = [
	{
		reason: 'Make conversations about puberty, bodies, and sex less embarrassing and cringey',
		explanation: 'Good news! This app will help you and your child talk with less embarrassment and cringe.'
	},
	{
		reason: 'Create a safe space for my child to learn and ask questions',
		explanation: 'Good news! This app offers a safe space for your child to learn and ask questions.'
	},
	{
		reason: 'Help me know what to say and when',
		explanation: 'Good news! This app will give you a guide for what to say and when.'
	},
	{
		reason: 'Something else',
		explanation: 'We’re glad you are here!'
	}
];


/**
 * Avatar Options
 */

export const AVATAR_OPTIONS = {
	[ROLES.PARENT]: [
		{
			id: 'parent_1',
			image: `${process.env.CDN_URL}/public/assets/avatars/parent-1.png`
		},
		{
			id: 'parent_2',
			image: `${process.env.CDN_URL}/public/assets/avatars/parent-2.png`
		},
		{
			id: 'parent_3',
			image: `${process.env.CDN_URL}/public/assets/avatars/parent-3.png`
		},
		{
			id: 'parent_4',
			image: `${process.env.CDN_URL}/public/assets/avatars/parent-4.png`
		},
		{
			id: 'parent_5',
			image: `${process.env.CDN_URL}/public/assets/avatars/parent-5.png`
		},
		{
			id: 'parent_6',
			image: `${process.env.CDN_URL}/public/assets/avatars/parent-6.png`
		}
	],
	[ROLES.CHILD]: [
		{
			id: 'child_1',
			image: `${process.env.CDN_URL}/public/assets/avatars/child-1.png`
		},
		{
			id: 'child_2',
			image: `${process.env.CDN_URL}/public/assets/avatars/child-2.png`
		},
		{
			id: 'child_3',
			image: `${process.env.CDN_URL}/public/assets/avatars/child-3.png`
		},
		{
			id: 'child_4',
			image: `${process.env.CDN_URL}/public/assets/avatars/child-4.png`
		},
		{
			id: 'child_5',
			image: `${process.env.CDN_URL}/public/assets/avatars/child-5.png`
		},
		{
			id: 'child_6',
			image: `${process.env.CDN_URL}/public/assets/avatars/child-6.png`
		}
	]
};


/**
 * Gender Options
 */

export const GENDER_OPTIONS = {
	[ROLES.PARENT]: [
		{
			value: 'Woman',
			label: 'Woman'
		},
		{
			value: 'Man',
			label: 'Man'
		},
		{
			value: 'Trans / Non-Binary',
			label: 'Trans / Non-Binary'
		},
		{
			value: 'Prefer Not to Say',
			label: 'Prefer Not to Say'
		},
	],
	[ROLES.CHILD]: [
		{
			value: 'Girl',
			label: 'Girl'
		},
		{
			value: 'Boy',
			label: 'Boy'
		},
		{
			value: 'Trans / Non-Binary',
			label: 'Trans / Non-Binary'
		},
		{
			value: 'Prefer Not to Say',
			label: 'Prefer Not to Say'
		},
	]
};


/**
 * Age Range Options
 */

export const AGE_RANGE_OPTIONS = {
	[ROLES.PARENT]: [],
	[ROLES.CHILD]: [
		{
			value: '9 or Younger',
			label: '9 or Younger'
		},
		{
			value: '10',
			label: '10'
		},
		{
			value: '11',
			label: '11'
		},
		{
			value: '12',
			label: '12'
		},
		{
			value: '13',
			label: '13'
		},
		{
			value: '14 or Older',
			label: '14 or Older'
		}
	]
};


/**
 * Activity Module Statuses
 */

export const ACTIVITY_MODULE_STATUSES = {
	LOCKED: 'locked',
	NOT_STARTED: 'not_started',
	PENDING_START: 'pending_start',
	IN_PROGRESS: 'in_progress',
	COMPLETE: 'complete'
};


/**
 * Analytics Actions
 */

export const ANALYTICS_ACTIONS = {
	PAGE_VISITED: 'page_visited'
};
