/*
 * File: Toggle.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import 'react-toggle/style.css';


/**
 * Styles
 */

export const Wrapper = styled.div`
	.react-toggle-track {
		background-color: ${({ theme }) => theme.layoutBorder};
	}
	.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
		background-color: ${({ theme }) => theme.layoutBorder};
	}
	.react-toggle--checked .react-toggle-track {
		background-color: ${({ theme }) => theme.brandPrimaryBase};
	}
	.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
		background-color: ${({ theme }) => theme.brandPrimaryBase};
	}
	.react-toggle-thumb {
		background-color: ${({ theme }) => theme.cardBackground};
		border-color: ${({ theme }) => theme.layoutBorder};
	}
	.react-toggle--checked .react-toggle-thumb {
		border-color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;
