/*
 * File: notifications.slice.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on April 29, 2022 at 6:43 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:48 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		hasUnreadNotifications: false,
		unreadNotificationCount: {
			all: 0
		},
	},
};


/**
 * Slices
 */

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		updateNotifications: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearNotifications: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateNotifications, clearNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
