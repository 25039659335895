/*
 * File: AboutAgeStep.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 13, 2024 at 10:58 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Constants
import { mobileBreakpoint } from '../../../../styles/constants';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	max-width: 650px;
	margin: 0px auto;

	@media only screen {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	.cardPadding {
		padding: 50px;
	}
	h2 {
		text-align: left;
		margin: 10px 0px 32px;
	}
	h3 {
		text-align: left;
		margin: 10px 0px 32px;
	}
	p {
		text-align: left;
		color: ${({ theme }) => theme.secondaryText};
		margin-bottom: 14px;
	}
	& > div > button {
		margin-top: 30px;
	}
	button {
		width: 100%;
	}
`;

export const IconContainer = styled.div`
	text-align: left;
	color: ${({ theme }) => theme.brandSecondaryBase};
	margin-bottom: 20px;

	svg {
		font-size: 2.4rem;
	}
`;

export const SelectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const AgeRangeOption = styled.div`
	padding: 10px 22px;
	border-radius: 40px;
	border: 2px solid ${({ theme }) => theme.layoutBorder};
	text-align: left;
	cursor: pointer;

	p {
		margin: 0px;
	}
	&:hover {
		border: 2px solid ${({ theme }) => theme.brandSecondaryBase};
	}
	${({ $isSelected }) => $isSelected && css`
		border: 2px solid ${({ theme }) => theme.brandSecondaryBase};
		background-color: ${({ theme }) => theme.brandSecondaryBase};

		p {
			color: ${({ theme }) => theme.primaryBackground};
		}
	`}
`;

export const IntentionWidget = styled.div`
	border-radius: 6px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	color: ${({ theme }) => theme.secondaryText};
	padding: 12px 14px;
	margin: 15px 0px 0px;

	p {
		margin: 0px;
	}
`;
