/*
 * File: Rewards.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: June 18, 2024 at 1:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../../../components';


/**
 * Styles
 */

export const Wrapper = styled(Padding)`
	position: relative;
	width: 100%;
	flex-direction: column;
	padding-top: 40px;
	padding-bottom: 40px;
`;

export const Decoration = styled.div`
	position: absolute;
	z-index: 1;
	width: 100%;
	min-height: 70vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/green-bump-decoration.png');
	background-size: cover;
	background-position: 50% 0%;
	background-repeat: no-repeat;
`;

export const TitleWidget = styled.div`
	width: 100%;
	min-height: 62px;
	height: auto;
	border-radius: 20px;
	border: 2px solid ${({ theme }) => theme.brandSecondaryBaseDarker};
	display: flex;
	align-items: center;
	z-index: 2;
	flex-shrink: 0;

	h4 {
		color: ${({ theme }) => theme.brandSecondaryBaseDarker};
		text-align: left;
		margin-left: 30px;
	}
`;

export const ContentContainer = styled.div`
	height: 100%; // Centers loader
	text-align: left;
	z-index: 2;
	margin: 30px 0px 0px;
`;
