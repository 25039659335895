/*
 * File: utilities.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on March 30, 2022 at 3:54 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 15, 2024 at 9:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Constants
 */

const HTML_ENTITIES = {
	nbsp: ' ',
	cent: '¢',
	pound: '£',
	yen: '¥',
	euro: '€',
	copy: '©',
	reg: '®',
	lt: '<',
	gt: '>',
	quot: '"',
	amp: '&',
	apos: '\'',
	iexcl: '¡',
	curren: '¤',
	brvbar: '¦',
	sect: '§',
	uml: '¨',
	ordf: 'ª',
	laquo: '«',
	not: '¬',
	shy: '­',
	macr: '¯',
	deg: '°',
	plusmn: '±',
	sup2: '²',
	sup3: '³',
	acute: '´',
	micro: 'µ',
	para: '¶',
	cedil: '¸',
	sup1: '¹',
	ordm: 'º',
	raquo: '»',
	frac14: '¼',
	frac12: '½',
	frac34: '¾',
	iquest: '¿',
	times: '×',
	divide: '÷',
	forall: '∀',
	part: '∂',
	exist: '∃',
	empty: '∅',
	nabla: '∇',
	isin: '∈',
	notin: '∉',
	ni: '∋',
	prod: '∏',
	sum: '∑',
	minus: '−',
	lowast: '∗',
	radic: '√',
	prop: '∝',
	infin: '∞',
	ang: '∠',
	and: '∧',
	or: '∨',
	cap: '∩',
	cup: '∪',
	int: '∫',
	there4: '∴',
	sim: '∼',
	cong: '≅',
	asymp: '≈',
	ne: '≠',
	equiv: '≡',
	le: '≤',
	ge: '≥',
	sub: '⊂',
	sup: '⊃',
	nsub: '⊄',
	sube: '⊆',
	supe: '⊇',
	oplus: '⊕',
	otimes: '⊗',
	perp: '⊥',
	sdot: '⋅',
	OElig: 'Œ',
	oelig: 'œ',
	Scaron: 'Š',
	scaron: 'š',
	Yuml: 'Ÿ',
	fnof: 'ƒ',
	circ: 'ˆ',
	tilde: '˜',
	ensp: ' ',
	emsp: ' ',
	thinsp: ' ',
	zwnj: '‌',
	zwj: '‍',
	ndash: '–',
	mdash: '—',
	lsquo: '‘',
	rsquo: '’',
	sbquo: '‚',
	ldquo: '“',
	rdquo: '”',
	bdquo: '„',
	dagger: '†',
	Dagger: '‡',
	bull: '•',
	hellip: '…',
	permil: '‰',
	prime: '′',
	Prime: '″',
	lsaquo: '‹',
	rsaquo: '›',
	oline: '‾',
	trade: '™',
	larr: '←',
	uarr: '↑',
	rarr: '→',
	darr: '↓',
	harr: '↔',
	crarr: '↵',
	lceil: '⌈',
	rceil: '⌉',
	lfloor: '⌊',
	rfloor: '⌋',
	loz: '◊',
	spades: '',
	clubs: '',
	hearts: '',
	diams: '',
};


/**
 * Utilities
 */

export const formatNumber = (n) => {
	if (n < 1e3) return n.toFixed(0);
	if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}k`;
	if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}m`;
	if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}b`;
	if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}t`;
	return null;
};

export const roundWithDenom = (n, denom = 10) => Math.round(n * denom) / denom;

export const formatNumberWithCommas = (n) => n.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

export const camelize = (str) => str.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '').replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const replaceHTMLMarkup = (content) => (content ? content.replaceAll('<br />', '\n').replaceAll('<br/>', '\n').replace(/<[^>]+>/g, '') : content);

export const formatToUSD = (number = 0) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);

// eslint-disable-next-line no-promise-executor-return
export const timeout = (ms) => new Promise((res) => setTimeout(res, ms));

export const shuffleArray = (array) => {
	for (let i = array.length - 1; i > 0; i -= 1) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
};


/**
 * Decoders
 */

export const unescapeHTML = (str) => str.replace(/&([^;]+);/g, (entity, entityCode) => {
	let match;
	if (entityCode in HTML_ENTITIES) {
		return HTML_ENTITIES[entityCode];
		/* eslint no-cond-assign: 0 */
	} if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
		return String.fromCharCode(parseInt(match[1], 16));
		/* eslint no-cond-assign: 0 */
	} if (match = entityCode.match(/^#(\d+)$/)) {
		return String.fromCharCode(match[1]);
	}
	return entity;
});


/**
 * Parsers
 */

export const parseFirstLastName = (name) => {
	if (!name) return {};
	const nameArray = name.split(/\s+/);
	const firstName = nameArray[0].trim();
	const lastName = name.replace(firstName, '').trim();
	return { firstName, lastName };
};
