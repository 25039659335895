/*
 * File: dateTime.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 20, 2022 at 9:13 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Constants
import { DEFAULT_TIMEZONE } from '../Constants';

// Plugins
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);


/**
 * Configuration
 */

TimeAgo.addDefaultLocale(en);


/**
 * Utilities
 */

export const formatToRelativeTime = (date) => {
	const timeAgo = new TimeAgo('en-US');
	return timeAgo.format(date);
};

export const formatDateString = (date, format) => dayjs(date || new Date()).tz(DEFAULT_TIMEZONE).format(format);

export const parseDateString = (value, format) => dayjs(value, format);

export const dateObj = (date) => dayjs(date || new Date()).tz(DEFAULT_TIMEZONE);

export const stringToDate = (content, format) => dayjs(content, format).toDate();

export const formatDate = (date, format) => dayjs(date).tz(DEFAULT_TIMEZONE).format(format);

export const formatMinutesToLargest = (minutes) => {
	const hours = Math.round((minutes / 60.0) * 10) / 10;
	if (minutes < 60) return minutes === 1 ? '1 min' : `${minutes} mins`;
	return hours === 1.0 ? '1 hour' : `${hours} hours`;
};

export const formatMinuteLength = (minutes) => `${Math.ceil(minutes)} min read`;
