/*
 * File: AccessCodeStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 10, 2024 at 3:36 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { validateAccessCode } from '../../../../services/access';

// Components
import {
	Button, TextInput, Typography
} from '../../../../components';

// Styles
import * as S from './AccessCodeStep.styles';


/**
 * Component
 */

export const AccessCodeStep = forwardRef(({
	updateStep, setAccessCode, className
}, ref) => {

	// Create state handlers
	const [codeError, setCodeError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get actions from hooks
	const navigate = useNavigate();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Handle access code function
	const handleAccessCode = async () => {

		// Get parameters
		const { code } = inputValues;

		// Validate parameters
		if (!code || validator.isEmpty(code, { ignore_whitespace: true })) {
			setCodeError({ message: 'Please enter your access code from your program instructor' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Valid access code
		validateAccessCode({
			code
		}).then(async () => {

			// Set access code
			setAccessCode(code);

			// Update step
			updateStep(2);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setCodeError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We can\'t find a matching access code in our system. Give it another go.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle login action function
	const loginFunction = () => {
		navigate(`${stateLocale.localePath}/login`);
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Content */}
			<Typography tag="h2" weight="bold">Code Entry</Typography>
			<Typography tag="p" variation="1" weight="regular">Enter your code to begin the signup process. You should have received this from the program instructor.</Typography>

			{/* Inputs */}
			<TextInput
				label="Code"
				name="code"
				type="text"
				error={codeError}
				value={inputValues.code || ''}
				containerClassName="inputSpacer"
				onFocus={() => { setCodeError(null); }}
				onKeyUp={() => { setCodeError(null); }}
				onBlur={() => { setCodeError(null); }}
				onChange={handleOnChange}
			/>

			{/* Continue Button */}
			<Button disabled={isLoading} isLoading={isLoading} onClick={handleAccessCode} size="large">Continue</Button>

			{/* Login Button */}
			<S.BottomActionContainer>
				<Button variant="text" size="small" onClick={loginFunction} disabled={isLoading}>
					<Typography tag="p" variation="2" weight="medium">
						Already have an account?
						{' '}
						<Typography weight="bold">
							Log In
						</Typography>
					</Typography>
				</Button>
			</S.BottomActionContainer>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

AccessCodeStep.displayName = 'AccessCodeStep';
AccessCodeStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	setAccessCode: PropTypes.func
};
AccessCodeStep.defaultProps = {
	className: null,
	setAccessCode: null
};
