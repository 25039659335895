/*
 * File: Topbar.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:46 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Utilities
import { parseFirstLastName } from '../../../../utilities/utilities';

// Slices
import { updateUI } from '../../../store/slices/ui/ui.slice';

// Components
import { Padding } from '../../Padding';

// Constants
import { navigationDropdownDelay } from '../../../styles/constants';

// Styles
import * as S from './Topbar.styles';
import { Typography } from '../../Typography';


/**
 * State
 */

let navigationDropdownTimer = null;


/**
 * Component
 */

export const Topbar = ({
	toggleSidebar, hideSidebar
}) => {

	// Set state
	const [showNavigationDropdown, setShowNavigationDropdown] = useState(false);

	// Create references for elements
	const profileRef = useRef();
	const isMounted = useRef(true);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current notification state from hook
	const notificationState = useSelector((state) => state.notifications.value);

	// Use hooks
	const dispatch = useDispatch();

	// Handle actions on component load
	useEffect(() => {

		// Initialize mounted state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render navigation actions
	const renderNavigationActions = () => (
		<S.ActionPadding>
			<S.ProfileContainer
				onMouseEnter={() => {
					clearTimeout(navigationDropdownTimer);
					setShowNavigationDropdown(true);
				}}
				onMouseLeave={() => {
					clearTimeout(navigationDropdownTimer);
					navigationDropdownTimer = setTimeout(() => {
						setShowNavigationDropdown(false);
					}, navigationDropdownDelay);
				}}
			>
				<S.ProfileImageContainer>
					<S.ProfileImage ref={profileRef} src={user.avatarImage.image} title="My Profile" alt="My Profile" />
				</S.ProfileImageContainer>
				{notificationState.hasUnreadNotifications && <S.UnreadBadge />}
				<S.NavigationDropdown
					className={showNavigationDropdown ? 'animate auth show' : 'auth animate'}
					items={[
						{
							icon: uiMode.mode === 'light' ? ['fal', 'moon'] : ['fal', 'sun'],
							title: uiMode.mode === 'light' ? 'Dark Mode' : 'Light Mode',
							action: () => {
								setShowNavigationDropdown(false);
								dispatch(updateUI({ isSet: true, mode: uiMode.mode === 'light' ? 'dark' : 'light' }));
							}
						}
					]}
					footerItems={[
						{
							icon: ['fal', 'sign-out'],
							title: 'Log out',
							action: () => { window.location = '/logout'; }
						}
					]}
				/>
			</S.ProfileContainer>
		</S.ActionPadding>
	);

	// Render component
	return (
		<S.Topbar>
			<Padding left right>
				{!hideSidebar && (
					<S.TopbarWrapper>
						<S.NavigationToggle icon={['fal', 'bars']} size={1.25} onClick={toggleSidebar} />
					</S.TopbarWrapper>
				)}
				<S.TopbarWrapper fullWidth />
				<S.TopbarWrapper>
					<Typography style={{ height: 'auto' }} variation="2" tag="p" weight="medium" className="isMobile">
						{parseFirstLastName(user.name)?.firstName}
					</Typography>
					<Typography style={{ height: 'auto' }} variation="2" tag="p" weight="medium" className="isNotMobile">
						{user.name}
					</Typography>
					{renderNavigationActions()}
				</S.TopbarWrapper>
			</Padding>
		</S.Topbar>
	);
};


/**
 * Configuration
 */

Topbar.displayName = 'Topbar';
Topbar.propTypes = {
	toggleSidebar: PropTypes.func,
	hideSidebar: PropTypes.bool
};
Topbar.defaultProps = {
	toggleSidebar: null,
	hideSidebar: false
};
