/*
 * File: AboutAgeStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: August 6, 2024 at 9:46 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { updateUserProfile } from '../../../../services/user';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, TextInput, Typography
} from '../../../../components';

// Constants
import { AGE_RANGE_OPTIONS, ROLES } from '../../../../../Constants';

// Styles
import * as S from './AboutAgeStep.styles';


/**
 * Component
 */

export const AboutAgeStep = forwardRef(({
	updateStep, role, className
}, ref) => {

	// Create state handlers
	const [ageError, setAgeError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [selectedAgeRange, setSelectedAgeRange] = useState(null);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get action from hooks
	const dispatch = useDispatch();

	// Handle next function
	const handleNext = async () => {

		// Get parameters
		const { age } = inputValues;

		// Validate parameters
		if (role === ROLES.PARENT) {
			if (!age || validator.isEmpty(age, { ignore_whitespace: true }) || age <= 0 || age >= 99) {
				setAgeError({ message: 'Please enter your age to continue' });
				return;
			}
			if (age < 18) {
				setAgeError({ message: 'You must be at least 18 to use Let\'s Talk' });
				return;
			}
		} else if (!selectedAgeRange || validator.isEmpty(selectedAgeRange, { ignore_whitespace: true })) {
			toastError(uiMode, 'Please select your age range to continue');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserProfile({
			...role === ROLES.PARENT ? {
				age
			} : {
				ageRange: selectedAgeRange
			}
		}).then(async ({ data: { user: newUser } }) => {

			// Update user state
			dispatch(updateUser(newUser));

			// Update step
			if (role === ROLES.PARENT) {
				updateStep(8);
			} else {
				updateStep(7);
			}

			// Set loading state
			setIsLoading(false);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setAgeError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'user']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h3" weight="bold">Tell Us About Yourself</Typography>

				{/* Instructions */}
				<Typography tag="p" variation="1" weight="medium">{role === ROLES.PARENT ? 'What is your age?' : 'What is your age range?'}</Typography>

				{/* Inputs */}
				{role === ROLES.PARENT
					? (
						<TextInput
							name="age"
							type="number"
							error={ageError}
							value={inputValues.age || ''}
							containerClassName="inputSpacer"
							onFocus={() => { setAgeError(null); }}
							onKeyUp={() => { setAgeError(null); }}
							onBlur={() => { setAgeError(null); }}
							onChange={handleOnChange}
						/>
					) : (
						<>
							<S.SelectionContainer>
								{AGE_RANGE_OPTIONS[role].map((option) => (
									<S.AgeRangeOption
										key={option.value}
										className="animate"
										onClick={() => { setSelectedAgeRange(option.value); }}
										$isSelected={option.value === selectedAgeRange}
									>
										<Typography tag="p">{option.label}</Typography>
									</S.AgeRangeOption>
								))}
							</S.SelectionContainer>
							<S.IntentionWidget>
								<Typography tag="p" variation="2">This program is intended for kids in the 10-13 year old age range.</Typography>
							</S.IntentionWidget>
						</>
					)}

				{/* Next Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">Next</Button>

				{/* Back Button */}
				<Button
					disabled={isLoading}
					onClick={() => { updateStep(role === ROLES.PARENT ? 6 : 5); }}
					size="small"
					variant="text"
					style={{ margin: '10px 0px -5px' }}
				>
					<FontAwesomeIcon icon={['fas', 'arrow-left']} />
					<Typography variation="button-medium" weight="semibold">Go Back</Typography>
				</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

AboutAgeStep.displayName = 'AboutAgeStep';
AboutAgeStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	role: PropTypes.string
};
AboutAgeStep.defaultProps = {
	className: null,
	role: null
};
