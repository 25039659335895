/*
 * File: CompletionStep.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 3:27 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mobileBreakpoint } from '../../../../styles/constants';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	max-width: 750px;
	margin: 0px auto;

	@media only screen {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	.cardPadding {
		padding: 40px 50px;
		background-image: url('${process.env.CDN_URL}/public/assets/decorations/white-gold-stars-decoration.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	h3 {
		text-align: center;
		margin: 10px 0px 20px;
	}
	p {
		text-align: center;
		color: ${({ theme }) => theme.secondaryText};
		max-width: 480px;
		width: 100%;
		margin: 0px auto;
	}
	& > div > button {
		margin-top: 30px;
	}
`;

export const IconContainer = styled.div`
	text-align: center;
	color: ${({ theme }) => theme.brandTertiaryBase};
	margin-bottom: 15px;
	position: relative;
	display: inline-flex;

	svg {
		font-size: 3.4rem;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 25px;
	left: 25px;
`;

export const InstructionsContainer = styled.div`
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 8px;
	padding: 10px 25px 30px 40px;
	margin: 20px auto 25px;
	width: 100%;
	max-width: 480px;
	text-align: left;

	* {
		font-size: 0.9rem;
		color: ${({ theme }) => theme.secondaryText};
	}
	li {
		margin-top: 15px;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
`;

export const VideoContainer = styled.div`
	max-width: 480px;
    width: 100%;
    margin: 0px auto 30px;

	video {
		width: 100%;
	}
`;
