/*
 * File: RadioGroup.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: August 6, 2024 at 10:34 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Constants
import { Typography } from '../Typography';

// Styles
import * as S from './RadioGroup.styles';


/**
 * Component
 */

export const RadioGroup = ({
	containerClassName, options, error, value, family, onChange, isCorrect, variant
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render radio option
	const renderRadio = (option) => {
		if (isCorrect && value === option.value) {
			return (
				<S.CorrectIndicator>
					<FontAwesomeIcon icon={['fas', 'check']} />
				</S.CorrectIndicator>
			);
		} if (!isCorrect && value === option.value) {
			return (
				<S.IncorrectIndicator>
					<FontAwesomeIcon icon={['fas', 'xmark']} />
				</S.IncorrectIndicator>
			);
		}
		return (
			<S.Radio
				type="radio"
				name={family}
				value={option.value}
				checked={value === option.value}
				onChange={(e) => {
					if (e.target.checked) {
						onChange(option.value);
					}
				}}
			/>
		);
	};

	// Render component
	return (
		<S.Wrapper className={containerClassName} $variant={variant}>
			{options.map((option) => (
				<S.RadioRow key={option.value}>
					{renderRadio(option)}
					<S.LabelContainer
						$isCorrect={isCorrect && value === option.value}
						$isIncorrect={!isCorrect && value === option.value}
						onClick={() => {
							if (value !== option.value) {
								onChange(option.value);
							}
						}}
					>
						<Typography tag="p" variation="2" center={false}>{option.label}</Typography>
						{option.subLabel && <Typography tag="p" variation="3" className="subLabel" center={false}>{option.subLabel}</Typography>}
					</S.LabelContainer>
				</S.RadioRow>
			))}
			{error && error.message && (
				<S.FloatingWrapper title={error.message} className="animate">
					<Typography tag="p" variation="3" className="animate">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

RadioGroup.displayName = 'RadioGroup';
RadioGroup.propTypes = {
	containerClassName: PropTypes.string,
	value: PropTypes.string,
	family: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	variant: PropTypes.oneOf(['horizontal', 'vertical']),
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
		subLabel: PropTypes.string,
	})),
	error: PropTypes.shape(),
	isCorrect: PropTypes.bool
};
RadioGroup.defaultProps = {
	containerClassName: null,
	value: '',
	onChange: null,
	options: [],
	variant: 'vertical',
	error: null,
	isCorrect: false
};
