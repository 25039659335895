/*
 * File: ConfettiBurst.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const ConfettiContainer = styled.div`
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0px;
	top: 0px;
	pointer-events: none;
`;

export const ConfettiPoint = styled.div`
	position: relative;
	top: ${({ $y }) => $y}px;
	left: ${({ $x }) => $x}px;
`;
