/*
 * File: AvatarStep.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 13, 2024 at 10:58 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Constants
import { mobileBreakpoint } from '../../../../styles/constants';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	max-width: 650px;
	margin: 0px auto;

	@media only screen {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	.cardPadding {
		padding: 50px;
	}
	h2 {
		text-align: left;
		margin: 10px 0px 32px;
	}
	h3 {
		text-align: left;
		margin: 10px 0px 32px;
	}
	p {
		text-align: left;
		color: ${({ theme }) => theme.secondaryText};
		margin-bottom: -10px;
	}
	& > div > button {
		margin-top: 30px;
	}
	button {
		width: 100%;
	}
`;

export const IconContainer = styled.div`
	text-align: left;
	color: ${({ theme }) => theme.brandSecondaryBase};
	margin-bottom: 20px;

	svg {
		font-size: 2.4rem;
	}
`;

export const SelectionContainer = styled.div`
	padding: 20px 0px 20px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		grid-template-columns: repeat(6, 1fr);
	}
`;

export const AvatarOption = styled.div`
	position: relative;
	padding: 15px 15px 6px;
	border-radius: 6px;
	cursor: pointer;

	${({ $isSelected, theme }) => ($isSelected ? css`
		border: 2px solid ${theme.brandSecondaryBase};
	` : css`
		border: 2px solid ${theme.primaryBackground};
	`)}
`;

export const AvatarImage = styled.img`
	height: 68px;
	width: auto;
	position: relative;
	z-index: 2;
`;

export const AvatarDecoration = styled.div`
	position: absolute;
	border-radius: 50%;
	width: 61px;
	height: 61px;
	top: 7px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	background-color: ${({ theme }) => theme.brandTertiaryBase};
`;
