/*
 * File: Video.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 3:35 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	position: relative;
`;

export const Video = styled.video`
	border-radius: 10px;
`;

export const VideoOverlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const PlayButton = styled.button`
	background-color: ${({ theme }) => theme.brandSecondaryBase};
	width: 80px !important;
	height: 80px !important;
	border-radius: 50%;
	box-shadow: ${ColorValues.elementShadow};
	appearance: none;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: ${ColorValues.white};
		font-size: 1.6rem;
	}
`;
