/*
 * File: CreateAccountIntroductionStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:49 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import {
	Button, Card, Typography
} from '../../../../components';

// Styles
import * as S from './CreateAccountIntroductionStep.styles';


/**
 * Component
 */

export const CreateAccountIntroductionStep = forwardRef(({
	updateStep, className
}, ref) => {

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Get actions from hooks
	const navigate = useNavigate();

	// Handle next function
	const handleNext = async () => {
		updateStep(2);
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'user']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h3" weight="bold">Create an Account for your Child</Typography>

				{/* Instructions */}
				<Typography tag="p" variation="1" weight="medium">
					You&apos;re making progress! Next, it&apos;s time to create a login for your child to be able to access the app.
				</Typography>

				{/* Next Button */}
				<Button onClick={handleNext} size="large">Next</Button>

				{/* Back Button */}
				<Button
					onClick={() => { navigate(`${locale.localePath}/login`); }}
					size="small"
					variant="text"
					style={{ margin: '10px 0px -5px' }}
				>
					<FontAwesomeIcon icon={['fas', 'arrow-left']} />
					<Typography variation="button-medium" weight="semibold">Go Back</Typography>
				</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

CreateAccountIntroductionStep.displayName = 'CreateAccountIntroductionStep';
CreateAccountIntroductionStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
};
CreateAccountIntroductionStep.defaultProps = {
	className: null
};
