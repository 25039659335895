/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 27, 2022 at 11:37 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: June 18, 2024 at 2:05 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import activitiesReducer from './slices/activities/activities.slice';
import confettiReducer from './slices/confetti/confetti.slice';
import userReducer from './slices/user/user.slice';
import localeReducer from './slices/locale/locale.slice';
import consentReducer from './slices/consent/consent.slice';
import uiReducer from './slices/ui/ui.slice';
import notificationsReducer from './slices/notifications/notifications.slice';
import rewardsReducer from './slices/rewards/rewards.slice';
import contentReducer from './slices/content/content.slice';
import journalReducer from './slices/journal/journal.slice';


/**
 * Store
 */

export default configureStore({
	reducer: {
		activities: activitiesReducer,
		confetti: confettiReducer,
		user: userReducer,
		locale: localeReducer,
		consent: consentReducer,
		ui: uiReducer,
		notifications: notificationsReducer,
		rewards: rewardsReducer,
		content: contentReducer,
		journal: journalReducer
	},
});
