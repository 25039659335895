/*
 * File: NavigationTab.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 11, 2022 at 12:25 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:14 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import * as S from './NavigationTab.styles';


/**
 * Component
 */

export const NavigationTab = forwardRef(({
	to, icon, activeIcon, label, showBorder, className, ...rest
}, ref) => {

	// Get location
	const location = useLocation();

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Check if active
	const isActive = location.pathname === to || location.pathname === `${locale.localePath}${to}`;

	// Render component
	return (
		<S.Link props={{ isActive }} ref={ref} to={to}>
			<S.Wrapper className={className ? `${className} animate` : 'animate'} isActive={isActive} showBorder={showBorder} {...rest}>
				<FontAwesomeIcon icon={isActive ? activeIcon : icon} />
				{label && <S.Label tag="p" variation="3">{label}</S.Label>}
			</S.Wrapper>
		</S.Link>
	);
});


/**
 * Configuration
 */

NavigationTab.displayName = 'NavigationTab';
NavigationTab.propTypes = {
	to: PropTypes.string.isRequired,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
	activeIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
	label: PropTypes.string,
	showBorder: PropTypes.bool,
	className: PropTypes.string
};
NavigationTab.defaultProps = {
	className: null,
	label: null,
	showBorder: true
};
