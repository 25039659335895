/*
 * File: PublicRouteWrapper.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 29, 2022 at 11:04 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


/**
 * Route Wrapper
 */

export const PublicRouteWrapper = ({
	children, user, locale, requireAuth
}) => {

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Handle auth actions if requires auth
	if (requireAuth === true) {

		// Check user state
		if (!userObj) {

			// Redirect to login
			return <Navigate to={`${localeObj.localePath}/login`} />;
		}
		if (userObj.isAnonymous === true) {

			// Redirect to login
			return <Navigate to={`${localeObj.localePath}/login`} />;
		}
		if (userObj.isActive !== true) {

			// Redirect to logout
			window.location = '/logout';
			return null;
		}
	}

	// Return children
	return children;
};


/**
 * Configuration
 */

PublicRouteWrapper.propTypes = {
	children: PropTypes.element,
	requireAuth: PropTypes.bool,
	user: PropTypes.shape(),
	locale: PropTypes.shape()
};
PublicRouteWrapper.defaultProps = {
	children: null,
	requireAuth: false,
	user: null,
	locale: null
};
