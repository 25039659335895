/*
 * File: locale.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 9:42 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Constants
import { AVAILABLE_LOCALES } from '../../Constants';


/**
 * Utilities
 */

export const createLocaleVariants = (route) => {

	// Get parameters
	const { path, paths } = route;
	const routes = path ? [route] : paths.map((pathObj) => ({
		...route,
		path: pathObj
	}));

	// Create route for each locale
	if (path) {
		AVAILABLE_LOCALES.forEach((locale) => {
			routes.push({
				...route,
				path: `/${locale}${path}`,
				localeId: locale,
				isAlternative: true
			});
		});
	} else if (paths) {
		paths.forEach((pathObj) => {
			AVAILABLE_LOCALES.forEach((locale) => {
				routes.push({
					...route,
					path: `/${locale}${pathObj}`,
					localeId: locale,
					isAlternative: true
				});
			});
		});
	}

	// Return routes
	return routes;
};

export const createStateLocale = (clientLocale, serverLocale) => {
	const stateLocale = serverLocale && Object.keys(serverLocale).length > 0 ? serverLocale : clientLocale;
	return clientLocale?.isSet ? clientLocale : stateLocale;
};
