/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on May 30, 2024 at 10:23 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: July 14, 2024 at 10:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const fetchActivityUnits = (data) => api.get('/activities/units', { params: data });

export const fetchActivityModule = ({ slug }) => api.get(`/activities/module/${slug}`);

export const beginActivityModule = ({ slug, stepId }) => api.post(`/activities/module/${slug}/begin`, { stepId });

export const completeActivityModule = ({ slug }) => api.post(`/activities/module/${slug}/complete`);

export const updateActivityModuleProgress = ({
	slug, stepId, isCompleted, response
}) => api.post(`/activities/module/${slug}/progress`, { stepId, isCompleted, response });

export const postActivityRating = ({
	slug, rating, stepId, responses
}) => api.post(`/activities/module/${slug}/feedback`, { rating, stepId, responses });
