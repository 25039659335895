/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 5:02 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const beginPasswordReset = (data) => api.post('/password/forgot', data);

export const validatePasswordResetAttempt = (data) => api.get('/password/reset', { params: data });

export const resetPassword = (data) => api.post('/password/reset', data);
