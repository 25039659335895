/*
 * File: LegalNavigation.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 13, 2022 at 5:15 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 30, 2024 at 10:42 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { LocaleLink } from '../LocaleLink';
import { Padding } from '../Padding';
import { Typography } from '../Typography';

// Styles
import { ColorValues } from '../../styles/colors';

// Constants
import { LegalNavSidebarWidth, mobileBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const LegalNavigation = styled.div`
	display: block;
	width: 100%;
	margin: 0 0 80px;
`;

export const InnerNavigation = styled.div`
	display: flex;
	overflow: visible;
	height: 100%;
	flex-direction: column;
	margin: 0 auto 0px;
	background-color: ${({ theme }) => theme.primaryBackground};
`;

export const MobileNavigation = styled.div`
	display: flex;
	flex-direction: column;

	* {
		text-align: left;
	}
`;

export const MobileNavigationToggle = styled(FontAwesomeIcon)`
	font-size: 1.0rem;
`;

export const MobileHeader = styled.div`
	padding: 22px 0;
	border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
	cursor: pointer;
	display: flex;
	align-items: center;

	& > div {
		align-items: center;
	}
	h5 {
		line-height: normal;
		flex-grow: 1;
	}
`;

export const MobileDropdown = styled.div`
	overflow: hidden;

	& > div {
		flex-direction: column;
	}
	${({ isOpen, elementHeight }) => (isOpen ? css`
		height: ${elementHeight}px;
		border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
	` : css`
		height: 0px;
		border-bottom: 1px solid transparent;
	`)};
`;

export const Wrapper = styled(Padding)`
	display: flex;
	overflow: visible;
	height: 100%;
	position: relative;
`;

export const SidebarNavigation = styled.div`
	padding: 55px 0px 0px 0;
	width: ${LegalNavSidebarWidth}px;
	bottom: unset;
	position: fixed;
	display: flex;
	flex-direction: column;
	z-index: 2;
	pointer-events: none;

	& > div {
		flex-direction: column;
	}
	* {
		text-align: left;
	}
	&.bottom {
		position: absolute;
		bottom: 0px;
	}
`;

export const NavigationSection = styled.div`
	margin: 30px 0 0;
`;

export const Link = styled(LocaleLink)`
	display: block;
	margin: 14px 0 0;
	font-weight: 300;
	text-decoration: none;
	color: ${({ theme }) => theme.primaryText};
	position: relative;
	pointer-events: all;

	&:hover {
		opacity: 0.7;
	}
`;

export const LinkActive = styled.div`
	height: 100%;
	width: 3px;
	position: absolute;
	background-color: ${ColorValues.brandPrimaryBase};
	top: 0px;
	left: -10px;
	border-radius: 2px;
`;

export const Content = styled.div`
	flex: 1;
  	display: flex;
	padding-top: 0px;
	flex-direction: column;
	padding: 0px;

	${({ allowOverflow }) => (allowOverflow ? css`
		overflow: visible;
	` : css`
		overflow: auto;
	`)};
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 0 0 0 ${LegalNavSidebarWidth}px;
	}
`;

export const ContentPadding = styled(Padding)`
	display: block;
	flex-grow: 1;
	padding-top: 50px;
`;

export const HighlightSection = styled.div`
	padding: 40px;
	border-radius: 12px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	margin: 0 0 30px;
`;

export const HighlightTitle = styled(Typography)`
	display: flex;
	align-items: center;
	margin: 0 0 10px;

	span {
		margin-left: 6px;
	}
`;
