/*
 * File: UIModeWidget.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:21 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Store
import { updateUI } from '../../store/slices/ui/ui.slice';

// Components
import { Badge } from '../Badge';

// Styles
import * as S from './UIModeWidget.styles';


/**
 * Component
 */

export const UIModeWidget = ({
	className
}) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Use hooks
	const dispatch = useDispatch();

	// Handle update UI mode
	const updateUIMode = () => {
		dispatch(updateUI({ isSet: true, mode: uiMode.mode === 'light' ? 'dark' : 'light' }));
	};

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper className={className}>
			<Badge
				onClick={updateUIMode}
				variant="outlined"
				type="neutral"
				icon={{ source: uiMode.mode === 'light' ? ['fal', 'moon'] : ['fal', 'sun'], placement: 'left' }}
				className="uiBadge"
			>
				{uiMode.mode === 'light' ? 'Dark Mode' : 'Light Mode'}
			</Badge>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

UIModeWidget.displayName = 'UIModeWidget';
UIModeWidget.propTypes = {
	className: PropTypes.string
};
UIModeWidget.defaultProps = {
	className: null,
};
