/*
 * File: storyblok.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on July 6, 2024 at 1:23 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: July 6, 2024 at 1:27 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const isRichTextEmpty = (content) => {
	if (!content) return true;
	if (!content.content) return true;
	if (content.content.length === 0) return true;
	if (content.content.length === 1 && !content.content[0].content) return true;
	return false;
};
