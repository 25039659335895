/*
 * File: Bottombar.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 19, 2022 at 11:25 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 17, 2024 at 12:05 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Bottombar = styled.div`
	height: auto;
	background-color: ${({ theme }) => theme.primaryBackground};
	position: fixed;
	display: flex;
	justify-content: space-between;
	width: 100%;
	z-index: 998;
	padding: 14px 0px 18px;
	bottom: ${({ showBar, barHeight }) => (showBar ? 0 : `-${barHeight}px`)};
	box-shadow: ${ColorValues.cookieBarShadow};
	transition: bottom 0.6s;
	-webkit-transition: bottom 0.6s;
	-moz-transition: bottom 0.6s;
	-o-transition: bottom 0.6s;
`;

export const TabContainer = styled.div`
	flex: 1 1 0px;
	min-width: 0;

	div {
		height: auto !important;
	}
`;
