/*
 * File: Journal.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 8, 2024 at 3:12 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card } from '../Card';

// Constants
import { mediumBreakpoint, mobileBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	position: relative;
	z-index: 3;
`;

export const TitleWidget = styled.div`
	width: 100%;
	min-height: 62px;
	height: auto;
	border-radius: 20px;
	border: 2px solid ${({ theme }) => theme.brandSecondaryBaseDarker};
	display: flex;
	align-items: center;
	z-index: 2;
	flex-shrink: 0;
	flex-direction: column;
	padding: 15px;
	gap: 10px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
		padding: 0px;
		border-radius: 100px;
		height: 62px;
	}
	h4 {
		color: ${({ theme }) => theme.brandSecondaryBaseDarker};
		text-align: left;
		margin-left: 30px;
		flex-grow: 1;
		height: 100%;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	button {
		height: 62px;
		right: 0px;
		position: relative;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			right: -2px;
		}
	}
`;

export const ContentContainer = styled.div`
	height: 100%; // Centers loader
	text-align: left;
	z-index: 2;
	margin-bottom: 100px;
`;

export const Header = styled.div`
	margin: 30px 0px 30px;
	width: 100%;
	max-width: 100%;

	* {
		text-align: left;
		line-height: 1.6;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		max-width: 75%;
	}
`;

export const JournalEntries = styled.div`
	padding: 30px 0px 0px;
	gap: 60px;
	display: flex;
	flex-direction: column;
`;

export const JournalContainer = styled.div`
	gap: 10px;
	display: flex;
	flex-direction: column;
`;

export const JournalEntry = styled(Card)`
	padding: 0px;
	margin: 8px 0px 0px;
`;

export const PromptContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	padding: 24px;

	h5 {
		flex-grow: 1;
		text-align: left;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
	}
	* {
		margin: 0px;
	}
`;

export const ResponseContainer = styled.div`
	padding: 0px 24px 24px;

	p {
		white-space: pre-line;
	}
`;

export const ActiveIndicator = styled.div`
	background-color: ${({ theme }) => theme.brandTertiaryBase};
	height: 10px;
	width: 10px;
	border-radius: 50%;
	flex-shrink: 0;
`;

export const FullSize = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const EmptyComponent = styled.div`
	text-align: center;
	max-width: 700px;

	& > p {
		margin-top: 10px;
	}
	svg {
		align-self: center;
		margin-bottom: 1.8rem;
		color: ${({ theme }) => theme.placeholderHighlight};
	}
	button {
		margin: 20px auto 0px;

		svg {
			margin: 0px;
		}
	}
`;

export const JournalEntrySection = styled.div`
	* {
		text-align: left;
	}
	& > p {
		color: ${({ theme }) => theme.secondaryText};
		margin-left: 10px;
	}
`;
