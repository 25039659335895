/*
 * File: ButtonFeedbackWidget.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: August 6, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Services
import { postActivityRating } from '../../services/activities';

// Components
import { Emoji } from '../Emoji';
import { Typography } from '../Typography';

// Styles
import * as S from './ButtonFeedbackWidget.styles';


/**
 * Constants
 */

const RATING_OPTIONS = [
	{
		id: 'sad',
		value: 'sad',
		display: '☹️'
	},
	{
		id: 'neutral',
		value: 'neutral',
		display: '😐'
	},
	{
		id: 'smile',
		value: 'smile',
		display: '😃'
	}
];


/**
 * Component
 */

export const ButtonFeedbackWidget = ({
	className, slug, continueAction, stepId, responses
}) => {

	// Handle actions on component load
	useEffect(() => {}, []);

	// Handle save rating
	const saveRating = async ({ value }) => {
		try {

			// Send feedback
			await postActivityRating({
				slug, rating: value, stepId, responses
			});

		} catch (e) {}

		// Fire continue action
		if (continueAction) continueAction();
	};

	// Render component
	return (
		<S.Wrapper className={className}>
			<Typography tag="p" variation="3" weight="semibold" className="isNotMobile">
				How did you like this activity?
			</Typography>
			{RATING_OPTIONS.map((option, index) => (
				<div key={option.id}>
					<S.RatingButton variant="text" onClick={() => { saveRating(option); }}>
						<Emoji symbol={option.display} label={option.id} size={1.4} />
					</S.RatingButton>
					{index < RATING_OPTIONS.length - 1 && <S.Divider />}
				</div>
			))}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

ButtonFeedbackWidget.displayName = 'ButtonFeedbackWidget';
ButtonFeedbackWidget.propTypes = {
	className: PropTypes.string,
	slug: PropTypes.string,
	continueAction: PropTypes.func,
	stepId: PropTypes.string,
	responses: PropTypes.shape()
};
ButtonFeedbackWidget.defaultProps = {
	className: null,
	slug: null,
	continueAction: null,
	stepId: null,
	responses: null
};
