/*
 * File: Onboarding.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on April 8, 2024 at 8:51 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: May 16, 2024 at 10:58 PM
 * Modified By: Brendan Michaelsen
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Padding } from '../../../components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
	text-align: center;
`;

export const Logo = styled.img`
	width: 80px;
	margin: ${({ $isVisible }) => ($isVisible ? '40px auto 20px' : '0px auto 0px')};
	text-align: center;
	opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		width: 100px;
		margin: ${({ $isVisible }) => ($isVisible ? '60px auto 25px' : '0px auto 0px')};
	}
`;

export const Decoration = styled.div`
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;
	left: 0px;
	bottom: 0px;
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/green-wave-decoration.png');
	background-size: cover;
	background-position: 50% 50vh;
	background-repeat: no-repeat;
`;

export const SecondaryDecoration = styled.div`
	position: fixed;
	z-index: 2;
	width: 100%;
	height: 100%;
	left: 0px;
	bottom: 0px;
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/green-wave-stars-decoration.png');
	background-size: cover;
	background-position: 50% 2vh;
	background-repeat: no-repeat;
	opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
`;

export const StepContainer = styled.div`
	position: relative;
	overflow: hidden;
	z-index: 3;
	height: ${({ stepHeight }) => (stepHeight ? `${stepHeight}px` : 'auto')};
`;

export const Step = styled.div`
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 0px;
	pointer-events: none;
	width: 100%;

	&.show {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.3s;
		position: relative;
		pointer-events: initial;
	}
`;

export const StepInner = styled(Padding)`
	width: 100%;
	justify-content: center;
`;

export const ProgressWidget = styled.div`
	position: relative;
	margin-bottom: ${({ $isVisible }) => ($isVisible ? '20px' : '0px')};
	opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		margin-bottom: ${({ $isVisible }) => ($isVisible ? '60px' : '0px')};
	}
`;

export const ProgressWidgetInner = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	gap: 40px;
`;

export const ProgressStep = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3px;
	position: relative;
	z-index: 2;

	p {
		${({ $status, theme }) => $status === 'inactive' && css`
			color: ${theme.secondaryText};
		`}
		${({ $status, theme }) => $status === 'active' && css`
			color: ${theme.brandPrimaryBase};
		`}
		${({ $status, theme }) => $status === 'complete' && css`
			color: ${theme.secondaryText};
		`}
	}
`;

export const ProgressCircle = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ $status, theme }) => $status === 'inactive' && css`
		border: 1px solid ${theme.secondaryText};
		background-color: ${theme.primaryBackground};
	`}
	${({ $status, theme }) => $status === 'active' && css`
		border: 2px solid ${theme.brandPrimaryBase};
		background-color: ${theme.primaryBackground};
	`}
	${({ $status, theme }) => $status === 'complete' && css`
		border: 1px solid ${theme.brandPrimaryBase};
		background-color: ${theme.brandPrimaryBase};
	`}
	span {
		${({ $status, theme }) => $status === 'inactive' && css`
			color: ${theme.secondaryText};
		`}
		${({ $status, theme }) => $status === 'active' && css`
			color: ${theme.brandPrimaryBase};
		`}
		${({ $status, theme }) => $status === 'complete' && css`
			color: ${theme.primaryBackground};
		`}
	}
`;

export const ProgressStepConnector = styled.div`
	position: absolute;
	top: 14px;
	left: 10px;
	height: 2px;
	width: 80%;
	z-index: 1;
	background-color: ${({ theme }) => theme.layoutBorder};
`;
