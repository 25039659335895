/*
 * File: CookieBar.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 24, 2022 at 9:59 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 9:15 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { setCookie } from '../../utilities/cookies';

// Slices
import { updateConsent } from '../../store/slices/consent/consent.slice';

// Components
import { Padding } from '../Padding';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { LocaleLink } from '../LocaleLink';

// Constants
import {
	CONSENT_COOKIE_EXPIRATION,
	CONSENT_ACTION_TAKEN_COOKIE,
	CONSENT_PERFORMANCE_ENABLED_COOKIE,
	CONSENT_ANALYTICS_ENABLED_COOKIE,
	CONSENT_TARGETING_ENABLED_COOKIE
} from '../../../Constants';

// Styles
import * as S from './CookieBar.styles';


/**
 * Component
 */


export const CookieBar = () => {

	// Get actions from hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Create reference for cookie bar
	const [showBar, setShowBar] = useState(true);
	const [barHeight, setBarHeight] = useState(0);
	const outerRef = useRef();

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Handle actions on component load
	useEffect(() => {

		// Set bar height
		setBarHeight(outerRef.current.clientHeight);
	}, []);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set bar height
		setBarHeight(outerRef.current.clientHeight);
	});

	// Get current consent state from hook
	const currentConsent = useSelector((state) => state.consent.currentValue);

	// Accept cookies function
	const acceptAllCookies = () => {

		// Set cookie values
		setCookie(CONSENT_ACTION_TAKEN_COOKIE, 'true', CONSENT_COOKIE_EXPIRATION);
		setCookie(CONSENT_PERFORMANCE_ENABLED_COOKIE, 'true', CONSENT_COOKIE_EXPIRATION);
		setCookie(CONSENT_ANALYTICS_ENABLED_COOKIE, 'true', CONSENT_COOKIE_EXPIRATION);
		setCookie(CONSENT_TARGETING_ENABLED_COOKIE, 'true', CONSENT_COOKIE_EXPIRATION);

		// Hide cookie bar
		setShowBar(false);

		// Update consent state
		setTimeout(() => {
			dispatch(updateConsent({
				consentActionTaken: true,
				performanceEnabled: true,
				analyticsEnabled: true,
				targetingEnabled: true
			}));
		}, 400);
	};

	// Customize settings function
	const customizeSettings = () => {

		// Set cookie values
		setCookie(CONSENT_ACTION_TAKEN_COOKIE, 'true', CONSENT_COOKIE_EXPIRATION);

		// Hide cookie bar
		setShowBar(false);

		// Update consent state
		dispatch(updateConsent({
			...currentConsent,
			consentActionTaken: true,
		}));

		// Navigate to cookie policy
		navigate(`${stateLocale.localePath}/legal/cookie-policy#settings`, { state: { section: 'settings' } });
	};

	// Return component
	return (
		<S.CookieBar ref={outerRef} showBar={showBar} barHeight={barHeight}>
			<Padding left right>
				<S.TextContainer>
					<Typography tag="p">
						Let&apos;s Talk uses cookies to provide you with a more personalized experience. Please read our
						{' '}
						<LocaleLink to="/legal/cookie-policy">cookie policy</LocaleLink>
						{' '}
						for more information.
					</Typography>
				</S.TextContainer>
				<S.ButtonContainer>
					<Button onClick={() => { acceptAllCookies(); }}>
						Accept All
						<span className="small-screen-span">
						&nbsp;Cookies
						</span>
					</Button>
					<Button variant="text" className="rightGutter" onClick={() => { customizeSettings(); }}>
						Customize
						<span className="small-screen-span">
						&nbsp;Settings
						</span>
					</Button>
				</S.ButtonContainer>
			</Padding>
		</S.CookieBar>
	);
};


/**
 * Configuration
 */

CookieBar.displayName = 'CookieBar';
CookieBar.propTypes = {};
CookieBar.defaultProps = {};
