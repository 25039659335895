/*
 * File: Resources.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 10:49 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card, Padding } from '../../../components';

// Constants
import { mediumBreakpoint, mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled(Padding)`
	position: relative;
	width: 100%;
	flex-direction: column;
	padding-top: 40px;
	padding-bottom: 40px;
`;

export const Decoration = styled.div`
	position: absolute;
	z-index: 1;
	width: 100%;
	min-height: 70vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/green-bump-decoration.png');
	background-size: cover;
	background-position: 50% 0%;
	background-repeat: no-repeat;
`;

export const TitleWidget = styled.div`
	width: 100%;
	min-height: 62px;
	height: auto;
	border-radius: 20px;
	border: 2px solid ${({ theme }) => theme.brandSecondaryBaseDarker};
	display: flex;
	align-items: center;
	z-index: 2;
	flex-shrink: 0;
	flex-direction: column;
	padding: 15px;
	gap: 10px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
		padding: 0px;
		border-radius: 100px;
		height: 62px;
	}
	h4 {
		color: ${({ theme }) => theme.brandSecondaryBaseDarker};
		text-align: left;
		margin-left: 30px;
		flex-grow: 1;
	}
	button {
		height: 62px;
		right: 0px;
		position: relative;

		@media only screen and (min-width: ${mediumBreakpoint}em) {
			right: -2px;
		}
	}
`;

export const ContentContainer = styled.div`
	height: 100%; // Centers loader
	text-align: left;
	z-index: 2;
	margin-bottom: 100px;
`;

export const Header = styled.div`
	margin: 30px 0px 0px;
	width: 100%;
	max-width: 100%;

	* {
		text-align: left;
		line-height: 1.6;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		max-width: 75%;
	}
`;

export const ResourcesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 30px 0px 30px 0px;
`;

export const ResourcesModule = styled.div`
	* {
		text-align: left;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin-left: 10px;
	}
`;

export const SectionCard = styled(Card)`
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin: 8px 0px 0px;
`;

export const Resource = styled.div`
	display: flex;
	align-items: center;

	span {
		font-size: 1.1rem;
		line-height: 1.5;
	}
	a,
	a:visited,
	a:link {
		text-decoration: none;
	}
	svg {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 1.0rem;
		margin-right: 10px;
	}
`;

export const FullSize = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const ChildCheckInContainer = styled.div`
	text-align: center;
	max-width: 700px;

	p {
		margin-top: 0.6rem;
		font-size: 0.9rem;
		color: ${({ theme }) => theme.secondaryText};
		max-width: 500px;
	}
	svg {
		align-self: center;
		margin-bottom: 1.8rem;
		color: ${({ theme }) => theme.placeholderHighlight};
	}
`;

export const ParentPromptContainer = styled.div`
	text-align: center;
	max-width: 700px;
	margin: 50px auto 0px;

	p {
		margin: 0.6rem auto 0px;
		font-size: 0.9rem;
		color: ${({ theme }) => theme.secondaryText};
		max-width: 500px;
	}
	svg {
		align-self: center;
		margin-bottom: 1.8rem;
		color: ${({ theme }) => theme.placeholderHighlight};
	}
`;

export const ResourceFavorite = styled.div`
	display: inline-flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;

	svg {
		position: relative;
		font-size: 1.1rem;
		top: 2px;
	}
	svg.active {
		color: ${({ theme }) => theme.brandTertiaryBase};
	}
`;
