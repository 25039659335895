/*
 * File: constants.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 10, 2022 at 11:13 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 17, 2024 at 11:25 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Breakpoints
 */

// Max Width
export const smallBreakpoint = 25; // 25em
export const mediumBreakpoint = 45; // 45em
export const largeBreakpoint = 60; // 60em

// Min Width
export const mobileBreakpoint = 62; // 62em


/**
 * Dimensions
 */

export const AppNavSidebarWidth = 250; // 260px
export const ContentNavSidebarWidth = 250; // 260px
export const AppNavTopbarHeight = 68; // 68px
export const ContentNavTopbarHeight = 68; // 68px
export const LegalNavSidebarWidth = 250; // 300px
export const WaitlistHeight = 450; // 500px
export const WaitlistPaddingOffset = 0; // 0px


/**
 * Delays
 */

export const navigationDropdownDelay = 300;
