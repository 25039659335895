/*
 * File: CreateAccountStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: August 3, 2024 at 11:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastError } from '../../../../utilities/toaster';

// Services
import { createChildAccount } from '../../../../services/user';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, PasswordInput, TextInput, Typography
} from '../../../../components';

// Styles
import * as S from './CreateAccountStep.styles';


/**
 * Component
 */

export const CreateAccountStep = forwardRef(({
	updateStep, className, childId, setAuthenticationParams
}, ref) => {

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [usernameError, setUsernameError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [passwordRulesError, setPasswordRulesError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get action from hooks
	const dispatch = useDispatch();

	// Handle next function
	const handleNext = async () => {

		// Get parameters
		const {
			name, username, password, confirmPassword
		} = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your child\'s name' });
			return;
		}
		if (!username || validator.isEmpty(username, { ignore_whitespace: true })) {
			setUsernameError({ message: 'Crease a username to share with your child' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true }) || passwordError || passwordRulesError) {
			setPasswordError({ message: passwordError?.message ? passwordError?.message : 'Please create a password for your child\'s Let\'s Talk account' });
			return;
		}
		if (!confirmPassword || validator.isEmpty(confirmPassword, { ignore_whitespace: true }) || password !== confirmPassword) {
			setConfirmPasswordError({ message: 'Your passwords must match' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Create child account
		createChildAccount({
			childId, name, username, pass: password
		}).then(({ data }) => {

			// Set loading state
			setIsLoading(false);

			// Dispatch new user
			dispatch(updateUser(data.user));

			// Set authentication parameters
			if (setAuthenticationParams) setAuthenticationParams({ username, password });

			// Move to next step
			updateStep(3);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setUsernameError(null);
			setPasswordError(null);
			setConfirmPasswordError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble creating your child\'s account. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'user']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h3" weight="bold">Create an account for your child</Typography>

				{/* Inputs */}
				<TextInput
					name="name"
					type="text"
					label="Enter your child's name"
					placeholder="e.g. Han Solo"
					error={nameError}
					value={inputValues.name || ''}
					containerClassName="inputSpacer"
					autoComplete="name"
					onFocus={() => { setNameError(null); }}
					onKeyUp={() => { setNameError(null); }}
					onBlur={() => { setNameError(null); }}
					onChange={handleOnChange}
				/>
				<TextInput
					name="username"
					type="text"
					label="Crease a username to share with your child"
					placeholder="e.g. lukeskywalker1"
					error={usernameError}
					value={inputValues.username || ''}
					containerClassName="inputSpacer"
					autoComplete="username"
					onFocus={() => { setUsernameError(null); }}
					onKeyUp={() => { setUsernameError(null); }}
					onBlur={() => { setUsernameError(null); }}
					onChange={handleOnChange}
				/>
				<PasswordInput
					label="Create a password to share with your child"
					onChange={handleOnChange}
					setError={setPasswordError}
					setRulesError={setPasswordRulesError}
					autoComplete="new-password"
					containerClassName="inputSpacer"
					rulesError={passwordRulesError}
					value={inputValues.password || ''}
					disallowedContent={[
						inputValues.username,
					].filter(Boolean)}
					error={passwordError}
				/>
				<TextInput
					label="Confirm your child's password"
					type="password"
					name="confirmPassword"
					error={confirmPasswordError}
					value={inputValues.confirmPassword || ''}
					containerClassName="inputSpacer"
					autoComplete="new-password"
					onFocus={() => { setConfirmPasswordError(null); }}
					onKeyUp={() => { setConfirmPasswordError(null); }}
					onBlur={() => { setConfirmPasswordError(null); }}
					onChange={handleOnChange}
				/>

				{/* Next Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">Next</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

CreateAccountStep.displayName = 'CreateAccountStep';
CreateAccountStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	childId: PropTypes.string,
	setAuthenticationParams: PropTypes.func
};
CreateAccountStep.defaultProps = {
	className: null,
	childId: null,
	setAuthenticationParams: null
};
