/*
 * File: SuggestResourceModal.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 9:33 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useState, useRef, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validator from 'validator';
import { useSelector } from 'react-redux';
import { Howl } from 'howler';

// Utilities
import { toastError } from '../../utilities/toaster';

// Services
import { submitResourceSuggestion } from '../../services/content';

// Components
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Typography } from '../Typography';
import { TextArea } from '../TextArea';
import { ConfettiBurst } from '../ConfettiBurst';

// Styles
import * as S from './SuggestResourceModal.styles';


/**
 * Component
 */

export const SuggestResourceModal = ({
	className, isOpen, handleClose
}) => {

	// Create state handlers
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [suggestionError, setSuggestionError] = useState(null);
	const [inputValues, setInputValues] = useState({});
	const [showConfirmation, setShowConfirmation] = useState(false);

	// Create refs
	const confettiTargetRef = useRef();

	// Create sound hooks
	const fanfareSound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/fanfare.mp3`],
		html5: true,
		volume: 0.1
	}), []);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle submit action
	const handleSubmit = async () => {

		// Get parameters
		const { suggestion } = inputValues;

		// Validate parameters
		if (!suggestion || validator.isEmpty(suggestion, { ignore_whitespace: true })) {
			setSuggestionError({ message: 'Please enter a resource you\'d like to see from our team' });
			return;
		}

		// Update submission state
		setIsSubmitting(true);

		// Submit suggestion
		try {

			// Submit suggestion
			await submitResourceSuggestion({ suggestion });

			// Update submission state
			setIsSubmitting(false);

			// Show confirmation
			setShowConfirmation(true);

			// Fire confetti
			setFireConfetti(true);

			// Reset confetti
			setTimeout(() => {
				setFireConfetti(false);
			}, 4000);

		} catch (e) {

			// Update submission state
			setIsSubmitting(false);

			// Show error message
			toastError(uiMode, 'Whoops. We\'re having trouble submitting your suggestion. Give it another go.');
		}
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on component load
	useEffect(() => {
		if (isOpen) {

			// Reset state
			setShowConfirmation(false);
			setInputValues({});
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} showClose variant="large" clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Submission Panel */}
				{!showConfirmation && (
					<S.SubmissionPanel>

						{/* Icon */}
						<S.IconContainer><FontAwesomeIcon icon={['fas', 'books']} /></S.IconContainer>

						{/* Content */}
						<Typography tag="h3" weight="bold">Suggest a Resource</Typography>
						<Typography tag="p" variation="1" weight="light" className="subtitle">Submit a suggestion for a resource you&apos;d like to see. Our team of experts will consider each suggestion.</Typography>

						{/* Text Area */}
						<TextArea
							placeholder="What's on your mind?"
							name="suggestion"
							type="text"
							rows={7}
							error={suggestionError}
							value={inputValues.suggestion || ''}
							containerClassName="formInput"
							onFocus={() => { setSuggestionError(null); }}
							onKeyUp={() => { setSuggestionError(null); }}
							onBlur={() => { setSuggestionError(null); }}
							onChange={handleOnChange}
						/>

						{/* Actions */}
						<S.ActionContainer>
							<Button disabled={isSubmitting} isLoading={isSubmitting} variant="solid" size="medium" onClick={handleSubmit}>
								<Typography tag="p" variant="2" weight="medium">Submit</Typography>
							</Button>
							<S.TextAreaPadding />
						</S.ActionContainer>

					</S.SubmissionPanel>
				)}

				{/* Confirmation Panel */}
				{showConfirmation && (
					<S.ConfirmationPanel>

						{/* Icon */}
						<S.ConfirmationIconContainer>
							<FontAwesomeIcon icon={['fal', 'check-circle']} />
							<S.ConfettiTarget ref={confettiTargetRef} />
						</S.ConfirmationIconContainer>

						{/* Title */}
						<Typography tag="h3" weight="bold">Thanks for your resource suggestion!</Typography>

						{/* Instructions */}
						<Typography tag="p" variation="1" weight="medium">
							Your resource suggestion has been submitted. Suggestions like yours will help us create a better app experience.
						</Typography>

						{/* Next Button */}
						<Button onClick={handleClose} size="large">Complete</Button>

					</S.ConfirmationPanel>
				)}

				{/* Confetti */}
				{fireConfetti && (
					<ConfettiBurst
						fireAway={fireConfetti}
						targetRef={confettiTargetRef}
						force={0.4}
						duration={3000}
						particleCount={80}
						audioHook={fanfareSound}
					/>
				)}

			</S.ModalInner>
			{showConfirmation && <S.ConfirmationBackground />}
		</Modal>
	);
};


/**
 * Configuration
 */

SuggestResourceModal.displayName = 'SuggestResourceModal';
SuggestResourceModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func
};
SuggestResourceModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null
};
