/*
 * File: UserRouteWrapper.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 29, 2022 at 11:04 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 29, 2024 at 12:48 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import {
	Spinner
} from '../components';


/**
 * Route Wrapper
 */

export const UserRouteWrapper = ({
	children, roles, user, locale
}) => {

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	const userStatus = useSelector((state) => state.user.status);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Check if user has required role
	const userHasRequiredRole = userObj?.role && userObj?.role?.primary && roles.includes(userObj?.role?.primary);

	// Render loading state
	if (userStatus !== 'succeeded') {
		return <Spinner showMeta />;
	}

	// Check user state
	if (!userObj) {

		// Redirect to login
		return <Navigate to={`${localeObj.localePath}/login`} />;
	}
	if (userObj.isAnonymous === true) {

		// Redirect to login
		return <Navigate to={`${localeObj.localePath}/login`} />;
	}
	if (userObj.isActive !== true) {

		// Redirect to logout
		window.location = '/logout';
		return null;
	}
	if (!userHasRequiredRole) {

		// Redirect to logout
		window.location = '/logout';
		return null;
	}
	if (userObj.onboardingComplete !== true) {

		// Redirect to onboarding
		return <Navigate to={`${localeObj.localePath}/join/onboarding`} />;
	}

	// Return wrapper children
	return children;
};


/**
 * Configuration
 */

UserRouteWrapper.propTypes = {
	children: PropTypes.element,
	roles: PropTypes.arrayOf(PropTypes.string),
	user: PropTypes.shape(),
	locale: PropTypes.shape()
};
UserRouteWrapper.defaultProps = {
	children: null,
	roles: [],
	user: null,
	locale: null
};
