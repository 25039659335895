/*
 * File: useSize.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on June 9, 2024 at 8:55 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: June 9, 2024 at 8:57 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import {
	useLayoutEffect, useState
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';


/**
 * Hook
 */

export default (target) => {

	// Create state handlers
	const [size, setSize] = useState();

	// Listen for layout effects
	useLayoutEffect(() => {

		// Update size
		setSize(target.current.getBoundingClientRect());

	}, [target]);

	// Where the magic happens
	useResizeObserver(target, (entry) => setSize(entry.contentRect));

	// Return size
	return size;
};
