/*
 * File: AskQuestionModal.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 22, 2024 at 12:59 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 0px 0px 50px;
	text-align: center;
	width: 100%;
	z-index: 2;

	.subtitle {
		margin: 5px 0 35px;
		padding: 0 20px;
	}
`;

export const IconContainer = styled.div`
	width: 50px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 5px;
	display: inline-flex;
	margin: 0px 0px 15px 0px;

	svg {
		color: ${({ theme }) => theme.primaryText};
		font-size: 1.15rem;
	}
`;

export const ActionContainer = styled.div`
	width: 100%;
	position: relative;
	padding: 0px 2px;

	button {
		width: 150px;
		position: absolute;
		right: 10px;
		bottom: 10px;
		z-index: 2;
	}
`;

export const TextAreaPadding = styled.div`
	height: 70px;
	background-color: ${({ theme }) => theme.primaryBackground};
	position: absolute;
	right: 2px;
	left: 2px;
	bottom: 2px;
	z-index: 1;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`;

export const SubmissionPanel = styled.div`
	padding: 0px;

	textarea {
		resize: none;
		scroll-padding: 70px;
		padding-bottom: 70px;
	}
`;

export const ConfirmationPanel = styled.div`
	padding: 60px 0px 40px;
	z-index: 2;

	* {
		text-align: center;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 10px auto 0px;
		max-width: 500px;
		width: 100%;

	}
	button {
		margin: 30px auto 0px;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 25px;
	left: 25px;
`;

export const ConfirmationIconContainer = styled.div`
	text-align: center;
	color: ${({ theme }) => theme.brandTertiaryBase};
	margin-bottom: 15px;
	position: relative;
	display: inline-flex;

	svg {
		font-size: 3.4rem;
	}
`;

export const ConfirmationBackground = styled.div`
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/white-gold-stars-decoration.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
`;
