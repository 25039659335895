/*
 * File: CopyrightFooter.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:59 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const CopyrightFooter = styled.div`
	position: relative;
	padding: 25px 0px 30px;
	width: 100%;
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.layoutBorder};

	& > a {
		display: flex;
		align-items: center;
	}
	* {
		text-align: left;
	}
	span {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.85rem;
		font-weight: 300;
	}
`;

export const LogoMark = styled.img`
	height: 24px;
	margin-right: 8px;
`;
