/*
 * File: ContentNavigation.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 30, 2024 at 10:13 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../Padding';

// Constants
import { AppNavTopbarHeight } from '../../styles/constants';


/**
 * Styles
 */

export const Navigation = styled.div`
	display: flex;
	height: auto !important;
	min-height: 100%;
	flex-direction: column;
	margin-top: ${AppNavTopbarHeight}px;
`;

export const Content = styled.div`
	flex: 1;
  	display: flex;
	overflow: auto;
	overflow-x: hidden;
	padding-top: 0px;
	flex-direction: column;
	flex-grow: 1;
`;

export const ContentPadding = styled(Padding)`
	display: flex;
	flex-grow: 1;
`;
