/*
 * File: FunFactWidget.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 1:50 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card } from '../Card';
import { Button } from '../Button';

// Styles
import { ColorValues } from '../../styles/colors';

// Constants
import { AppNavSidebarWidth, AppNavTopbarHeight, mobileBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	.adjustedModal {
		left: 0px;
		top: ${(AppNavTopbarHeight - 59.0) / 2.0}px;

		@media only screen and (min-width: ${mobileBreakpoint}em) {
			left: ${AppNavSidebarWidth / 2.0}px;
			top: ${AppNavTopbarHeight / 2.0}px;
		}
	}
`;

export const DesktopCard = styled(Card)`
	padding: 25px 20px 25px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 175px;
	cursor: pointer;

	p {
		color: ${({ theme }) => theme.secondaryText};
	}
	svg {
		color: ${({ theme }) => theme.brandTertiaryBase};
		font-size: 2.8rem;
	}
`;

export const DesktopButton = styled(Button)`
	box-shadow: ${ColorValues.elementShadow};
	width: 175px;
	border-radius: 5px;
	padding-left: 0px;
	padding-right: 0px;
`;

export const MobileCard = styled.div`
	width: 50px;
	height: 50px;
	background-color: ${({ theme }) => theme.primaryBackground};
	border-radius: 50%;
	box-shadow: ${ColorValues.elementShadow};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	svg {
		color: ${({ theme }) => theme.brandTertiaryBase};
		font-size: 1.6rem;
	}
`;

export const PromptContent = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.8rem;

		* {
			font-size: 0.8rem;
		}
	}
`;
