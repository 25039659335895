/*
 * File: color.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on May 30, 2024 at 11:45 AM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: September 27, 2024 at 10:22 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const hexToRgb = (hex) => {
	let hexValue = hex;

	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hexValue = hexValue.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexValue);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;

};
