/*
 * File: ContentNavigation.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:29 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 17, 2024 at 11:27 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Topbar } from './Topbar';
import { CopyrightFooter } from '../CopyrightFooter';

// Styles
import * as S from './ContentNavigation.styles';


/**
 * Component
 */

export const ContentNavigation = ({
	children
}) => (
	<S.Navigation>

		{/* Top Bar */}
		<Topbar />

		{/* Content */}
		<S.Content id="scroller">

			{/* Content */}
			<S.ContentPadding>
				{children}
			</S.ContentPadding>

			{/* Footer */}
			<CopyrightFooter />

		</S.Content>
	</S.Navigation>
);


/**
 * Configuration
 */

ContentNavigation.displayName = 'ContentNavigation';
ContentNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
ContentNavigation.defaultProps = {
	children: null
};
