/*
 * File: LocaleLink.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 9:36 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';


/**
 * Styles
 */

export const LocaleLink = styled(ReactLink)`
	display: inline;
	color: inherit;
	text-transform: inherit;
	font-weight: inherit;
	font-family: inherit;
`;

export const BaseLink = styled.a`
	display: inline;
	color: inherit;
	text-transform: inherit;
	font-weight: inherit;
	font-family: inherit;
`;
