/*
 * File: RewardsProgressWidget.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 2:05 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	text-align: center;
	width: 100%;

	p {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const StatusContent = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.8rem;

		* {
			font-size: 0.8rem;
		}
		span {
			color: ${({ theme }) => theme.brandPrimaryBase};
		}
	}
	svg {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 1.4rem;
		margin-bottom: 5px;
	}
`;

export const CompletedContent = styled.div`
	p {
		font-size: 0.8rem;
		color: ${({ theme }) => theme.secondaryText};

		* {
			font-size: 0.8rem;
		}
	}
	svg {
		font-size: 1.4rem;
		margin-bottom: 5px;
		color: ${({ theme }) => theme.brandTertiaryBase};
	}
`;

export const RewardsBalanceContainer = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0px auto 20px;
	position: relative;
	width: 80px;
`;

export const RewardsBalance = styled.div`
	padding: 3px;
`;

export const RewardsInner = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	span {
		font-size: 1.4rem;
		color: ${({ theme }) => theme.secondaryText};
		margin-bottom: -3px;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 25px;
	left: 25px;
`;
