/*
 * File: Card.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:54 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 21, 2024 at 1:59 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Colors
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const Card = styled.div`
	background-color: ${({ theme }) => theme.cardBackground};
	border-radius: 5px;
	box-shadow: ${ColorValues.elementShadow};
`;
