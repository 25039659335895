/*
 * File: ResetPassword.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:24 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button, Padding } from '../../../components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
	text-align: center;

	.uiModeWidget {
		position: absolute;
		z-index: 2;
		top: 10px;
		right: 10px;
	}
`;

export const Logo = styled.img`
	width: 80px;
	margin: 40px auto;
	text-align: center;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		width: 100px;
		margin: 60px auto;
	}
`;

export const Form = styled.form`
	width: 100%;
	max-width: ${({ $maxWidth }) => $maxWidth}px;
	margin: 0px auto;

	@media only screen {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	.cardPadding {
		padding: 50px;
	}
	h2 {
		text-align: center;
		margin: 10px 0px 10px;
	}
	p {
		text-align: center;
   		margin-bottom: 40px;
		color: ${({ theme }) => theme.secondaryText};
	}
	& > p {
		margin-bottom: 40px;
	}
	button {
		width: 100%;
	}
`;

export const ForgotPasswordContainer = styled.div`
	margin: 7px 0px 2px;
    text-align: right;
    width: 100%;
`;

export const ForgotPasswordButton = styled(Button)`
	width: auto !important;
	display: inline-block;
	padding: 0px;
`;

export const BottomActionContainer = styled.div`
	margin: 14px 0px 2px;
    text-align: center;
    width: 100%;

	button {
		padding: 0px;
		display: inline-block;
		width: auto;
	}
	p {
		margin: 0px;
		display: inline-block;

		span {
			font-size: inherit !important;
			color: ${({ theme }) => theme.brandPrimaryBase};
		}
	}
`;

export const Decoration = styled.div`
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;
	left: 0px;
	bottom: 0px;
	background-image: url('${process.env.CDN_URL}/public/assets/decorations/green-wave-decoration.png');
	background-size: cover;
	background-position: 50% 40vh;
	background-repeat: no-repeat;
`;

export const FormContainer = styled(Padding)`
	z-index: 2;
	position: relative;
`;

export const ActionButton = styled(Button)`
	margin: 30px auto 0px;
`;
