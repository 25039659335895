/*
 * File: ProfileNameStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 3:55 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastError } from '../../../../utilities/toaster';

// Services
import { updateUserProfile } from '../../../../services/user';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, TextInput, Typography,
	Video
} from '../../../../components';

// Constants
import { ROLES } from '../../../../../Constants';

// Styles
import * as S from './ProfileNameStep.styles';


/**
 * Component
 */

export const ProfileNameStep = forwardRef(({
	updateStep, updateHeight, role, isVisible, className
}, ref) => {

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get action from hooks
	const dispatch = useDispatch();

	// Handle next function
	const handleNext = async () => {

		// Get parameters
		const { name } = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name to continue' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserProfile({
			name
		}).then(async ({ data: { user: newUser } }) => {

			// Update user state
			dispatch(updateUser(newUser));

			// Update step
			updateStep(2);

			// Set loading state
			setIsLoading(false);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setNameError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Perform actions on visibility change
	useEffect(() => {
		if (isVisible === true) {

			// Update height
			updateHeight(true);
		}
	}, [isVisible]);

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'hand-wave']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h2" weight="bold">Welcome to Let&apos;s Talk!</Typography>

				{/* Video Container */}
				{role === ROLES.CHILD && (
					<S.VideoContainer>
						<Video url={`${process.env.CDN_URL}/public/assets/video/lets-talk-intro-video.mp4`} className="video" />
					</S.VideoContainer>
				)}

				{/* Instructions */}
				<Typography tag="p" variation="1" weight="medium">To start, what is your name?</Typography>

				{/* Inputs */}
				<TextInput
					name="name"
					type="text"
					placeholder="e.g. Han Solo"
					error={nameError}
					value={inputValues.name || ''}
					containerClassName="inputSpacer"
					autoComplete="email"
					onFocus={() => { setNameError(null); }}
					onKeyUp={() => { setNameError(null); }}
					onBlur={() => { setNameError(null); }}
					onChange={handleOnChange}
				/>

				{/* Next Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">Next</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

ProfileNameStep.displayName = 'ProfileNameStep';
ProfileNameStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	updateHeight: PropTypes.func,
	isVisible: PropTypes.bool,
	role: PropTypes.string
};
ProfileNameStep.defaultProps = {
	className: null,
	updateHeight: null,
	isVisible: false,
	role: null
};
