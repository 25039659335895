/*
 * File: LoadingModal.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 30px 40px 70px;
	width: 100%;

	h4 {
		text-align: center;
		margin: -10px 0px 0px 0px;
		color: ${({ theme }) => theme.primaryText};
	}
`;
