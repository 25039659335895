/*
 * File: CookiePolicy.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 29, 2024 at 10:35 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { smallBreakpoint } from '../../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
    max-width: 700px;
    overflow-wrap: break-word;
    box-sizing: border-box;
	margin: 0 auto;
`;

export const TableOverflow = styled.div`
	overflow-x: scroll;
`;

export const SettingsTable = styled.table`
	border-collapse: collapse;
	border-spacing: 0;
	display: table;
	box-sizing: border-box;
	margin: 1rem 0 1.875rem;
	border-radius: 10px;

	@media only screen {
		font-size: 0.775rem;

		td,
		th {
			padding: 1.25rem;
		}
	}

	@media only screen and (min-width: ${smallBreakpoint}em) {
		font-size: 1em;

		td,
		th {
			padding: 1.875rem;
		}
	}
	thead {
		background-color: ${({ theme }) => theme.secondaryBackground};
		display: table-header-group;
		vertical-align: middle;
		border-color: inherit;
	}
	tbody {
		display: table-row-group;
		vertical-align: middle;
		border-color: inherit;
		background-color: ${({ theme }) => theme.primaryBackground} !important;

		tr {
			background-color: ${({ theme }) => theme.primaryBackground} !important;
		}
	}
	tr:first-child {
		border-top: 0.0625em solid ${({ theme }) => theme.layoutBorder};
	}
	tr {
		border-bottom: 0.0625em solid ${({ theme }) => theme.layoutBorder} !important;
	}
	tr {
		display: table-row;
		vertical-align: inherit;
		border-color: inherit;
	}
	td:first-child,
	th:first-child {
		border-left: 0.0625em solid ${({ theme }) => theme.layoutBorder};
	}
	td,
	th {
		border-right: 0.0625em solid ${({ theme }) => theme.layoutBorder};
	}
	td:nth-child(2) {
		white-space: nowrap;
	}
	th {
		text-align: center;
		font-weight: 700;
	}
`;

export const CookieSettingsContainer = styled.div`
	margin-top: 20px;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	padding: 1.975rem;

	h2 {
		padding-bottom: 5px;
	}
`;

export const CookiesSettingsRow = styled.div`
	margin-top: 30px;
	display: flex;
	align-items: center;

	h3 {
		margin-bottom: 4px;
		font-size: 1.2rem !important;
	}
	p {
		margin: 0px;
		font-size: 0.9rem;
		line-height: 1.6;
	}
	.flexRight {
		width: 250px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-left: 10px;
	}
	.flexLeft {
		flex-grow: 1;
	}
`;
