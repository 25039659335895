/*
 * File: Activity.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 14, 2024 at 8:38 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useMemo, useRef, useState
} from 'react';
import validator from 'validator';
import { Howl } from 'howler';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import ReactDOMServer from 'react-dom/server';
import {
	render, MARK_LINK, NODE_EMOJI, NODE_PARAGRAPH
} from 'storyblok-rich-text-react-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	DndContext,
	useDroppable,
	useDraggable,

	/* useSensor,
	useSensors,
	MouseSensor,
	TouchSensor,
	KeyboardSensor */
} from '@dnd-kit/core';
import { useMediaQuery } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';
import { replaceHTMLMarkup, shuffleArray } from '../../../../utilities/utilities';
import { isRichTextEmpty } from '../../../utilities/storyblok';

// Services
import {
	fetchActivityModule, beginActivityModule, completeActivityModule, updateActivityModuleProgress
} from '../../../services/activities';

// Store
import { updateUser } from '../../../store/slices/user/user.slice';

// Components
import {
	Meta,
	AppNavigation,
	ErrorComponent,
	Spinner,
	LocaleLink,
	Emoji,
	Typography,
	Video,
	Button,
	ConfettiBurst,
	TextArea,
	TextInput,
	RadioGroup,
	Checkbox,
	ButtonFeedbackWidget,
} from '../../../components';

// Constants
import { ACTIVITY_MODULE_STATUSES, ROLES } from '../../../../Constants';
import { mobileBreakpoint } from '../../../styles/constants';

// Styles
import * as S from './Activity.styles';
import 'react-medium-image-zoom/dist/styles.css';

// Dynamic
const Zoom = React.lazy(() => import('react-medium-image-zoom'));


/**
 * Constants
 */

// const BG_AUDIO_VOLUME = 0.1;


/**
 * Configuration
 */

storyblokInit({
	accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
	apiOptions: {
		region: 'us'
	},
	use: [apiPlugin]
});

const richTextConfiguration = ({ setVideoPlaying }) => ({
	markResolvers: {
		[MARK_LINK]: (children, { href, ...rest }) => <LocaleLink {...rest} to={href} target="_blank">{children}</LocaleLink>
	},
	nodeResolvers: {
		[NODE_EMOJI]: (children, { name, emoji }) => <Emoji label={name} symbol={emoji} size={1.2} />,
		[NODE_PARAGRAPH]: (children) => <Typography tag="p">{children}</Typography>
	},
	blokResolvers: {
		video: ({ /* eslint-disable-line react/prop-types */ video }) => {
			const { filename } = video;
			return (
				<Video url={filename} onPlaying={(isPlaying) => setVideoPlaying(isPlaying)} />
			);
		}
	}
});


/**
 * Helper Components
 */

const Droppable = ({ id, children }) => {

	// Use hooks
	const { setNodeRef } = useDroppable({ id });

	// Render component
	return (
		<div ref={setNodeRef}>
			{children}
		</div>
	);
};

Droppable.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const Draggable = ({ id, children }) => {

	// Use hooks
	const {
		attributes, listeners, setNodeRef, transform
	} = useDraggable({ id });

	// Define styles
	const style = transform ? {
		transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
	} : undefined;

	// Render component
	return (
		<S.DNDButton ref={setNodeRef} style={style} {...listeners} {...attributes} type="button">
			{children}
		</S.DNDButton>
	);
};

Draggable.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};


/**
 * Component
 */

const Activity = ({ meta, locale, data }) => {

	// Get component parameters
	const { slug } = useParams();

	// Create sound hooks
	const correctSound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/correct.mp3`],
		html5: true,
		volume: 0.2
	}), []);
	const fanfareSound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/fanfare.mp3`],
		html5: true,
		volume: 0.1
	}), []);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Set state
	const [componentData, setComponentData] = useState(null);
	const [pageStatus, setPageStatus] = useState('idle');
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	const [stepTransition, startStepTransition] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [errors, setError] = useState({});
	const [showBackground, setShowBackground] = useState(false);
	const [orderedDefinitions, setOrderedDefinitions] = useState([]);
	const [isFeedbackVisible, toggleFeedbackVisible] = useState(false);
	const [continueAction, setContinueAction] = useState(null);
	const [stepResponses, setStepResponses] = useState(null);
	const [isVideoPlaying, setVideoPlaying] = useState(false);

	// const [backgroundAudioVolume, setBackgroundAudioVolume] = useState(BG_AUDIO_VOLUME);
	// const [muteAudio, setMuteAudio] = useState(false);

	// Create drag and drop sensors
	/* const mouseSensor = useSensor(MouseSensor);
	const touchSensor = useSensor(TouchSensor);
	const keyboardSensor = useSensor(KeyboardSensor);
	const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor); */

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get actions from hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Check if mobile screen size
	const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}em)`);

	// Create references for components
	const isMounted = useRef(true);
	const confettiTargetRef = useRef();

	// const audioPlayerRef = useRef();

	// Check if should show child perspective
	const showChildPerspective = false; /* useMemo(() => {

		// Get current module
		const currentModule = componentData?.module;
		const currentStep = currentModule?.content?.[currentStepIndex];

		// Check if contains child activities
		const containsChildActivities = (currentModule?.roles || []).includes(ROLES.CHILD);

		// Return state
		return user?.role?.primary === ROLES.PARENT
			&& containsChildActivities
			&& !currentStep?.content?.show_for_parents_only
			&& !currentStep?.content?.show_for_child_only;

	}, [currentStepIndex, user]); */

	// Check if should show parent perspective
	const showParentPerspective = useMemo(() => {

		// Get current module
		const currentModule = componentData?.module;
		const currentStep = currentModule?.content?.[currentStepIndex];

		// Return state
		return user?.role?.primary === ROLES.PARENT
			&& currentStep?.content?.show_for_parents_only;

	}, [currentStepIndex, user]);

	// Handle begin activity
	const beginActivity = async () => {
		try {

			// Get current module
			const currentModule = componentData.module.content[0];

			// Begin activity
			const { data: datum } = await beginActivityModule({ slug, stepId: `${currentModule.id}` });

			// Dispatch new user
			dispatch(updateUser(datum.user));

		} catch (e) { }
	};

	// Handle next action
	const handleNext = async () => {
		try {

			// Set loading state
			setIsLoading(true);

			// Get current module
			const currentModule = componentData.module.content[currentStepIndex];

			// Handle action
			if (currentStepIndex === 0) {

				// Begin activity
				await beginActivity();

			} else if (currentStepIndex === componentData.module.content.length) {

				// Complete activity
				const { data: datum } = await completeActivityModule({ slug });

				// Dispatch new user
				dispatch(updateUser(datum.user));

			} else {

				// Get required inputs
				const requiredInputs = currentModule?.content?.content?.filter(({ component }) => component === 'activity_reflection'
						|| component === 'activity_quiz'
						|| component === 'activity_quiz_all_apply'
						|| component === 'activity_term_matching'
						|| component === 'activity_journal') || [];

				// Parse input values
				const responseValues = {};
				Object.values(inputValues).forEach((inputObj) => {
					if (inputObj.stepId === `${currentModule.id}`) {
						responseValues[inputObj.questionId] = inputObj;
					}
				});

				// Validate responses for child only
				let shouldReturn = false;
				if (user.role.primary === ROLES.CHILD) {
					for (let i = 0; i < requiredInputs.length; i += 1) {
						const { component, _uid, ...rest } = requiredInputs[i];
						if (component === 'activity_journal') {

							// Get response
							const responseObj = responseValues[_uid];

							// Validate response
							if (user.role.primary === ROLES.CHILD) {
								if (!responseObj || !responseObj.response || validator.isEmpty(responseObj.response, { ignore_whitespace: true })) {
									toastError(uiMode, 'Whoops! Please create a new journal entry to continue.');
									shouldReturn = true;
									break;
								}
							}
						}
						if (component === 'activity_reflection') {

							// Get parameters
							const { is_required: isRequired } = rest;

							// Get response
							const responseObj = responseValues[_uid];

							// Validate response
							if (isRequired && (!responseObj || !responseObj.response || validator.isEmpty(responseObj.response, { ignore_whitespace: true }))) {
								setError({ ...errors, [_uid]: { message: 'Please enter a response' } });
								shouldReturn = true;
								break;
							}
						} else if (component === 'activity_quiz') {

							// Get parameters
							const { questions } = rest;

							// Validate questions
							for (let j = 0; j < questions.length; j += 1) {

								// Get response
								const questionId = questions[j]._uid;
								const responseObj = responseValues[questionId];

								// Validate response
								if (!responseObj || !responseObj?.response?.text || validator.isEmpty(responseObj?.response?.text, { ignore_whitespace: true })) {
									setError({ ...errors, [questionId]: { message: 'Please select an option' } });
									shouldReturn = true;
									break;
								}
							}
						} else if (component === 'activity_quiz_all_apply') {

							// Get parameters
							const { statements, force_response: forceResponse } = rest;

							// Validate statements
							for (let j = 0; j < statements.length; j += 1) {
								if (forceResponse) {

									// Get response
									const { options, _uid: statementId } = statements[j];
									const responses = responseValues[statementId]?.response?.values || [];

									// Validate response
									if (options && options.length > 0) {
										if (!responses || responses.length === 0 || validator.isEmpty(responses[0].text, { ignore_whitespace: true })) {
											setError({ ...errors, [statementId]: { message: 'Please select an option' } });
											shouldReturn = true;
											break;
										}
									}
								}
							}


						} else if (component === 'activity_term_matching') {

							// Get parameters
							const { terms } = rest;

							// Validate terms
							for (let j = 0; j < terms.length; j += 1) {

								// Get response
								const termId = terms[j]._uid;
								const responseObj = responseValues[termId];

								// Validate response
								if (!responseObj || !responseObj?.response?.text || validator.isEmpty(responseObj?.response?.text, { ignore_whitespace: true })) {
									setError({ ...errors, [termId]: { message: 'Please match a definition to this term' } });
									shouldReturn = true;
									break;
								}
							}
						}
					}
				}

				// Return if necessary
				if (shouldReturn) {

					// Update loading state
					setIsLoading(false);

					// Return
					return;
				}

				// Update activity progress
				const { data: datum } = await updateActivityModuleProgress({
					slug,
					stepId: `${currentModule.id}`,
					isCompleted: true,
					response: responseValues
				});

				// Set step responses
				setStepResponses(responseValues);

				// Dispatch new user
				dispatch(updateUser(datum.user));
			}

			// Handle step transition
			if (currentStepIndex === componentData.module.content.length) {

				// Build query string (NV - FOR TRIAL ONLY)
				const queryString = componentData.module.index === 17 ? '?trial=complete' : '';

				// Move back to dashboard
				navigate(`${stateLocale.localePath}/dashboard${queryString}`);

			} else if (currentStepIndex < componentData.module.content.length) {

				// Define continue action
				const action = () => {

					// Start transition
					startStepTransition(true);
					setTimeout(() => {

						// Update state
						setCurrentStepIndex(currentStepIndex + 1);
						startStepTransition(false);

						// Set loading state
						setIsLoading(false);

						// Set state to show feedback
						toggleFeedbackVisible(false);

					}, 400);

					// Fire confetti in necessary
					if (currentStepIndex === componentData.module.content.length - 1) {
						setTimeout(() => {

							// Fire confetti
							setFireConfetti(true);

							// Reset confetti
							setTimeout(() => {
								setFireConfetti(false);
							}, 4000);

						}, 500);
					}
				};

				// Check if should collect feedback
				if (currentModule?.content?.collect_feedback) {

					// Set state to show feedback
					toggleFeedbackVisible(true);

					// Set continue action
					setContinueAction(() => action);

				} else {

					// Run action
					action();
				}
			}
		} catch (e) {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (e.response && e.response?.data?.message) {
				toastError(uiMode, e.response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your activity progress. Give it another go.');
			}
		}
	};

	// Handle previous action
	const handlePrevious = async () => {

		// Go back a step
		startStepTransition(true);
		setTimeout(() => {
			setCurrentStepIndex(currentStepIndex - 1);
			startStepTransition(false);
		}, 400);
	};

	// Handle move to next module
	/* const moveToNextModule = async () => {
		navigate(`/activity/${componentData?.nextModule?.slug}`);
	}; */

	// Handle get module journey status for user
	const getModuleJourneyStatus = (dataObj, userObj) => {
		if (dataObj && dataObj.module) {
			let moduleObj = {};
			userObj.journey.forEach(({ modules }) => {
				modules.forEach((obj) => {
					if (obj.module === dataObj.module.id) {
						moduleObj = obj;
					}
				});
			});
			return moduleObj;
		}
		return {};
	};

	// Handle play audio
	/* const playAudio = async () => {
		try {
			await audioPlayerRef?.current?.audioEl?.current?.play();
		} catch (e) {}
	}; */

	// Handle play/pause audio
	/* const playPauseAudio = async () => {
s
		// Check if audio is muted
		const isMuted = muteAudio;

		// Update background music volume
		setMuteAudio(!muteAudio);
		setBackgroundAudioVolume(backgroundAudioVolume === 0 || isMuted ? BG_AUDIO_VOLUME : 0);
	}; */

	// Initialize component data function
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch activity module
			const { data: { module: moduleObj, nextModule } } = await fetchActivityModule({ slug });

			// Ensure component is mounted
			if (isMounted.current) {

				// Set component data
				setComponentData({ module: moduleObj, nextModule });

				// Get module journey status
				const journeyStatus = getModuleJourneyStatus({ module: moduleObj, nextModule }, user);

				// Set input values
				const values = {};
				journeyStatus.responses.forEach(({ stepId, response }) => {
					if (response) {
						Object.keys(response).forEach((key) => {
							values[`${stepId}.${key}`] = response[key];
						});
					}
				});

				// Set input values
				setInputValues(values);

				// Set current step
				if (journeyStatus.status === ACTIVITY_MODULE_STATUSES.COMPLETE) {
					setCurrentStepIndex(1);
				} else {
					const completedSet = [...new Set(journeyStatus.responses.map((responseObj) => (responseObj.isCompleted ? responseObj.stepId : false)).filter(Boolean))];
					if (completedSet.length === moduleObj.content.length) {
						setCurrentStepIndex(moduleObj.content.length);
					} else {
						const notCompletedStepIndex = moduleObj.content.findIndex(({ id }) => !completedSet.includes(`${id}`));
						if (notCompletedStepIndex > 0) setCurrentStepIndex(notCompletedStepIndex);
						else setCurrentStepIndex(1);
					}
				}

				// Update page status
				setPageStatus('success');
			}

		} catch (e) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle on input change action
	const handleOnChange = (event, questionDisplay) => {

		// Get event parameters
		const {
			name, value
		} = event.target;

		// Parse parameters
		const [stepId, questionId] = name.split('.');

		// Set input values
		setInputValues({
			...inputValues,
			[name]: {
				stepId,
				questionId,
				questionDisplay: replaceHTMLMarkup(questionDisplay),
				response: value
			}
		});
	};

	// Handle drag end function
	const handleDragEnd = (event, stepId, terms) => {

		// Get parameters
		const { over, active } = event;

		// Get active parameters
		const activeId = active.id.replace('-definition', '');

		// Get selected definition
		const definition = terms.find(({ _uid }) => _uid === activeId);

		// Remove from existing input values
		const updatedInputValues = {};
		Object.keys(inputValues).forEach((key) => {
			if (inputValues[key]?.response?.id !== definition._uid) {
				updatedInputValues[key] = inputValues[key];
			}
		});

		// Check if over is valid
		if (over) {

			// Get selected term
			const term = terms.find(({ _uid }) => _uid === over.id);

			// Set input values
			setInputValues({
				...updatedInputValues,
				[`${stepId}.${over.id}`]: {
					stepId: `${stepId}`,
					questionId: over.id,
					questionDisplay: term.term,
					response: { text: definition.definition, id: definition._uid }
				}
			});
		} else {

			// Set input values
			setInputValues({ ...updatedInputValues });
		}
	};

	// Handle actions on slug change
	useEffect(() => {
		if (pageStatus !== 'idle') {
			setComponentData(null);
			setCurrentStepIndex(0);
			setPageStatus('idle');
		}
	}, [slug]);

	// Handle actions on video play state change
	useEffect(() => {

		// Update background music volume
		// setBackgroundAudioVolume(isVideoPlaying ? 0 : BG_AUDIO_VOLUME);

	}, [isVideoPlaying]);

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle actions on step initialization
	useEffect(() => {

		// Begin activity on first step
		if (currentStepIndex === 1) {

			// Begin activity
			beginActivity();
		}

		// Get current module
		const currentModule = componentData?.module?.content?.[currentStepIndex];
		if (currentModule) {

			// Check if content contains term matching activity
			if (Array.isArray((currentModule?.content?.content || []))) {
				(currentModule?.content?.content || []).forEach((componentObj) => {
					if (componentObj.component === 'activity_term_matching') {
						const definitions = JSON.parse(JSON.stringify(componentObj.terms));
						shuffleArray(definitions);
						setOrderedDefinitions(definitions);
					}
				});
			}

			// Update background visibility
			setShowBackground(currentModule?.content?.show_bg_decoration || currentModule?.content?.component === 'activity_table_of_contents');

			// Update background music volume
			// setBackgroundAudioVolume(currentModule?.content?.mute_bg_audio ? 0 : BG_AUDIO_VOLUME);

		} else {

			// Update background music volume
			// setBackgroundAudioVolume(BG_AUDIO_VOLUME);

			// Update background visibility
			setShowBackground(false);
		}

		// Scroll to top of component
		window.scrollTo(0, 0);

	}, [currentStepIndex]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle play audio
		// playAudio();

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render next button
	const renderNextButton = () => {
		if (currentStepIndex === 0) return 'Start';
		if (currentStepIndex === componentData.module.content.length) return 'Complete';
		return 'Next';
	};

	// Render table of contents icon
	const renderTOCIcon = (content) => {
		if (content.some(({ component }) => component === 'activity_quiz')
			|| content.some(({ component }) => component === 'activity_quiz_all_apply')
			|| content.some(({ component }) => component === 'activity_term_matching')) {
			return ['fal', 'square-list'];
		}
		if (content.some(({ component }) => component === 'activity_journal')
			|| content.some(({ component }) => component === 'activity_reflection')) {
			return ['fal', 'pencil'];
		}
		if (content.some(({ component }) => component === 'activity_media')) {

			// Get parameters
			const { media: { filename } } = content.find(({ component }) => component === 'activity_media');

			// Get file parameters
			const dotArray = filename.split('.');
			const extension = dotArray.slice(dotArray.length - 1).join('.').toLowerCase();

			// Handle file extension
			if (extension === 'mp4') {
				return ['fal', 'video'];
			}
			return ['fal', 'image'];
		}
		return ['fal', 'book'];
	};

	// Render activity media
	const renderActivityMedia = (media) => {

		// Get parameters
		const { filename } = media;

		// Get file parameters
		const dotArray = filename.split('.');
		const extension = dotArray.slice(dotArray.length - 1).join('.').toLowerCase();

		// Handle file extension
		if (extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'gif') {
			return <Zoom wrapStyle={{ width: '100%' }}><S.Image src={filename} /></Zoom>;
		}
		if (extension === 'pdf') {
			return null;
		}
		if (extension === 'mp4') {
			return <Video url={filename} className="video" onPlaying={(isPlaying) => setVideoPlaying(isPlaying)} />;
		}
		return null;
	};

	// Render step
	const renderStep = () => {

		// Get current module
		const currentModule = componentData.module.content[currentStepIndex];

		// Render component
		switch (currentModule.content.component) {
			case 'activity_intro': {

				// Get intro content structure
				const isColumnLayout = currentModule?.content?.image?.filename;

				// Render component
				return (
					<S.ActivityIntro $isColumnLayout={isColumnLayout}>

						{/* Title */}
						<S.TitleContainer>
							<Typography tag="h3" weight="semibold">{componentData.module?.name}</Typography>
						</S.TitleContainer>

						{/* Column Container */}
						<S.ColumnContainer>

							{/* Image */}
							{isColumnLayout && <S.IntroImage src={currentModule?.content?.image?.filename} />}

							{/* Content */}
							<S.ContentContainer className="paragraphContent alterAlign">
								{render(currentModule?.content?.content, richTextConfiguration({ setVideoPlaying }))}
							</S.ContentContainer>

						</S.ColumnContainer>

						{/* Time Estimate */}
						{currentModule?.timeToComplete && (
							<S.TimeEstimateContainer>
								<Typography tag="p" variation="1" weight="semibold">Estimated time to complete:</Typography>
								<Typography tag="p" variation="1">{currentModule?.timeToComplete}</Typography>
							</S.TimeEstimateContainer>
						)}

						{/* Actions */}
						{/* <S.ActionsContainer>
							<Typography tag="p" variation="1" weight="semibold">Actions in Step</Typography>
							<S.ActionsInStep>
								{currentModule?.content?.actions_in_step.includes('video') && <FontAwesomeIcon icon={['fal', 'video']} />}
								{currentModule?.content?.actions_in_step.includes('infographic') && <FontAwesomeIcon icon={['fal', 'image']} />}
								{currentModule?.content?.actions_in_step.includes('image') && <FontAwesomeIcon icon={['fal', 'image']} />}
								{currentModule?.content?.actions_in_step.includes('quiz') && <FontAwesomeIcon icon={['fal', 'square-list']} />}
							</S.ActionsInStep>
						</S.ActionsContainer> */}

					</S.ActivityIntro>
				);
			}
			case 'activity_key_words': {

				// Get parameters
				const { image, _uid } = currentModule.content;

				// Render component
				return (
					<S.Activity>

						{/* Media */}
						<S.MediaContainer key={_uid}>
							{renderActivityMedia(image)}
						</S.MediaContainer>

						{/* Feature Prompt */}
						<S.FeaturePrompt to="/dashboard/terms" target="_blank" className="animate">
							<FontAwesomeIcon icon={['fas', 'arrow-up-left']} />
							<Typography weight="semibold">
								Looking for more key words? Click to see the full Terms List in the menu
							</Typography>
						</S.FeaturePrompt>

					</S.Activity>
				);
			}
			case 'activity_table_of_contents': {

				// Get modules to display
				const displayModules = componentData.module.content.filter(({ content: { component } }) => component === 'activity');

				// Render component
				return (
					<S.ActivityTableOfContents>

						{/* Title */}
						<Typography tag="h3" weight="bold">Coming Up in this Module...</Typography>

						{/* Table of Contents */}
						<S.TableOfContents>
							{displayModules.map((displayModule) => (
								<S.TableOfContentContainer key={displayModule.id}>
									<Typography tag="h4" weight="medium" center={false}>{displayModule.name}</Typography>
									<FontAwesomeIcon icon={renderTOCIcon(displayModule.content.content)} />
								</S.TableOfContentContainer>
							))}
						</S.TableOfContents>

					</S.ActivityTableOfContents>
				);
			}
			case 'activity': {

				// Render component
				return (
					<S.Activity>
						{currentModule?.content?.content.map(({
							component,
							_uid,
							...rest
						}) => {

							// Return individual element (question or static content)
							switch (component) {

								// Static Content
								case 'activity_content': {

									// Get parameters
									const { content, parent_specific_content: parentContent } = rest;

									// Check if parent content is empty
									const parentContentEmpty = !parentContent || !parentContent.content || parentContent.content.every((obj) => !obj.content || obj.content.length === 0);

									// Get content to display
									const displayContent = !parentContentEmpty && user?.role?.primary === ROLES.PARENT ? parentContent : content;

									// Render component
									return (
										<S.ContentContainer className="paragraphContent solidBg" key={_uid}>
											{render(displayContent, richTextConfiguration({ setVideoPlaying }))}
										</S.ContentContainer>
									);
								}

								// Media
								case 'activity_media': {

									// Get parameters
									const { media } = rest;

									// Render component
									return (
										<S.MediaContainer key={_uid}>
											{renderActivityMedia(media)}
										</S.MediaContainer>
									);
								}

								// Feature Prompt
								case 'activity_feature_prompt': {

									// Get parameters
									const { prompt, url } = rest;

									// Render component
									return (
										<S.FeaturePrompt to={url} target="_blank" className="animate">
											<FontAwesomeIcon icon={['fas', 'arrow-up-left']} />
											<Typography weight="semibold">{prompt}</Typography>
										</S.FeaturePrompt>
									);
								}

								// Embed
								case 'activity_embed': {

									// Get parameters
									const { code, caption } = rest;

									// Render component
									return (
										<S.EmbedContainer key={_uid}>
											<div
												// eslint-disable-next-line react/no-danger
												dangerouslySetInnerHTML={{ __html: code }}
											/>
											{caption && (
												<S.ContentContainer className="paragraphContent">
													{render(caption, richTextConfiguration({ setVideoPlaying }))}
												</S.ContentContainer>
											)}
										</S.EmbedContainer>
									);
								}

								// Journal Activity
								case 'activity_journal': {

									// Get parameters
									const { prompt } = rest;

									// Render component
									return (
										<S.JournalContainer key={_uid}>
											<S.ContentContainer className="paragraphContent">
												{render(prompt, richTextConfiguration({ setVideoPlaying }))}
											</S.ContentContainer>
											<S.Journal
												showEmptyState={false}
												showSubPrompt={false}
												onCompleteAction={(itemAdded) => {
													if (itemAdded) handleNext();
												}}
												onEntryCreated={(entryContent) => {
													handleOnChange({
														target: {
															name: `${currentModule.id}.${_uid}`,
															value: entryContent
														}
													}, ReactDOMServer.renderToString(render(prompt, richTextConfiguration({ setVideoPlaying }))));
												}}
												onResponseCreated={(responseContent) => {
													handleOnChange({
														target: {
															name: `${currentModule.id}.${_uid}`,
															value: responseContent
														}
													}, ReactDOMServer.renderToString(render(prompt, richTextConfiguration({ setVideoPlaying }))));
												}}
											/>
										</S.JournalContainer>
									);
								}

								// Reflection Question
								case 'activity_reflection': {

									// Get parameters
									const {
										question, parent_specific_question: parentQuestion, is_inline: isInline, placeholder
									} = rest;

									// Check if parent content is empty
									const parentContentEmpty = !parentQuestion || !parentQuestion.content || parentQuestion.content.every((obj) => !obj.content || obj.content.length === 0);

									// Get content to display
									const displayQuestion = !parentContentEmpty && user?.role?.primary === ROLES.PARENT ? parentQuestion : question;

									// Render component
									return (
										<S.ReflectionContainer key={_uid} $isInline={isInline}>
											{!isInline ? (
												<>
													<S.ContentContainer className="paragraphContent">
														{render(displayQuestion, richTextConfiguration({ setVideoPlaying }))}
													</S.ContentContainer>
													<TextArea
														placeholder={placeholder || "What's on your mind?"}
														name={`${currentModule.id}.${_uid}`}
														type="text"
														rows={9}
														value={inputValues[`${currentModule.id}.${_uid}`]?.response || ''}
														onChange={(e) => {
															handleOnChange(e, ReactDOMServer.renderToString(render(displayQuestion, richTextConfiguration({ setVideoPlaying }))));
														}}
														error={errors[_uid]}
														onFocus={() => { setError({ ...errors, [_uid]: null }); }}
														onKeyUp={() => { setError({ ...errors, [_uid]: null }); }}
														onBlur={() => { setError({ ...errors, [_uid]: null }); }}
													/>
												</>
											) : (
												<S.ContentContainer className="paragraphContent">
													{render(displayQuestion, richTextConfiguration({ setVideoPlaying }))}
													<S.Bumper />
													<TextInput
														name={`${currentModule.id}.${_uid}`}
														type="text"
														value={inputValues[`${currentModule.id}.${_uid}`]?.response || ''}
														onChange={(e) => {
															handleOnChange(e, ReactDOMServer.renderToString(render(displayQuestion, richTextConfiguration({ setVideoPlaying }))));
														}}
														error={errors[_uid]}
														onFocus={() => { setError({ ...errors, [_uid]: null }); }}
														onKeyUp={() => { setError({ ...errors, [_uid]: null }); }}
														onBlur={() => { setError({ ...errors, [_uid]: null }); }}
													/>
												</S.ContentContainer>
											)}

										</S.ReflectionContainer>
									);
								}

								// Multiple Choice Quiz
								case 'activity_quiz': {

									// Get parameters
									const { questions, should_score: shouldScore, score_schema: scoreSchema } = rest;

									// Create quiz id
									const quizId = `${currentModule.id}.${_uid}`;

									// Check if all questions answered
									const allAnswered = questions.every(({ _uid: innerUID }) => inputValues[`${currentModule.id}.${innerUID}`]?.response?.text != null);

									// Calculate score and select schema if necessary
									let selectedScoreSchema = null;
									if (shouldScore && allAnswered && scoreSchema && scoreSchema.length > 0) {

										// Calculate score
										const totalScore = questions.reduce((total, { _uid: innerUID }) => {
											const score = inputValues[`${currentModule.id}.${innerUID}`]?.response?.score || '0';
											return total + parseInt(score, 10);
										}, 0);

										// Select score schema
										selectedScoreSchema = scoreSchema.find(({ max_score: max, min_score: min }) => {
											const minValue = parseInt(min, 10);
											const maxValue = parseInt(max, 10);
											return totalScore >= minValue && totalScore <= maxValue;
										});
									}

									// Render component
									return (
										<S.QuizContainer key={_uid}>
											{questions.map(({
												answers,
												correct_answer_prompt: correctAnswerPrompt,
												incorrect_answer_prompt: incorrectAnswerPrompt,
												correct_answer_feedback: generalCorrectAnswerFeedback,
												incorrect_answer_feedback: generalIncorrectAnswerFeedback,
												question: generalQuestion,
												parent_specific_question: parentSpecificQuestion,
												parent_specific_incorrect_answer_feedback: parentSpecificIncorrectAnswerFeedback,
												parent_specific_correct_answer_feedback: parentSpecificCorrectAnswerFeedback,
												_uid: innerUID
											}) => {

												// Get content
												const question = user?.role?.primary === ROLES.PARENT && parentSpecificQuestion ? parentSpecificQuestion : generalQuestion;
												const correctAnswerFeedback = user?.role?.primary === ROLES.PARENT && parentSpecificCorrectAnswerFeedback ? parentSpecificCorrectAnswerFeedback : generalCorrectAnswerFeedback;
												const incorrectAnswerFeedback = user?.role?.primary === ROLES.PARENT && parentSpecificIncorrectAnswerFeedback ? parentSpecificIncorrectAnswerFeedback : generalIncorrectAnswerFeedback;

												// Get current answer
												const answerValue = inputValues[`${currentModule.id}.${innerUID}`]?.response?.text || '';
												const selectedAnswer = answers.find(({ answer: generalAnswer, parent_specific_answer: parentSpecificAnswer }) => {
													const answer = user?.role?.primary === ROLES.PARENT && parentSpecificAnswer ? parentSpecificAnswer : generalAnswer;
													return answer === answerValue;
												});

												// Check if all answers are correct
												const allAnswersCorrect = answers.every(({ is_correct: isCorrect }) => isCorrect === true);

												// Check if all answers are incorrect
												const allAnswersIncorrect = answers.every(({ is_correct: isCorrect }) => isCorrect === false);

												// Render question
												return (
													<S.QuizQuestion key={innerUID}>
														<S.ContentContainer className="paragraphContent">
															<Typography tag="p" weight="semibold" center={false} variation="1">{question}</Typography>
														</S.ContentContainer>
														<S.QuizAnswerContainer>
															<RadioGroup
																value={answerValue}
																isCorrect={selectedAnswer?.is_correct === true}
																onChange={(e) => {

																	// Play correct audio if necessary
																	if (answers.find(({ answer: generalAnswer, parent_specific_answer: parentSpecificAnswer }) => {
																		const answer = user?.role?.primary === ROLES.PARENT && parentSpecificAnswer ? parentSpecificAnswer : generalAnswer;
																		return answer === e;
																	})?.is_correct === true) {
																		try { correctSound.play(); } catch (e2) {}
																	}

																	// Handle change
																	handleOnChange({
																		target: {
																			name: `${currentModule.id}.${innerUID}`,
																			value: {
																				text: e,
																				score: answers.find(({ answer: generalAnswer, parent_specific_answer: parentSpecificAnswer }) => {
																					const answer = user?.role?.primary === ROLES.PARENT && parentSpecificAnswer ? parentSpecificAnswer : generalAnswer;
																					return answer === e;
																				})?.score,
																				quizId
																			}
																		}
																	}, question);

																	// Reset errors
																	setError({ ...errors, [innerUID]: null });
																}}
																family={`${currentModule.id}.${innerUID}`}
																options={answers.map(({ answer: generalAnswer, parent_specific_answer: parentSpecificAnswer }) => {
																	const answer = user?.role?.primary === ROLES.PARENT && parentSpecificAnswer ? parentSpecificAnswer : generalAnswer;
																	return {
																		value: answer,
																		label: answer,
																	};
																})}
																error={errors[innerUID]}
															/>
														</S.QuizAnswerContainer>
														<S.QuestionFeedbackContainer>
															{selectedAnswer?.is_correct === true && !isRichTextEmpty(correctAnswerFeedback) && (
																<S.QuestionFeedback>
																	{!allAnswersCorrect && (
																		<S.AnswerFeedback>
																			<FontAwesomeIcon icon={['fas', 'check-circle']} />
																			<Typography tag="span" variation="1" weight="semibold">{correctAnswerPrompt || 'Correct answer'}</Typography>
																		</S.AnswerFeedback>
																	)}
																	<S.ContentContainer className="paragraphContent">
																		{render(correctAnswerFeedback, richTextConfiguration({ setVideoPlaying }))}
																	</S.ContentContainer>
																</S.QuestionFeedback>
															)}
															{selectedAnswer?.is_correct === false && !isRichTextEmpty(incorrectAnswerFeedback) && (
																<S.QuestionFeedback>
																	{!allAnswersIncorrect && (
																		<S.AnswerFeedback>
																			<FontAwesomeIcon icon={['fas', 'xmark-circle']} />
																			<Typography tag="span" variation="1" weight="semibold">{incorrectAnswerPrompt || 'Try again!'}</Typography>
																		</S.AnswerFeedback>
																	)}
																	<S.ContentContainer className="paragraphContent">
																		{render(incorrectAnswerFeedback, richTextConfiguration({ setVideoPlaying }))}
																	</S.ContentContainer>
																</S.QuestionFeedback>
															)}
														</S.QuestionFeedbackContainer>
													</S.QuizQuestion>
												);

											})}
											{selectedScoreSchema && (
												<S.QuizScoreContainer>
													<S.ContentContainer className="paragraphContent">
														{render(selectedScoreSchema.feedback, richTextConfiguration({ setVideoPlaying }))}
													</S.ContentContainer>
												</S.QuizScoreContainer>
											)}
										</S.QuizContainer>
									);
								}

								// All Apply Quiz
								case 'activity_quiz_all_apply': {

									// Get parameters
									const { statements, single_response_only: singleResponseOnly } = rest;

									// Create quiz id
									const quizId = `${currentModule.id}.${_uid}`;

									// Render component
									return (
										<S.AllApplyQuizContainer key={_uid}>
											{statements.map(({
												feedback,
												options,
												statement,
												_uid: innerUID
											}, index) => {

												// Check if options specified
												const optionsSpecified = options != null && options.length > 0;

												// Get current answer
												const answerValues = inputValues[`${currentModule.id}.${innerUID}`]?.response?.values || [];

												// Render statement
												return (
													<S.QuizStatement key={innerUID} $showBorder={index < statements.length - 1}>
														<S.FlexRow $switchOnMobile={optionsSpecified}>
															<S.StatementContent onClick={!optionsSpecified ? () => {
																handleOnChange({
																	target: {
																		name: `${currentModule.id}.${innerUID}`,
																		value: {
																			values: answerValues.length === 0 ? [{
																				text: 'Yes',
																				quizId
																			}] : []
																		}
																	}
																}, statement);
																setError({ ...errors, [innerUID]: null });
															} : undefined}
															>
																<Typography tag="p" weight="semibold" center={false} variation="1">{statement}</Typography>
															</S.StatementContent>
															<div className="isNotMobile" style={{ flexGrow: 1 }} />
															<S.StatementOptions>
																{optionsSpecified ? (
																	// eslint-disable-next-line react/jsx-no-useless-fragment
																	<>
																		{singleResponseOnly ? (
																			<RadioGroup
																				value={answerValues.length > 0 ? answerValues[0]?.text : null}
																				variant={isMobile ? 'vertical' : 'horizontal'}
																				isCorrect
																				onChange={(e) => {
																					handleOnChange({
																						target: {
																							name: `${currentModule.id}.${innerUID}`,
																							value: {
																								values: [{
																									text: e,
																									quizId
																								}]
																							}
																						}
																					}, statement);
																					setError({ ...errors, [innerUID]: null });
																				}}
																				family={`${currentModule.id}.${innerUID}`}
																				options={options.map(({ option }) => ({
																					value: option,
																					label: option,
																				}))}
																				error={errors[innerUID]}
																			/>
																		) : options.map(({ option, _uid: optionUID }) => (
																			<S.CheckboxRow key={optionUID}>
																				<Checkbox
																					name={`${currentModule.id}.${innerUID}`}
																					value={answerValues.some(({ text }) => text === option)}
																					onChange={(e) => {
																						const newValues = answerValues.filter(({ text }) => text !== option);
																						handleOnChange({
																							target: {
																								name: `${currentModule.id}.${innerUID}`,
																								value: {
																									values: [
																										...newValues,
																										e.target.checked && {
																											text: option,
																											quizId
																										}
																									].filter(Boolean)
																								}
																							}
																						}, statement);
																						setError({ ...errors, [innerUID]: null });
																					}}
																				/>
																				<Typography
																					variation="2"
																					weight="regular"
																					style={{ cursor: 'pointer' }}
																					onClick={() => {
																						const newValues = answerValues.filter(({ text }) => text !== option);
																						handleOnChange({
																							target: {
																								name: `${currentModule.id}.${innerUID}`,
																								value: {
																									values: [
																										...newValues,
																										!answerValues.some(({ text }) => text === option) && {
																											text: option,
																											quizId
																										}
																									].filter(Boolean)
																								}
																							}
																						}, statement);
																						setError({ ...errors, [innerUID]: null });
																					}}
																				>
																					{option}
																				</Typography>
																			</S.CheckboxRow>
																		))}
																	</>
																) : (
																	<Checkbox
																		name={`${currentModule.id}.${innerUID}`}
																		value={answerValues.some(({ text }) => text === 'Yes')}
																		onChange={(e) => {
																			handleOnChange({
																				target: {
																					name: `${currentModule.id}.${innerUID}`,
																					value: {
																						values: [
																							e.target.checked && {
																								text: 'Yes',
																								quizId
																							}
																						].filter(Boolean)
																					}
																				}
																			}, statement);
																			setError({ ...errors, [innerUID]: null });
																		}}
																	/>
																)}
															</S.StatementOptions>
														</S.FlexRow>
														{answerValues && answerValues.length > 0 && !isRichTextEmpty(feedback) && (
															<S.QuestionFeedbackContainer>
																<S.QuestionFeedback>
																	<S.ContentContainer className="paragraphContent">
																		{render(feedback, richTextConfiguration({ setVideoPlaying }))}
																	</S.ContentContainer>
																</S.QuestionFeedback>
															</S.QuestionFeedbackContainer>
														)}
													</S.QuizStatement>
												);
											})}
										</S.AllApplyQuizContainer>
									);
								}

								// Term Matching
								case 'activity_term_matching': {

									// Get parameters
									const { terms } = rest;

									// Check if all correct
									const allCorrect = terms.every(({ _uid: innerUID }) => {
										const answerId = inputValues[`${currentModule.id}.${innerUID}`]?.response?.id || '';
										return answerId === innerUID;
									});

									// Render component
									return (
										<DndContext
											key={_uid}
											onDragEnd={(e) => { handleDragEnd(e, currentModule.id, terms); }}

											// sensors={sensors}
										>
											<S.TermMatchingContainer>
												<S.DefinitionContainer>
													{allCorrect && (
														<S.MatchSuccessFeedback>
															<FontAwesomeIcon icon={['fas', 'check-circle']} />
															<Typography tag="span" variation="1" weight="semibold">You got them all!</Typography>
														</S.MatchSuccessFeedback>
													)}
													{orderedDefinitions.filter(({ _uid: id }) => !Object.values(inputValues).some(({ response }) => response.id === id)).map(({
														definition,
														_uid: innerUID,
													}) => (
														<Draggable key={`${innerUID}-definition`} id={`${innerUID}-definition`}>
															<S.Definition>
																<Typography tag="p" variation="2" center={false}>{definition}</Typography>
															</S.Definition>
														</Draggable>
													))}
												</S.DefinitionContainer>
												<S.TermContainer>
													{terms.map(({
														incorrect_feedback: incorrectFeedback,
														term,
														_uid: innerUID,
													}) => {

														// Get current answer
														const answerValue = inputValues[`${currentModule.id}.${innerUID}`]?.response?.text || '';
														const answerId = inputValues[`${currentModule.id}.${innerUID}`]?.response?.id || '';

														// Get error
														const error = errors[innerUID];

														// Render term
														return (
															<S.MatchTerm key={innerUID}>
																<S.FlexRow>
																	<Droppable id={innerUID}>
																		<S.MatchDefinition>
																			{!answerValue && error?.message && (
																				<S.MatchError>
																					<Typography tag="p" variation="3">{error?.message}</Typography>
																				</S.MatchError>
																			)}
																			{answerValue && (
																				<Draggable id={`${answerId}-definition`}>
																					<S.Definition>
																						<Typography tag="p" variation="2" center={false}>{answerValue}</Typography>
																					</S.Definition>
																				</Draggable>
																			)}
																		</S.MatchDefinition>
																	</Droppable>
																	<S.MatchLine className="isNotMobile" />
																	<S.Term>
																		<Typography tag="h5" weight="semibold">{term}</Typography>
																	</S.Term>
																</S.FlexRow>
																{answerValue && answerId !== innerUID && !isRichTextEmpty(incorrectFeedback) && (
																	<S.QuestionFeedbackContainer>
																		<S.MatchFeedback>
																			<S.ContentContainer className="paragraphContent">
																				{render(incorrectFeedback, richTextConfiguration({ setVideoPlaying }))}
																			</S.ContentContainer>
																		</S.MatchFeedback>
																	</S.QuestionFeedbackContainer>
																)}
															</S.MatchTerm>
														);
													})}
												</S.TermContainer>
											</S.TermMatchingContainer>
										</DndContext>
									);
								}
								default:
									return null;
							}
						})}
					</S.Activity>
				);
			}
			default:
				return null;
		}
	};

	// Render activity
	const renderActivity = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner />;
		}
		if (pageStatus === 'error') {
			return <ErrorComponent />;
		}
		return (
			<>
				{/* Child View Badge */}
				{showChildPerspective && (
					<S.ChildViewBadge>
						<Typography tag="p" weight="semibold" variation="3">My Child&apos;s View (this is what your child sees)</Typography>
					</S.ChildViewBadge>
				)}

				{/* Parent View Badge */}
				{showParentPerspective && (
					<S.ParentViewBadge>
						<Typography tag="p" weight="semibold" variation="3">Parent View (this is content just for parents)</Typography>
					</S.ParentViewBadge>
				)}

				{/* Activity Content */}
				<S.ActivityContent $activeTransition={stepTransition}>

					{/* Step */}
					<S.Step $showPerspective={showChildPerspective || showParentPerspective}>
						{currentStepIndex === componentData.module.content.length ? (
							<S.CompleteContainer>
								<S.IconContainer>
									<FontAwesomeIcon icon={['fal', 'crown']} />
									<S.ConfettiTarget ref={confettiTargetRef} />
								</S.IconContainer>
								<Typography tag="h3" weight="semibold">You Did It!</Typography>
								<Typography tag="p" weight="medium">This activity is complete. Great work!</Typography>
							</S.CompleteContainer>
						)
							: renderStep()}
					</S.Step>

					{/* Child View */}
					{showChildPerspective && <S.ChildView />}

					{/* Parent View */}
					{showParentPerspective && <S.ParentView />}

				</S.ActivityContent>

				{/* Action Bar */}
				<S.ActionBar>

					{/* Back Button */}
					{currentStepIndex > 1 && (
						<Button
							size="medium"
							variant="outline"
							onClick={handlePrevious}
						>
							<Typography tag="p" variation="2" weight="medium">Back</Typography>
						</Button>
					)}

					{/* Spacer */}
					<div style={{ flexGrow: '1' }} />

					{/* Play / Pause Button */}
					{/* <Button
						size="medium"
						variant="outline"
						onClick={playPauseAudio}
						className="audioButton"
					>
						<FontAwesomeIcon icon={backgroundAudioVolume === 0 || muteAudio ? ['fal', 'play'] : ['fal', 'pause']} />
						<Typography tag="p" variation="2" weight="medium">{backgroundAudioVolume === 0 || muteAudio ? 'Play music' : 'Pause music'}</Typography>
					</Button> */}

					{/* Next Module Button */}
					{/* user?.role?.primary === ROLES.PARENT && componentData?.nextModule && (
						<Button
							size="medium"
							variant="outline"
							onClick={moveToNextModule}
						>
							<Typography tag="p" variation="2" weight="medium">Skip to next module</Typography>
							<FontAwesomeIcon icon={['fas', 'arrow-right']} />
						</Button>
					) */}

					{/* Feedback Widget */}
					<ButtonFeedbackWidget
						slug={slug}
						className={`animate ${isFeedbackVisible ? 'showFeedback' : 'hideFeedback'}`}
						continueAction={continueAction}
						stepId={`${componentData?.module?.content?.[currentStepIndex]?.id || ''}`}
						responses={stepResponses}
					/>

					{/* Next Button */}
					<Button
						size="medium"
						onClick={handleNext}
						disabled={isLoading}
						isLoading={isLoading}
						className={`animate ${isFeedbackVisible ? 'hideButton' : ''}`}
					>
						<Typography tag="p" variation="2" weight="medium">
							{renderNextButton()}
						</Typography>
					</Button>


				</S.ActionBar>

				{/* Background */}
				{currentStepIndex === componentData.module.content.length && <S.CompleteBackground />}

				{/* Secondary Background */}
				{currentStepIndex !== componentData.module.content.length && showBackground && <S.CompleteBackground />}
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation data={data}>
				<S.Wrapper left right onMouseDown={() => { /* playAudio(); */ }}>

					{/* Activity Card */}
					<S.ActivityCard>

						{/* Top bar */}
						<S.Topbar $showPerspective={showChildPerspective || showParentPerspective}>
							<Typography tag="p" weight="bold" variation="2">{componentData?.module?.name}</Typography>
							<S.CloseButton icon={['fal', 'times']} size={1.1} onClick={() => { navigate(`${stateLocale.localePath}/dashboard`); }} />
						</S.Topbar>

						{/* Activity Content */}
						{renderActivity()}

						{/* Confetti */}
						{fireConfetti && (
							<ConfettiBurst
								fireAway={fireConfetti}
								targetRef={confettiTargetRef}
								force={0.4}
								duration={3000}
								particleCount={80}
								className="zIndex3"
								audioHook={fanfareSound}
							/>
						)}

					</S.ActivityCard>

					{/* Decoration */}
					<S.Decoration />

				</S.Wrapper>
			</AppNavigation>

			{/* Audio Player */}
			{/* <ReactAudioPlayer
				ref={audioPlayerRef}
				src={`${process.env.CDN_URL}/public/assets/audio/activity-music-loop.mp3`}
				volume={muteAudio ? 0 : backgroundAudioVolume}
				controls={false}
				loop
			/> */}
		</>
	);
};


/**
 * Configuration
 */

Activity.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
Activity.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default Activity;
