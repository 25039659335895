/*
 * File: TextInput.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 9, 2022 at 4:02 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 13, 2024 at 10:41 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './TextInput.styles';


/**
 * Component
 */

export const TextInput = forwardRef(({
	id, label, error, type, size, icon, containerClassName, ...rest
}, ref) => (
	<S.Wrapper error={error} className={containerClassName}>
		{label && (
			<Typography tag="label" weight="semibold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null} className="animate">
				{label}
			</Typography>
		)}
		{icon && (
			<S.IconWrapper>
				<FontAwesomeIcon icon={icon} />
			</S.IconWrapper>
		)}
		<S.TextInput ref={ref} id={id} error={error} {...rest} type={type} size={size} withIcon={!!icon} className={rest.className ? `${rest.className} animate` : 'animate'} />
		{error && error.message && (
			<S.FloatingWrapper title={error.message} className="animate">
				<Typography tag="p" variation="3" className="animate">
					{error.message}
				</Typography>
			</S.FloatingWrapper>
		)}
	</S.Wrapper>
));


/**
 * Configuration
 */

TextInput.displayName = 'TextInput';
TextInput.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	type: PropTypes.oneOf(['text', 'password', 'email', 'search', 'number']),
	size: PropTypes.oneOf(['fit', 'small', 'medium']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	containerClassName: PropTypes.string,
	error: PropTypes.shape(),
};
TextInput.defaultProps = {
	icon: null,
	type: 'text',
	size: 'medium',
	placeholder: '',
	label: '',
	containerClassName: null,
	error: null,
	id: null
};
