/*
 * File: ButtonFeedbackWidget.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 10:05 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button } from '../Button';

// Constants
import { mobileBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 50px;
	padding: 10px 0px 10px 0px;
	height: 50px !important;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 10px 0px 10px 20px;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin-right: 10px;
	}
`;

export const RatingButton = styled(Button)`
	width: 42px !important;
	min-width: 42px !important;
	height: 100% !important;
`;

export const Divider = styled.div`
	width: 1px;
	height: 100%;
	background-color: ${({ theme }) => theme.layoutBorder};
`;
