/*
 * File: Routes.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:25 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 30, 2024 at 12:14 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { Navigate, matchPath } from 'react-router-dom';

// Utilities
import { createLocaleVariants } from '../utilities/locale';

// Slice
import { initialState as initialLocale } from '../store/slices/locale/locale.slice';

// Navigation
import { PublicRouteWrapper } from '../navigation/PublicRouteWrapper';
import { AuthRouteWrapper } from '../navigation/AuthRouteWrapper';
import { UserRouteWrapper } from '../navigation/UserRouteWrapper';

// Components
import {
	Activity,
	Answers,
	Error,
	ForgotPassword,
	InviteChild,
	Dashboard,
	Join,
	Journal,
	Login,
	ResetPassword,
	Resources,
	Rewards,
	TermsList,
	Onboarding,
	LegalOverview,
	CookiePolicy,
	PrivacyPolicy,
	SecurityPromise,
	Terms,
} from './pages';

// Constants
import { ROLES } from '../../Constants';


/**
 * Helpers
 */

const filterProps = (path, props) => {

	// If data is undefined, return props
	if (!props || !props.data) return props;

	// Search for route path
	const routes = Object.keys(props.data).filter((key) => key.startsWith('/'));
	const matchRoute = routes.find((route) => matchPath(path, route));

	// Check for exact match
	let exactMatch = true;
	if (typeof window !== 'undefined') {
		const fullPath = (props?.locale?.url?.fullPath !== '' ? props?.locale?.url?.fullPath?.replace(/\/$/, '') : props?.locale?.url?.fullPath) || '';
		let basePath = fullPath.startsWith(props?.locale?.localePath) ? fullPath.replace(props?.locale?.localePath, '') : fullPath;
		if (basePath === '') basePath = '/';
		exactMatch = fullPath === window.location.pathname || `${fullPath}/` === window.location.pathname || basePath === window.location.pathname;
	}

	// Return data
	return (props.data ? {
		...props,
		data: {
			...props?.data.global,
			...matchRoute && exactMatch ? props?.data[matchRoute] : undefined
		}
	} : props);
};

const prepareComponent = (routeObj) => (Array.isArray(routeObj) ? routeObj.map((route) => ({
	...route,
	component: route.component(route.path)
})) : {
	...routeObj,
	component: routeObj.component(routeObj.path)
});


/**
 * Routes
 */

export const Routes = (props) => {

	// Get current locale from props
	const localeObj = props?.locale?.localePath != null ? props?.locale : initialLocale.value;

	// Get current meta from props
	const metaObj = props?.meta;

	// Get current user from props
	const userObj = props?.user;

	// Render routes
	return [].concat(...[

		/**
		 * Dashboard Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/dashboard',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.CHILD, ROLES.PARENT, ROLES.ADMIN]} user={userObj} locale={localeObj}>
						<Dashboard
							{...filterProps(path, props)}
							meta={{
								title: 'Dashboard - Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/dashboard/journal',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.CHILD, ROLES.PARENT, ROLES.ADMIN]} user={userObj} locale={localeObj}>
						<Journal
							{...filterProps(path, props)}
							meta={{
								title: 'My Journal - Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/dashboard/child-account/:childId',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.PARENT]} user={userObj} locale={localeObj}>
						<InviteChild
							{...filterProps(path, props)}
							meta={{
								title: 'Create Child Account - Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/dashboard/rewards',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.CHILD, ROLES.PARENT, ROLES.ADMIN]} user={userObj} locale={localeObj}>
						<Rewards
							{...filterProps(path, props)}
							meta={{
								title: 'My Rewards - Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),


		/**
		 * Activity Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/activity/:slug',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.CHILD, ROLES.PARENT, ROLES.ADMIN]} user={userObj} locale={localeObj}>
						<Activity
							{...filterProps(path, props)}
							meta={{
								title: 'Activity - Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),


		/**
		 * Content Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/dashboard/resources',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.CHILD, ROLES.PARENT, ROLES.ADMIN]} user={userObj} locale={localeObj}>
						<Resources
							{...filterProps(path, props)}
							meta={{
								title: 'Resources - Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/dashboard/answers',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.CHILD, ROLES.PARENT, ROLES.ADMIN]} user={userObj} locale={localeObj}>
						<Answers
							{...filterProps(path, props)}
							meta={{
								title: 'Ask a Question - Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/dashboard/terms',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			functions: [],
			component(path) {
				return (
					<UserRouteWrapper roles={[ROLES.CHILD, ROLES.PARENT, ROLES.ADMIN]} user={userObj} locale={localeObj}>
						<TermsList
							{...filterProps(path, props)}
							meta={{
								title: 'Terms List - Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</UserRouteWrapper>
				);
			}
		})),


		/**
		 * Authentication Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/login',
			exact: true,
			shouldIndex: true,
			priority: '1.0',
			component(path) {
				return (
					<AuthRouteWrapper redirect="/" user={userObj} locale={localeObj}>
						<Login
							{...filterProps(path, props)}
							meta={{
								title: 'Log In - Let\'s Talk',
								description: 'Let\'s Talk is app where you and your child are on a journey toward open communication about puberty, bodies, sex, and relationships.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</AuthRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/forgot',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<ForgotPassword
							{...filterProps(path, props)}
							meta={{
								title: 'Forgot Password - Let\'s Talk',
								description: 'Forgot your password? Enter your account email and we\'ll send you a link to reset your password.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/reset-password/:aToken/:bToken',
			exact: true,
			shouldIndex: false,
			component(path) {
				return (
					<PublicRouteWrapper>
						<ResetPassword
							{...filterProps(path, props)}
							meta={{
								title: 'Reset Password - Let\'s Talk',
								description: 'Reset your Let\'s Talk account password.',
								bodyClasses: 'hide-captcha',
								showSocial: false,
								shouldIndex: false,
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/join',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper user={userObj} locale={localeObj}>
						<Join
							{...filterProps(path, props)}
							meta={{
								title: 'Join Let\'s Talk',
								description: 'Let\'s Talk is app where you and your child are on a journey toward open communication about puberty, bodies, sex, and relationships.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/join/onboarding',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper user={userObj} locale={localeObj}>
						<Onboarding
							{...filterProps(path, props)}
							meta={{
								title: 'Join Let\'s Talk',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Legal Pages
		 */

		prepareComponent(createLocaleVariants({
			path: '/legal',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<LegalOverview
							{...filterProps(path, props)}
							meta={{
								title: 'Legal - Let\'s Talk',
								description: 'Let\'s Talk\'s Terms and Policies, including security guidelines, Terms of Service, cookie policies, privacy, and more.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/cookie-policy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<CookiePolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Cookie Policy - Let\'s Talk',
								description: 'Our Cookie Policy explains what these technologies are and why we use them, as well as your rights to control our use of them.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/privacy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<PrivacyPolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Privacy Policy - Let\'s Talk',
								description: 'We care about your privacy. Our privacy policy will help you understand what information is collected, how it\'s used, and what choices you have.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/security',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<SecurityPromise
							{...filterProps(path, props)}
							meta={{
								title: 'Security Promise - Let\'s Talk',
								description: 'At Let\'s Talk, privacy and data security are important to us, and both are paramount to the safety and security of our users and visitors. So is transparency.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/terms',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<Terms
							{...filterProps(path, props)}
							meta={{
								title: 'Terms of Service - Let\'s Talk',
								description: 'Our Terms of Service detail and clarify your rights and responsibilities when using Let\'s Talk.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Redirects
		 */

		prepareComponent(createLocaleVariants({
			path: '/',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component() {
				return (<Navigate to={`${localeObj.localePath}/dashboard`} />);
			}
		})),


		/**
		 * Error
		 */

		prepareComponent({
			path: '*',
			shouldIndex: false,
			isError: true,
			code: 404,
			component(path) {
				return (
					<Error
						code={404}
						{...filterProps(path, props)}
						meta={{
							title: 'Page Not Found - Let\'s Talk',
							description: 'Whoops! We can\'t seem to find the page you\'re looking for.',
							bodyClasses: 'hide-captcha',
							showSocial: false,
							shouldIndex: false,
							...metaObj
						}}
					/>
				);
			}
		})
	].filter(Boolean));
};
