/*
 * File: RewardsCreationStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 12, 2024 at 9:25 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { updateUserProfile } from '../../../../services/user';
import { createReward } from '../../../../services/reward';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, TextInput, Typography
} from '../../../../components';

// Styles
import * as S from './RewardsCreationStep.styles';


/**
 * Component
 */

export const RewardsCreationStep = forwardRef(({
	updateStep, updateHeight, className
}, ref) => {

	// Create state handlers
	const [customRewardError, setCustomRewardError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [selectedRewardId, setSelectedRewardId] = useState(null);
	const [customRewardActive, setCustomRewardActive] = useState(false);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get rewards from hook
	const rewards = useSelector((state) => state.rewards.value);

	// Get action from hooks
	const dispatch = useDispatch();

	// Handle next function
	const handleNext = async () => {

		// Get parameters
		const { customReward } = inputValues;

		// Validate parameters
		if (customRewardActive && (!customReward || validator.isEmpty(customReward, { ignore_whitespace: true }))) {
			setCustomRewardError({ message: 'Please enter a description of your custom reward to continue' });
			return;
		}
		if (!customRewardActive && (!selectedRewardId || validator.isEmpty(selectedRewardId, { ignore_whitespace: true }))) {
			toastError(uiMode, 'Please select a reward for your child to continue');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Initialize reward id
		let rewardId = selectedRewardId;

		// Create new custom reward if necessary
		if (customRewardActive) {
			try {

				// Create custom reward
				const { data: { reward } } = await createReward({ description: customReward });

				// Set reward id
				rewardId = reward.id;

			} catch ({ response }) {

				// Set loading state
				setIsLoading(false);
				setCustomRewardError(null);

				// Show error message
				if (response?.data?.message) {
					toastError(uiMode, response?.data?.message);
				} else {
					toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
				}

				// Return
				return;
			}
		}

		// Update user profile
		updateUserProfile({
			rewardId
		}).then(async ({ data: { user: newUser } }) => {

			// Update user state
			dispatch(updateUser(newUser));

			// Update step
			updateStep(11);

			// Set loading state
			setIsLoading(false);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setCustomRewardError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const { name, value } = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on custom reward update
	useEffect(() => {

		// Update height
		updateHeight(true);

	}, [customRewardActive]);

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Icon */}
				<S.IconContainer>
					<FontAwesomeIcon icon={['fal', 'trophy']} />
				</S.IconContainer>

				{/* Title */}
				<Typography tag="h3" weight="bold">Awesome! Now time for Rewards</Typography>

				{/* Instructions */}
				<Typography tag="p" variation="1" weight="medium">Select a reward that your child will receive after they complete all the steps.</Typography>
				<Typography tag="p" variation="1" weight="medium">Here are a few rewards that have worked for other families. You can also write your own! Be as specific as possible so your child knows what to expect.</Typography>

				{/* Selection Options */}
				<S.SelectionContainer>
					{rewards.map((reward) => (
						<S.RewardOption
							key={reward.id}
							className="animate"
							onClick={() => {
								setSelectedRewardId(reward.id);
								setCustomRewardActive(false);
							}}
							$isSelected={reward.id === selectedRewardId}
						>
							<Typography tag="p">{reward.description}</Typography>
						</S.RewardOption>
					))}
					<S.RewardOption
						className="animate"
						$isSelected={customRewardActive}
						onClick={() => {
							setSelectedRewardId(null);
							setCustomRewardActive(true);
						}}
					>
						<Typography tag="p">Customize the reward</Typography>
					</S.RewardOption>
				</S.SelectionContainer>

				{/* Custom Reward */}
				{customRewardActive && (
					<S.CustomRewardContainer>
						<TextInput
							name="customReward"
							type="text"
							label="Child's Custom Reward"
							placeholder="e.g. Pizza party!"
							error={customRewardError}
							value={inputValues.customReward || ''}
							containerClassName="inputSpacer"
							autoComplete="email"
							onFocus={() => { setCustomRewardError(null); }}
							onKeyUp={() => { setCustomRewardError(null); }}
							onBlur={() => { setCustomRewardError(null); }}
							onChange={handleOnChange}
						/>
					</S.CustomRewardContainer>
				)}

				{/* Next Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">Next</Button>

				{/* Back Button */}
				<Button
					disabled={isLoading}
					onClick={() => { updateStep(9); }}
					size="small"
					variant="text"
					style={{ margin: '10px 0px -5px' }}
				>
					<FontAwesomeIcon icon={['fas', 'arrow-left']} />
					<Typography variation="button-medium" weight="semibold">Go Back</Typography>
				</Button>

			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

RewardsCreationStep.displayName = 'RewardsCreationStep';
RewardsCreationStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	className: PropTypes.string,
	updateHeight: PropTypes.func,
};
RewardsCreationStep.defaultProps = {
	className: null,
	updateHeight: null
};
