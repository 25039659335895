/*
 * File: Spinner.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 6, 2022 at 12:13 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 8, 2024 at 10:00 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: grid;
	place-items: center;
	height: inherit;
	align-content: center;
	width: 100%;
	height: 100%;
`;
