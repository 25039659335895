/*
 * File: Login.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:12 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import validator from 'validator';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';
import { renderLogo } from '../../../utilities/image';

// Services
import { loginUser } from '../../../services/authentication';

// Slices
import { updateUser } from '../../../store/slices/user/user.slice';

// Components
import {
	Meta,
	Typography,
	TextInput,
	Button,
	Card,
	Checkbox,
	UIModeWidget
} from '../../../components';

// Styles
import * as S from './Login.styles';


/**
 * Component
 */

const Login = ({ meta, locale }) => {

	// Create state handlers
	const [usernameError, setUsernameError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get actions from hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle log in function
	const handleLogin = async () => {

		// Get parameters
		const { username, password, testConsent } = inputValues;

		// Validate parameters
		if (!username || validator.isEmpty(username, { ignore_whitespace: true })) {
			setUsernameError({ message: 'Please enter your email or username' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true })) {
			setPasswordError({ message: 'Please enter your password' });
			return;
		}
		if (!testConsent) {
			toastError(uiMode, 'You must consent to test the app before continuing.');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Log in user
		loginUser({
			username,
			pass: password,
		}).then(async ({ data }) => {

			// Dispatch new user
			dispatch(updateUser(data.user));

			// Navigate to the dashboard
			navigate(`${stateLocale.localePath}/dashboard`);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setUsernameError(null);
			setPasswordError(null);

			// Clear password
			setInputValues({ ...inputValues, password: '' });

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We can\'t find an account with those details. Give it another go.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value, checked, type
		} = event.target;
		if (type === 'checkbox') {
			setInputValues({ ...inputValues, [name]: checked });
		} else {
			setInputValues({ ...inputValues, [name]: value });
		}
	};

	// Handle sign up action function
	const signUpFunction = () => {
		navigate(`${stateLocale.localePath}/join`);
	};

	// Handle forgot password action function
	const forgotPasswordFunction = () => {
		navigate(`${stateLocale.localePath}/forgot`);
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle render content
	const renderContent = () => (
		<S.Form>

			{/* Card */}
			<Card className="cardPadding">

				{/* Content */}
				<Typography tag="h2" weight="bold">Welcome back!</Typography>
				<Typography tag="p" variation="1" weight="regular">Log in to continue your progress.</Typography>

				{/* Inputs */}
				<TextInput
					label="Email or Username"
					name="username"
					type="text"
					error={usernameError}
					value={inputValues.username || ''}
					containerClassName="inputSpacer"
					autoComplete="email"
					onFocus={() => { setUsernameError(null); }}
					onKeyUp={() => { setUsernameError(null); }}
					onBlur={() => { setUsernameError(null); }}
					onChange={handleOnChange}
				/>
				<TextInput
					label="Password"
					type="password"
					name="password"
					error={passwordError}
					value={inputValues.password || ''}
					containerClassName="inputSpacer"
					autoComplete="current-password"
					onFocus={() => { setPasswordError(null); }}
					onKeyUp={() => { setPasswordError(null); }}
					onBlur={() => { setPasswordError(null); }}
					onChange={handleOnChange}
				/>

				{/* Forgot Password Button */}
				<S.ForgotPasswordContainer>
					<S.ForgotPasswordButton
						disabled={isLoading}
						variant="text"
						errorOffset={passwordError != null}
						className="animate"
						onClick={forgotPasswordFunction}
					>
						<Typography tag="p" variation="3" weight="medium">Forgot Password?</Typography>
					</S.ForgotPasswordButton>
				</S.ForgotPasswordContainer>

				{/* Checkboxes */}
				<S.CheckboxContainer>
					<S.CheckboxRow>
						<Checkbox
							name="testConsent"
							value={inputValues.testConsent || false}
							onChange={handleOnChange}
							isDark
						/>
						<Typography variation="2" weight="regular">
							I agree to test this program
						</Typography>
					</S.CheckboxRow>
				</S.CheckboxContainer>

				{/* Log In Button */}
				<Button disabled={isLoading} isLoading={isLoading} onClick={handleLogin} size="large">Log In</Button>

				{/* Create Account Button */}
				<S.BottomActionContainer>
					<Button variant="text" size="small" onClick={signUpFunction} disabled={isLoading}>
						<Typography tag="p" variation="2" weight="medium">
							Don&apos;t have an account?
							{' '}
							<Typography weight="bold">
								Sign Up
							</Typography>
						</Typography>
					</Button>
				</S.BottomActionContainer>
			</Card>
		</S.Form>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} />

			{/* Component Content */}
			<S.Wrapper>

				{/* Logo */}
				<S.Logo src={renderLogo(uiMode)} title="Let's Talk Logo" alt="Let's Talk Logo" />

				{/* Form Container */}
				<S.FormContainer left right>
					{renderContent()}
				</S.FormContainer>

				{/* UI Mode Widget */}
				<UIModeWidget className="uiModeWidget" />

				{/* Decoration */}
				<S.Decoration />

			</S.Wrapper>
		</>
	);
};


/**
 * Configuration
 */

Login.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
Login.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default Login;
