/*
 * File: Select.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 1:17 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 10, 2024 at 4:51 PM
 * Modified By: Brendan Michaelsen
 */
/* eslint-disable no-nested-ternary */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import chroma from 'chroma-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { useSelector } from 'react-redux';
import { Typography } from '../Typography';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './Select.styles';
import { DarkTheme, LightTheme } from '../../styles/colors';


/**
 * Dimensions
 */

const SIZES = {
	small: {
		control: {
			minHeight: '2.25rem',
			fontSize: '0.75rem',
		},
		option: {
			fontSize: '0.75rem',
		},
	},
	medium: {
		control: {
			minHeight: '2.8125rem',
			fontSize: '1rem',
		},
		option: {
			fontSize: '0.95rem',
		},
	},
	fit: {
		control: {
			width: '100%',
			fontSize: '0.9rem'
		},
		option: {
			fontSize: '0.9rem'
		}
	}
};


/**
 * Component
 */

export const Select = forwardRef(({
	id, name, label, placeholder, values, options, error, size, icon, iconOnClick, onChange, containerClassName, ...rest
}, ref) => {

	// Create reference
	const selectRef = useRef(null);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current theme
	const theme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;

	// Create focus color style
	const createFocusStyle = (isFocused) => (isFocused ? theme.inputFocusBorder : theme.inputBorder);

	// Create transparent color
	const selectedColor = uiMode.mode === UI_MODE_OPTIONS.DARK ? chroma(theme.secondaryBackground).brighten(0.4).css() : chroma(theme.secondaryBackground).darken(0.4).css();

	// Define select styles
	const selectStyles = {
		clearIndicator: (styles) => ({ ...styles }),
		container: (styles) => ({ ...styles }),
		group: (styles) => ({ ...styles }),
		groupHeading: (styles) => ({ ...styles }),
		loadingIndicator: (styles) => ({ ...styles }),
		loadingMessage: (styles) => ({ ...styles }),
		menuList: (styles) => ({ ...styles }),
		menuPortal: (styles) => ({
			...styles,
			zIndex: 9999
		}),
		label: (styles) => ({ ...styles }),
		control: (styles, { isFocused }) => ({
			...styles,
			borderRadius: '50px',
			minHeight: SIZES[size].control.minHeight,
			fontSize: SIZES[size].control.fontSize,
			borderColor: error ? theme.statusDangerBase : createFocusStyle(isFocused),
			boxShadow: 'none',
			'&:hover': { borderColor: error ? theme.statusDangerBase : createFocusStyle(isFocused) },
			cursor: 'pointer',
			backgroundColor: theme.primaryBackground,
		}),
		dropdownIndicator: (styles, { isFocused }) => ({
			...styles,
			padding: 0,
			color: error ? theme.statusDangerBase : createFocusStyle(isFocused)
		}),
		indicatorsContainer: (styles) => ({
			...styles,
			padding: '0 0.75rem'
		}),
		indicatorSeparator: (styles) => ({
			...styles,
			width: 0
		}),
		input: (styles) => ({
			...styles,
			padding: 0,
			margin: 0,
			color: theme.primaryText
		}),
		menu: (styles) => ({
			...styles,
			position: 'relative',
			zIndex: 9999,
			boxShadow: 'none',
			backgroundColor: theme.primaryBackground,
			border: `1px solid ${theme.layoutBorder}`,
		}),
		multiValue: (styles) => ({
			...styles,
			backgroundColor: 'transparent',
			border: `1px solid ${theme.inputFocusBorder}`,
			padding: '0.1875rem 0.4375rem',
			borderRadius: 9999
		}),
		multiValueLabel: (styles) => ({
			...styles,
			color: theme.inputFocusBorder,
			padding: 0,
			fontSize: 12,
			fontWeight: 700,
			lineHeight: '20px'
		}),
		multiValueRemove: (styles) => ({
			...styles,
			color: theme.inputFocusBorder,
			'&:hover': { backgroundColor: 'transparent' }
		}),
		noOptionsMessage: (styles) => ({ ...styles }),
		option: (styles, { isDisabled, isFocused, isSelected }) => ({
			...styles,
			cursor: 'pointer',
			fontSize: SIZES[size].option.fontSize,
			color: theme.primaryText,
			backgroundColor: isDisabled
				? undefined
				: isSelected
					? selectedColor
					: isFocused
						? theme.secondaryBackground
						: undefined,
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
					? isSelected
						? selectedColor
						: theme.secondaryBackground
					: undefined,
			},
		}),
		placeholder: (styles) => ({
			...styles,
			padding: 0,
			margin: 0,
			whiteSpace: 'nowrap',
			color: theme.inputPlaceholder
		}),
		singleValue: (styles) => ({
			...styles,
			padding: 0,
			margin: 0,
			color: theme.primaryText,
			textAlign: 'left'
		}),
		valueContainer: (styles) => ({
			...styles,
			padding: icon ? '0 1.25rem;' : '0 1.25rem;',
			margin: 0,
		}),
	};

	// Handle value changed
	const handleChangeEvent = (value) => {
		const finalValue = Array.isArray(value) ? value.map((val) => val.value) : value.value;
		onChange({ target: { name, value: finalValue } });
	};

	// Return component
	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<Typography
					tag="label"
					weight="semibold"
					htmlFor={id ? `${id}` : null}
					id={id ? `${id}-label` : null}
					className="animate"
					onClick={() => (ref ? ref.current.focus() : selectRef.current.focus())}
				>
					{label}
				</Typography>
			)}
			<ReactSelect
				ref={ref || selectRef}
				id={id}
				placeholder={placeholder}
				options={options}
				onChange={handleChangeEvent}
				{...rest}
				value={values.map((value) => options.find((option) => option.value === value)).filter((value) => value != null)}
				styles={selectStyles}
				className={rest.className ? `${rest.className} animate` : 'animate'}
				menuPortalTarget={typeof document !== 'undefined' ? document.body : null}
				menuPlacement="auto"
				instanceId={`react-select-${id}`}
			/>
			{icon && (
				<S.IconWrapper>
					<FontAwesomeIcon icon={icon} onClick={iconOnClick} />
				</S.IconWrapper>
			)}
			{error && error.message && (
				<S.FloatingWrapper title={error.message} className="animate">
					<Typography tag="p" variation="3" className="animate">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});


/**
 * Configuration
 */

Select.displayName = 'Select';
Select.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	size: PropTypes.oneOf(['fit', 'small', 'medium']),
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	values: PropTypes.arrayOf(PropTypes.string),
	label: PropTypes.string,
	containerClassName: PropTypes.string,
	error: PropTypes.shape(),
	options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
	iconOnClick: PropTypes.func,
	onChange: PropTypes.func,
};
Select.defaultProps = {
	icon: null,
	size: 'medium',
	placeholder: '',
	values: [],
	label: '',
	containerClassName: null,
	error: null,
	id: null,
	name: null,
	options: [],
	iconOnClick: () => { },
	onChange: () => { },
};
