/*
 * File: Error.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 12:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	text-align: center;

	.uiModeWidget {
		position: absolute;
		z-index: 2;
		top: 10px;
		right: 10px;
	}
`;

export const Logo = styled.img`
	width: 80px;
	margin: 40px auto 10px;
	text-align: center;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		width: 100px;
		margin: 60px auto 20px;
	}
`;
