/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on June 18, 2024 at 2:11 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: July 3, 2024 at 4:20 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const fetchJournalEntries = (data) => api.get('/journal/entries', { params: data });

export const fetchJournalPrompts = (data) => api.get('/journal/prompts', { params: data });

export const submitJournalEntry = ({ prompt, promptId, entryId }) => api.post('/journal/entries', { prompt, promptId, entryId });

export const submitJournalResponse = ({ response, entryId }) => api.post('/journal/response', { response, entryId });
