/*
 * File: Onboarding.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on April 8, 2024 at 8:51 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 12:32 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { renderLogo } from '../../../utilities/image';

// Components
import {
	Meta, Spinner,
	Typography
} from '../../../components';

// Step Components
import { AboutAgeStep } from './AboutAgeStep';
import { AboutGenderStep } from './AboutGenderStep';
import { AvatarStep } from './AvatarStep';
import { ChildAboutAgeStep } from './ChildAboutAgeStep';
import { ChildAboutGenderStep } from './ChildAboutGenderStep';
import { CompletionStep } from './CompletionStep';
import { ProfileNameStep } from './ProfileNameStep';
import { ReasonStep } from './ReasonStep';
import { RewardsCreationStep } from './RewardsCreationStep';
import { TutorialFlowStep } from './TutorialFlowStep';
import { WelcomeStep } from './WelcomeStep';

// Constants
import { ROLES } from '../../../../Constants';

// Styles
import * as S from './Onboarding.styles';


/**
 * Component
 */

const Onboarding = ({
	meta, locale, user
}) => {

	// Create state for step components
	const [aboutAgeStepHeight, setAboutAgeStepHeight] = useState(0);
	const [aboutGenderStepHeight, setAboutGenderStepHeight] = useState(0);
	const [avatarStepHeight, setAvatarStepHeight] = useState(0);
	const [childAboutAgeStepHeight, setChildAboutAgeStepHeight] = useState(0);
	const [childAboutGenderStepHeight, setChildAboutGenderStepHeight] = useState(0);
	const [completionStepHeight, setCompletionStepHeight] = useState(0);
	const [profileNameStepHeight, setProfileNameStepHeight] = useState(0);
	const [reasonStepHeight, setReasonStepHeight] = useState(0);
	const [rewardsCreationStepHeight, setRewardsCreationStepHeight] = useState(0);
	const [tutorialFlowStepHeight, setTutorialFlowStepHeight] = useState(0);
	const [welcomeStepHeight, setWelcomeStepHeight] = useState(0);

	// Create references for step components
	const aboutAgeStepRef = useRef();
	const aboutGenderStepRef = useRef();
	const avatarStepRef = useRef();
	const childAboutAgeStepRef = useRef();
	const childAboutGenderStepRef = useRef();
	const completionStepRef = useRef();
	const profileNameStepRef = useRef();
	const reasonStepRef = useRef();
	const rewardsCreationStepRef = useRef();
	const tutorialFlowStepRef = useRef();
	const welcomeStepRef = useRef();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current user
	let userObj = useSelector((state) => state.user.value);
	const userStatus = useSelector((state) => state.user.status);
	if (!userObj && user) userObj = user;

	// Get current locale from hook
	let localeObj = useSelector((state) => state.locale.value);
	if (locale && locale.isSet) localeObj = locale;

	// Get user role
	const userRole = userObj?.role?.primary;

	// Get initial step
	let initialStep = 1;
	if (userRole === ROLES.PARENT) {
		if (!userObj.name) {
			initialStep = 1;
		} else if (!userObj.reasonForUsing) {
			initialStep = 2;
		} else if (!userObj.tutorialComplete) {
			initialStep = 4;
		} else if (!userObj.avatarImage) {
			initialStep = 5;
		} else if (!userObj.gender && userObj.genders.length === 0) {
			initialStep = 6;
		} else if (!userObj.age) {
			initialStep = 7;
		} else if (!userObj.children || userObj.children.length === 0 || (!userObj.children[0].childGender && userObj.children[0].childGenders.length === 0)) {
			initialStep = 8;
		} else if (!userObj.children[0].childAgeRange) {
			initialStep = 9;
		} else if (!userObj.rewardsSetupComplete) {
			initialStep = 10;
		}
	} else if (userRole === ROLES.CHILD) {
		if (!userObj.name) {
			initialStep = 1;
		} else if (!userObj.tutorialComplete) {
			initialStep = 3;
		} else if (!userObj.avatarImage) {
			initialStep = 4;
		} else if (!userObj.gender && userObj.genders.length === 0) {
			initialStep = 5;
		} else if (!userObj.ageRange) {
			initialStep = 6;
		}
	}

	// Create state handlers
	const [currentStep, setCurrentStep] = useState(initialStep);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);
	const [showSecondaryDecoration, setShowSecondaryDecoration] = useState(false);
	const [hideHeaderContent, setHideHeaderContent] = useState(false);

	// Check if user has required role
	const userHasRequiredRole = userRole && (userRole === ROLES.CHILD || userRole === ROLES.PARENT);

	// Generate progress status
	const progressStatus = (step) => {
		switch (step) {
			case 1: {
				if (currentStep >= 1 && currentStep <= 7) return 'active';
				if (currentStep > 7) return 'complete';
				break;
			}
			case 2: {
				if (currentStep >= 8 && currentStep <= 9) return 'active';
				if (currentStep > 9) return 'complete';
				break;
			}
			case 3: {
				if (currentStep >= 10 && currentStep <= 11) return 'active';
				if (currentStep > 11) return 'complete';
				break;
			}
			default:
				break;
		}
		return 'inactive';
	};

	// Handle actions on step change
	useEffect(() => {

		// Update visible elements
		if (userRole === ROLES.PARENT) {
			setShowSecondaryDecoration(currentStep === 4);
			setHideHeaderContent(currentStep === 4);
		} else {
			setShowSecondaryDecoration(currentStep === 3);
			setHideHeaderContent(currentStep === 3);
		}

	}, [currentStep]);

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setAboutAgeStepHeight(aboutAgeStepRef?.current?.clientHeight);
			setAboutGenderStepHeight(aboutGenderStepRef?.current?.clientHeight);
			setAvatarStepHeight(avatarStepRef?.current?.clientHeight);
			setChildAboutAgeStepHeight(childAboutAgeStepRef?.current?.clientHeight);
			setChildAboutGenderStepHeight(childAboutGenderStepRef?.current?.clientHeight);
			setCompletionStepHeight(completionStepRef?.current?.clientHeight);
			setProfileNameStepHeight(profileNameStepRef?.current?.clientHeight);
			setReasonStepHeight(reasonStepRef?.current?.clientHeight);
			setRewardsCreationStepHeight(rewardsCreationStepRef?.current?.clientHeight);
			setTutorialFlowStepHeight(tutorialFlowStepRef?.current?.clientHeight);
			setWelcomeStepHeight(welcomeStepRef?.current?.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setAboutAgeStepHeight(aboutAgeStepRef?.current?.clientHeight);
		setAboutGenderStepHeight(aboutGenderStepRef?.current?.clientHeight);
		setAvatarStepHeight(avatarStepRef?.current?.clientHeight);
		setChildAboutAgeStepHeight(childAboutAgeStepRef?.current?.clientHeight);
		setChildAboutGenderStepHeight(childAboutGenderStepRef?.current?.clientHeight);
		setCompletionStepHeight(completionStepRef?.current?.clientHeight);
		setProfileNameStepHeight(profileNameStepRef?.current?.clientHeight);
		setReasonStepHeight(reasonStepRef?.current?.clientHeight);
		setRewardsCreationStepHeight(rewardsCreationStepRef?.current?.clientHeight);
		setTutorialFlowStepHeight(tutorialFlowStepRef?.current?.clientHeight);
		setWelcomeStepHeight(welcomeStepRef?.current?.clientHeight);
	});

	// Get step height for step component
	const getStepHeight = () => {
		if (userRole === ROLES.PARENT) {
			switch (currentStep) {
				case 1:
					return profileNameStepHeight;
				case 2:
					return reasonStepHeight;
				case 3:
					return welcomeStepHeight;
				case 4:
					return tutorialFlowStepHeight;
				case 5:
					return avatarStepHeight;
				case 6:
					return aboutGenderStepHeight;
				case 7:
					return aboutAgeStepHeight;
				case 8:
					return childAboutGenderStepHeight;
				case 9:
					return childAboutAgeStepHeight;
				case 10:
					return rewardsCreationStepHeight;
				case 11:
					return completionStepHeight;
				default:
					return 0;
			}
		} else if (userRole === ROLES.CHILD) {
			switch (currentStep) {
				case 1:
					return profileNameStepHeight;
				case 2:
					return welcomeStepHeight;
				case 3:
					return tutorialFlowStepHeight;
				case 4:
					return avatarStepHeight;
				case 5:
					return aboutGenderStepHeight;
				case 6:
					return aboutAgeStepHeight;
				case 7:
					return completionStepHeight;
				default:
					return 0;
			}
		}
		return 0;
	};

	// Render component function
	const renderComponent = () => {

		// Render loading state
		if (userStatus !== 'succeeded') {
			return <Spinner showMeta meta={meta} />;
		}

		// Check user state
		if (!userObj || userObj.isAnonymous === true) {

			// Redirect to login
			return <Navigate to={`${localeObj.localePath}/login`} />;
		}
		if (userObj.isActive !== true) {

			// Redirect to logout
			window.location = '/logout';
			return null;
		}
		if (!userHasRequiredRole) {

			// Redirect to logout
			window.location = '/logout';
			return null;
		}
		if (userObj.onboardingComplete === true) {

			// Redirect to dashboard
			return <Navigate to={`${localeObj.localePath}/dashboard`} />;
		}

		// Render component
		return (
			<>
				{/* Meta */}
				<Meta meta={meta} locale={localeObj} />

				{/* Component Content */}
				<S.Wrapper>

					{/* Logo */}
					<S.Logo
						src={renderLogo(uiMode)}
						title="Let's Talk Logo"
						alt="Let's Talk Logo"
						className="animate"
						$isVisible={!hideHeaderContent}
					/>

					{/* Progress Widget */}
					{userRole === ROLES.PARENT && (
						<S.ProgressWidget className="animate" $isVisible={!hideHeaderContent}>
							<S.ProgressWidgetInner>
								<S.ProgressStep $status={progressStatus(1)}>
									<S.ProgressCircle $status={progressStatus(1)}>
										<Typography weight="semibold">1</Typography>
									</S.ProgressCircle>
									<Typography weight="semibold" variation="2" tag="p">Profile</Typography>
								</S.ProgressStep>
								<S.ProgressStep $status={progressStatus(2)}>
									<S.ProgressCircle $status={progressStatus(2)}>
										<Typography weight="semibold">2</Typography>
									</S.ProgressCircle>
									<Typography weight="semibold" variation="2" tag="p">Children</Typography>
								</S.ProgressStep>
								<S.ProgressStep $status={progressStatus(3)}>
									<S.ProgressCircle $status={progressStatus(3)}>
										<Typography weight="semibold">3</Typography>
									</S.ProgressCircle>
									<Typography weight="semibold" variation="2" tag="p">Rewards</Typography>
								</S.ProgressStep>
								<S.ProgressStepConnector />
							</S.ProgressWidgetInner>
						</S.ProgressWidget>
					)}

					{/* Step Container */}
					<S.StepContainer className="animate" stepHeight={getStepHeight()}>
						{userRole === ROLES.PARENT && (
							<>
								<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<ProfileNameStep
											ref={profileNameStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 1}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<ReasonStep
											ref={reasonStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 2}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<WelcomeStep
											ref={welcomeStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 3}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 4 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<TutorialFlowStep
											ref={tutorialFlowStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 4}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 5 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<AvatarStep
											ref={avatarStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 5}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								{/* <S.Step className={currentStep === 6 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<AboutIntroductionStep
											ref={aboutIntroductionStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 6}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step> */}
								<S.Step className={currentStep === 6 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<AboutGenderStep
											ref={aboutGenderStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 6}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 7 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<AboutAgeStep
											ref={aboutAgeStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 7}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 8 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<ChildAboutGenderStep
											ref={childAboutGenderStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 8}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 9 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<ChildAboutAgeStep
											ref={childAboutAgeStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 9}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 10 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<RewardsCreationStep
											ref={rewardsCreationStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 10}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 11 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<CompletionStep
											ref={completionStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 11}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
							</>
						)}
						{userRole === ROLES.CHILD && (
							<>
								<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<ProfileNameStep
											ref={profileNameStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 1}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<WelcomeStep
											ref={welcomeStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 2}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<TutorialFlowStep
											ref={tutorialFlowStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 3}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 4 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<AvatarStep
											ref={avatarStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 4}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 5 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<AboutGenderStep
											ref={aboutGenderStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 5}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 6 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<AboutAgeStep
											ref={aboutAgeStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 6}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
								<S.Step className={currentStep === 7 ? 'animate show' : 'animate'}>
									<S.StepInner left right>
										<CompletionStep
											ref={completionStepRef}
											updateStep={setCurrentStep}
											updateHeight={setShouldUpdateHeight}
											isVisible={currentStep === 7}
											role={userRole}
										/>
									</S.StepInner>
								</S.Step>
							</>
						)}
					</S.StepContainer>

					{/* Primary Decoration */}
					<S.Decoration />

					{/* Secondary Decoration */}
					<S.SecondaryDecoration className="animate" $isVisible={showSecondaryDecoration} />

				</S.Wrapper>
			</>
		);
	};

	// Render component
	return renderComponent();
};


/**
 * Configuration
 */

Onboarding.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	user: PropTypes.shape(),
};
Onboarding.defaultProps = {
	meta: {},
	locale: {},
	user: null,
};


/**
 * Exports
 */

export default Onboarding;
