/*
 * File: image.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on March 14, 2023 at 1:16 PM
 * Copyright © 2023 Let's Talk. All rights reserved.
 *
 * Last Modified: July 22, 2024 at 11:57 AM
 * Modified By: Brendan Michaelsen
 */

import { UI_MODE_OPTIONS } from '../../Constants';

/**
 * Utilities
 */

export const dataURItoBlob = (dataURI) => {

	// Convert base64 to raw binary data held in a string
	const byteString = atob(dataURI.split(',')[1]);

	// Separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// Write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i += 1) {
		ia[i] = byteString.charCodeAt(i);
	}

	// Return image blob
	return new Blob([ab], { type: mimeString });
};

export const renderLogo = (uiMode) => {
	if (uiMode?.mode === UI_MODE_OPTIONS.DARK) {
		return `${process.env.CDN_URL}/public/assets/logos/lets-talk-logo-white.png`;
	}
	return `${process.env.CDN_URL}/public/assets/logos/lets-talk-logo.png`;
};
