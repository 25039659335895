/*
 * File: DragDrop.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on November 3, 2022 at 11:22 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 6, 2024 at 7:59 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Colors
import { ColorValues } from '../../styles/colors';


/**
 * Helpers
 */

const getColor = (props) => {
	if (props.isDragAccept) {
		return props.theme.layoutBorder;
	}
	if (props.isDragReject) {
		return ColorValues.statusDangerBase;
	}
	if (props.isFocused) {
		return props.theme.layoutBorder;
	}
	return props.theme.layoutBorder;
};

const getShadow = (props) => {
	if (props.isDragAccept) {
		return ColorValues.elementShadow;
	}
	return 'none';
};

const getBorder = (props) => {
	if (props.isDragAccept) {
		return `2px solid ${getColor(props)}`;
	}
	return `2px solid ${props.theme.primaryBackground}`;
};

const getBackgroundImage = (props) => {
	if (props.isDragAccept) {
		return 'none';
	}
	return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${getColor(props).replace('#', '')}' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='16' stroke-linecap='square'/%3e%3c/svg%3e")`;
};


/**
 * Styles
 */

export const DragContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 20px;
	border-width: 2px;
	border-radius: 10px;
	border-style: dashed;
	background-color: ${({ theme }) => theme.primaryBackground};
	outline: none;
	cursor: pointer;
	border: ${(props) => getBorder(props)};
	box-shadow: ${(props) => getShadow(props)};
    background-image: ${(props) => getBackgroundImage(props)};
`;
