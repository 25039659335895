/*
 * File: JourneyStone.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 1:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 100px;
	left: 100px;
	width: 200px;
	height: 200px;
`;

export const Target = styled.div`
	width: 10px;
	height: 10px;
	position: absolute;
	bottom: 0px;
	left: 50%;
`;

export const Stone = styled.img`
	width: 100%;
	z-index: 1;
	position: absolute;
	pointer-events: all !important;
	cursor: pointer;
`;

export const Content = styled.div`
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3px;
	max-width: 80%;
	pointer-events: none;
`;

export const LockIcon = styled(FontAwesomeIcon)`
	font-size: 1.5rem;
	margin-bottom: 5px;
`;

export const AvatarContainer = styled.div`
	display: flex;
	gap: 40px;
	position: absolute;
	left: 0px;
	top: 38px;
	width: 100%;
	align-items: center;
	justify-content: center;
	pointer-events: none;
`;

export const Avatar = styled.img`
	height: 38px;
	width: auto;
	z-index: 2;
	position: relative;
`;

export const AvatarDecoration = styled.div`
	position: absolute;
	border-radius: 50%;
	width: 33px;
	height: 33px;
	top: -4px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	background-color: ${({ theme }) => theme.brandTertiaryBase};
`;

export const AvatarOption = styled.div`
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;

	* {
		text-align: center;
	}
	p {
		position: relative;
	}
`;
