/*
 * File: UnitProgress.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: June 3, 2024 at 11:01 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

// Utilities
import { hexToRgb } from '../../../utilities/color';

// Hooks
import ProgressProvider from '../../hooks/ProgressProvider';

// Components
import { Typography } from '../Typography';

// Constants
import { ACTIVITY_MODULE_STATUSES, UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './UnitProgress.styles';
import { DarkTheme, LightTheme } from '../../styles/colors';
import 'react-circular-progressbar/dist/styles.css';


/**
 * Constants
 */

const PROGRESS_SIZE = 0.16;
const LABEL_SIZE = 0.014;


/**
 * Component
 */

export const UnitProgress = ({
	className, position, containerWidth, activityUnit, isDarkBackground
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get primary user unit progress
	const userUnitProgress = useMemo(() => {
		if (activityUnit) {

			// Get user parameters
			const { journey } = user;

			// Get module parameters
			const { id: unitId } = activityUnit;

			// Get unit
			const unit = journey?.find((unitObj) => unitObj.unit === unitId);
			if (unit) {

				// Get module progress
				const completedItems = unit.modules?.filter((moduleObj) => moduleObj.status === ACTIVITY_MODULE_STATUSES.COMPLETE);

				// Return unit progress
				return completedItems.length / unit.modules.length;
			}
		}
		return 0;
	}, [user, activityUnit]);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;
	const primaryColor = hexToRgb(currentTheme.brandQuaternaryBase);

	// Handle actions on component load
	useEffect(() => {}, []);

	// Render component
	return (
		<S.Wrapper
			className={className}
			style={{
				left: `${position.left * containerWidth}px`,
				top: `${position.top * containerWidth}px`,
				width: `${PROGRESS_SIZE * containerWidth}px`,
				height: `${PROGRESS_SIZE * containerWidth}px`
			}}
		>

			{/* Rewards Progress Circle */}
			<ProgressProvider valueStart={0} valueEnd={userUnitProgress * 100.0}>
				{(value) => (
					<CircularProgressbarWithChildren
						value={value}
						strokeWidth={4}
						styles={buildStyles({
							strokeLinecap: 'round',
							pathTransitionDuration: 0.5,
							pathColor: `rgba(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b}, 1)`,
							textColor: currentTheme.brandQuaternaryBase,
							trailColor: 'white',
							backgroundColor: 'transparent',
						})}
					>

						{/* Rewards Balance */}
						<S.UnitProgressContainer>
							<S.UnitProgressInner $isDarkBackground={isDarkBackground}>
								<Typography
									tag="p"
									weight="bold"
									className="animate"
									style={{ fontSize: `${LABEL_SIZE * containerWidth}px` }}
								>
									{activityUnit?.title}
									:
								</Typography>
								<Typography
									tag="p"
									weight="regular"
									className="animate"
									style={{ fontSize: `${LABEL_SIZE * containerWidth}px` }}
								>
									{activityUnit?.subtitle}
								</Typography>
							</S.UnitProgressInner>
						</S.UnitProgressContainer>

					</CircularProgressbarWithChildren>
				)}
			</ProgressProvider>

		</S.Wrapper>
	);
};


/**
 * Configuration
 */

UnitProgress.displayName = 'UnitProgress';
UnitProgress.propTypes = {
	className: PropTypes.string,
	position: PropTypes.shape(),
	containerWidth: PropTypes.number,
	activityUnit: PropTypes.shape(),
	isDarkBackground: PropTypes.bool
};
UnitProgress.defaultProps = {
	className: null,
	position: {},
	containerWidth: 1500,
	activityUnit: null,
	isDarkBackground: false
};
