/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on May 21, 2024 at 10:32 AM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 10:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const fetchQA = (data) => api.get('/content/qa', { params: data });

export const fetchResources = (data) => api.get('/content/resources', { params: data });

export const fetchTerms = (data) => api.get('/content/terms', { params: data });

export const fetchFunFact = (data) => api.get('/content/funfact', { params: data });

export const fetchFunFacts = (data) => api.get('/content/funfacts', { params: data });

export const submitQuestion = ({ question }) => api.post('/content/qa', { question });

export const submitResourceSuggestion = ({ suggestion }) => api.post('/content/resources', { suggestion });

export const updateFavoriteResources = ({ resourceIds }) => api.post('/content/resources/favorites', { resourceIds });
