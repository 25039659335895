/*
 * File: Dashboard.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 8, 2024 at 9:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card } from '../../../components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	position: relative;
	padding: 0px 0 0px;
	width: 100%;
	display: flex;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const JourneyLayer = styled.img`
	width: 100%;
	position: relative;
`;

export const LayerWrapper = styled.div`
	position: relative;
`;

export const WidgetContainer = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 3;
	display: flex;
	flex-direction: column;
	gap: 15px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		top: 3rem;
		right: 4rem;
	}
`;

export const RewardsProgressCard = styled(Card)`
	padding: 25px 10px 30px;
	width: 175px;
`;

export const LoadingContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background-color: white;
	z-index: 3;
`;

export const ScrollBar = styled.div`
	position: absolute;
	top: 1%;
	right: 0px;
	height: 98%;
	width: 12px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	z-index: 9;
	border-radius: 5px;
`;

export const Thumb = styled.div`
	width: 100%;
	height: 20px;
	background-color: ${({ theme }) => theme.secondaryButton};
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 10;
	border-radius: 5px;
	cursor: pointer;
`;
