/*
 * File: activities.slice.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on May 30, 2024 at 10:08 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: May 30, 2024 at 10:23 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Services
import {
	fetchActivityUnits as getActivityUnits,
} from '../../../services/activities';


/**
 * Functions
 */

export const fetchActivityUnits = createAsyncThunk('activities/fetchActivityUnits', async () => {
	try {

		// Fetch activity units
		const { data } = await getActivityUnits();

		// Return units
		return data.units;

	} catch (e) {

		// Return null
		return null;
	}
});


/**
 * Slices
 */

export const activitiesSlice = createSlice({
	name: 'activities',
	initialState: {
		units: {
			status: 'idle',
			value: [],
			error: null
		},
	},
	reducers: {
		updateActivityUnits: (state, action) => {

			// Set state
			state.units.value = action.payload;
			state.units.status = 'succeeded';
		},
		clearActivityUnits: (state) => {

			// Clear state
			state.units.value = [];
		},
	},
	extraReducers: {
		[fetchActivityUnits.pending]: (state) => {
			state.units.status = 'loading';
		},
		[fetchActivityUnits.fulfilled]: (state, action) => {
			state.units.status = 'succeeded';
			state.units.value = action.payload;
		},
		[fetchActivityUnits.rejected]: (state, action) => {
			state.units.status = 'failed';
			state.units.error = action.error.message;
		},
	},
});


/**
 * Exports
 */

export const {
	updateActivityUnits,
	clearActivityUnits
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
