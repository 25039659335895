/*
 * File: AccessCodeStep.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: April 29, 2024 at 3:06 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	max-width: 550px;
	margin: 0px auto;
	padding-bottom: 10px;

	.cardPadding {
		padding: 50px;
	}
	h2 {
		text-align: center;
		margin: 10px 0px 10px;
	}
	p {
		text-align: center;
   		margin-bottom: 40px;
		color: ${({ theme }) => theme.secondaryText};
	}
	& > p {
		margin-bottom: 40px;
	}
	& > button {
		margin-top: 30px;
	}
	button {
		width: 100%;
	}
`;

export const BottomActionContainer = styled.div`
	margin: 14px 0px 2px;
    text-align: center;
    width: 100%;

	button {
		padding: 0px;
		display: inline-block;
		width: auto;
	}
	p {
		margin: 0px;
		display: inline-block;

		span {
			font-size: inherit !important;
			color: ${({ theme }) => theme.brandPrimaryBase};
		}
	}
`;
