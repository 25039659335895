/*
 * File: CreateAccountIntroductionStep.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:25 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: May 18, 2024 at 1:06 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mobileBreakpoint } from '../../../../styles/constants';


/**
 * Styles
 */

export const StepContainer = styled.div`
	width: 100%;
	max-width: 650px;
	margin: 0px auto;

	@media only screen {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	.cardPadding {
		padding: 50px;
	}
	h2 {
		text-align: left;
		margin: 10px 0px 15px;
	}
	h3 {
		text-align: left;
		margin: 10px 0px 15px;
	}
	p {
		text-align: left;
		color: ${({ theme }) => theme.secondaryText};
		margin-bottom: 50px;
	}
	& > div > button {
		margin-top: 30px;
	}
	button {
		width: 100%;
	}
`;

export const IconContainer = styled.div`
	text-align: left;
	color: ${({ theme }) => theme.brandSecondaryBase};
	margin-bottom: 20px;

	svg {
		font-size: 2.4rem;
	}
`;
