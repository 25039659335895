/*
 * File: TutorialFlowStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: October 8, 2024 at 1:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	forwardRef, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { toastError } from '../../../../utilities/toaster';
import { renderLogo } from '../../../../utilities/image';

// Services
import { updateUserProfile } from '../../../../services/user';

// Store
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Card, Typography
} from '../../../../components';

// Constants
import { ROLES } from '../../../../../Constants';

// Styles
import * as S from './TutorialFlowStep.styles';


/**
 * Content
 */

const PARENT_SLIDES = [
	{
		id: 1,
		icon: ['fal', 'bullhorn'],
		iconColor: 'brandSecondaryBase',
		iconAngle: -25,
		title: 'This is Let’s Talk!',
		description: 'An app that will guide you and your child through a journey towards open discussions about puberty, bodies, sex, and relationships. The next screens will show you how to set up your profile and make the most of the app\'s features.',
	},
	{
		id: 2,
		icon: ['fal', 'mountain-sun'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Walk the Path',
		description: 'Start your journey on the path by creating your profile and completing a few parent-only steps to get familiar with the app. After completing these steps, invite your child to join by creating their username. After your child\'s profile is set up, you each complete steps on the path.',
		image: `${process.env.CDN_URL}/public/assets/slides/walk-the-path.png`
	},

	/* {
		id: 3,
		icon: ['fal', 'compass'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'How to Navigate',
		description: '• Step with a star: means you’ve finished that activity\n• Step highlighted in yellow is the next one you need to complete to move forward\n• Locked Step: A step with a lock icon is locked and will open only after finishing previous activities\n• Family Challenge: is an activity that parent and child need to complete together.\n• Unit Progress Tracker: Shows the unit content and how far you’ve come in completing your activities\n• Reward Stats: Keeps track of your achievements\n• Motivation: Provides extra interesting information if you’re curious!\n',
		image: `${process.env.CDN_URL}/public/assets/slides/how-to-walk-path.png`
	}, */
	{
		id: 3,
		icon: ['fal', 'mountain-sun'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Trek Together',
		description: 'After setting up your child\'s profile, you both can navigate the path at your own pace. The step you need to complete will always be highlighted in yellow to guide you. You can view your child\'s avatar and see their progress on the path at any time. The app provides joint activities and conversation starters to keep you connected. If you want to preview content before your child, just stay one step ahead on the path.',
		image: `${process.env.CDN_URL}/public/assets/slides/trek-together.png`
	},
	{
		id: 4,
		icon: ['fal', 'circle-check'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Complete Activities',
		description: 'Each step is introduced by a new character and has short, kid-friendly videos and activities.\n\nComplete all the activities in each step to earn a star and move to the next step in the journey.',
		image: `${process.env.CDN_URL}/public/assets/slides/complete-activities.png`
	},
	{
		id: 5,
		icon: ['fal', 'face-smile'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Rate Activities',
		description: 'After each activity, you’ll have the chance to rate it. Your feedback will help us continuously improve the app!',
		image: `${process.env.CDN_URL}/public/assets/slides/rate-activities.png`
	},
	{
		id: 6,
		icon: ['fal', 'book'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Build Communication',
		description: 'There is a Family Communication Journal within the app. Your child will start conversations and ask questions, giving you insights into their thoughts. Use it to respond and support their curiosity.',
		image: `${process.env.CDN_URL}/public/assets/slides/build-communication.png`
	},
	{
		id: 7,
		icon: ['fal', 'star'],
		iconColor: 'brandTertiaryBase',
		iconAngle: 0,
		title: 'Reward Your Child',
		description: 'When your child finishes the path, they can cash in their stars for a reward. Rewards can motivate kids to make it to the end, even if some of the topics are a little uncomfortable.\n\nYou\'ll choose the reward for your child when you set up your profile.',
		image: `${process.env.CDN_URL}/public/assets/slides/reward-your-child.png`
	},
	{
		id: 8,
		icon: ['fal', 'screwdriver-wrench'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Additional Resources',
		description: 'On the menu bar, you can access additional materials to enhance your learning experience.',
		image: `${process.env.CDN_URL}/public/assets/slides/additional-resources.png`
	},
	{
		id: 9,
		icon: ['fal', 'person-hiking'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Ready to Start Your Journey?',
		description: 'Tell us a bit more about yourself to get started!',
	},
];

const CHILD_SLIDES = (user) => [
	{
		id: 1,
		icon: ['fal', 'bullhorn'],
		iconColor: 'brandSecondaryBase',
		iconAngle: -25,
		title: 'Let’s Talk!',
		description: 'This is an app where you will learn more about your body, puberty, sex, and relationships. The next screens will show you more about how it works.',
	},
	{
		id: 2,
		icon: ['fal', 'mountain-sun'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Walk the Path',
		description: 'The path will take you step-by-step through topics kids your age need to know.\n\nWe’ll keep the content fast and fun. Don’t worry, no boring lectures!',
		image: `${process.env.CDN_URL}/public/assets/slides/journey-the-path.png`
	},

	/* {
		id: 3,
		icon: ['fal', 'compass'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'How to Navigate',
		description: '• Step with a star: means you’ve finished that activity\n• Step highlighted in yellow is the next one you need to complete to move forward\n• Locked Step: A step with a lock icon is locked and will open only after finishing previous activities\n• Family Challenge: is an activity that parent and child need to complete together.\n• Unit Progress Tracker: Shows the unit content and how far you’ve come in completing your activities\n• Reward Stats: Keeps track of your achievements\n• Motivation: Provides extra interesting information if you’re curious!\n',
		image: `${process.env.CDN_URL}/public/assets/slides/how-to-walk-path.png`
	}, */
	{
		id: 3,
		icon: ['fal', 'circle-check'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Complete Activities',
		description: 'Each step is introduced by a new character and has short, kid-friendly videos and activities.\n\nComplete all of the activities to earn a star and move to the next step.',
		image: `${process.env.CDN_URL}/public/assets/slides/complete-activities.png`
	},
	{
		id: 4,
		icon: ['fal', 'face-smile'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Rate Activities',
		description: 'After each activity, you’ll have the chance to rate it. Your feedback will help us continuously improve the app!',
		image: `${process.env.CDN_URL}/public/assets/slides/rate-activities.png`
	},
	{
		id: 5,
		icon: ['fal', 'book'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Build Communication',
		description: 'Use the journal to chat with your parent and ask questions.',
		image: `${process.env.CDN_URL}/public/assets/slides/build-communication-kid.png`
	},
	{
		id: 6,
		icon: ['fal', 'star'],
		iconColor: 'brandTertiaryBase',
		iconAngle: 0,
		title: 'Earn a Reward',
		description: `When you finish the path, you can cash in your stars for a reward your parent has set.\n\nYour reward is: ${user?.primaryReward?.description}!`,
		image: `${process.env.CDN_URL}/public/assets/slides/reward-your-child.png`
	},
	{
		id: 7,
		icon: ['fal', 'screwdriver-wrench'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Additional Resources',
		description: 'You’ll find extra info along the way to answer your questions about growing up.',
		image: `${process.env.CDN_URL}/public/assets/slides/additional-resources.png`
	},
	{
		id: 8,
		icon: ['fal', 'person-hiking'],
		iconColor: 'brandSecondaryBase',
		iconAngle: 0,
		title: 'Ready to Start Your Journey?',
		description: 'Tell us a bit more about yourself to get started!',
	},
];


/**
 * Component
 */

export const TutorialFlowStep = forwardRef(({
	updateStep, updateHeight, role, className
}, ref) => {

	// Create state handlers
	const [isLoading, setIsLoading] = useState(false);
	const [slideIndex, setSlideIndex] = useState(0);
	const [slideTransition, startSlideTransition] = useState(false);
	const [buttonTitle, setButtonTitle] = useState('Next');

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get action from hooks
	const dispatch = useDispatch();

	// Get current slide content
	const currentSlide = useMemo(() => {
		if (role === ROLES.PARENT) {
			return PARENT_SLIDES[slideIndex];
		}
		return CHILD_SLIDES(user)[slideIndex];

	}, [slideIndex, role]);

	// Handle next function
	const handleNext = async () => {

		// Check action type
		const slides = role === ROLES.PARENT ? PARENT_SLIDES : CHILD_SLIDES(user);
		if (slideIndex < slides.length - 1) {
			startSlideTransition(true);
			setTimeout(() => {
				setSlideIndex(slideIndex + 1);
				startSlideTransition(false);
			}, 400);
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Update user profile
		updateUserProfile({
			tutorialComplete: true
		}).then(async ({ data: { user: newUser } }) => {

			// Update user state
			dispatch(updateUser(newUser));

			// Update step
			if (role === ROLES.PARENT) {
				updateStep(5);
			} else {
				updateStep(4);
			}

			// Set loading state
			setIsLoading(false);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble saving your information. Give it another go.');
			}
		});
	};

	// Handle back function
	const handleBack = () => {

		// Go to previous step
		if (slideIndex === 0) {
			if (role === ROLES.PARENT) {
				updateStep(3);
			} else {
				updateStep(2);
			}
			return;
		}

		// Go back a slide
		startSlideTransition(true);
		setTimeout(() => {
			setSlideIndex(slideIndex - 1);
			startSlideTransition(false);
		}, 400);
	};

	// Handle actions on step change
	useEffect(() => {

		// Update button title
		const slides = role === ROLES.PARENT ? PARENT_SLIDES : CHILD_SLIDES(user);
		if (slideIndex === slides.length - 1) {
			setButtonTitle('Continue');
		}

		// Update height
		setTimeout(() => {
			updateHeight(true);
		}, 200);

		// Update height
		setTimeout(() => {
			updateHeight(true);
		}, 1000);

	}, [slideIndex]);

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Card */}
			<Card className="cardPadding">

				{/* Slide Container */}
				<S.SlideContainer $activeTransition={slideTransition}>
					<S.Slide>

						{/* Image */}
						{currentSlide.image && (
							<S.ImageColumn>
								<S.Image
									src={currentSlide.image}
									title={currentSlide.title}
									alt={currentSlide.title}
								/>
							</S.ImageColumn>
						)}

						{/* Video */}
						{currentSlide.video && <S.VideoColumn />}

						{/* Content */}
						<S.ContentColumn $columnMode={currentSlide.image || currentSlide.video}>

							{/* Icon */}
							<S.IconContainer
								$iconColor={currentSlide.iconColor}
								$iconAngle={currentSlide.iconAngle}
							>
								<FontAwesomeIcon icon={currentSlide.icon} />
							</S.IconContainer>

							{/* Title */}
							<Typography tag={currentSlide.image || currentSlide.video ? 'h3' : 'h2'} weight="bold">{currentSlide.title}</Typography>

							{/* Instructions */}
							<div><Typography tag="p" variation="1" weight="medium">{currentSlide.description}</Typography></div>

						</S.ContentColumn>

					</S.Slide>
				</S.SlideContainer>

				{/* Footer */}
				<S.Footer>

					{/* Spacer */}
					<S.Spacer>
						<Button
							disabled={isLoading}
							isLoading={isLoading}
							onClick={handleBack}
							size="small"
							variant="text"
						>
							<FontAwesomeIcon icon={['fas', 'arrow-left']} />
							<Typography variation="button-medium" weight="semibold">Go Back</Typography>
						</Button>
					</S.Spacer>

					{/* Next Button */}
					<S.ButtonContainer>
						<Button disabled={isLoading} isLoading={isLoading} onClick={handleNext} size="large">{buttonTitle}</Button>
					</S.ButtonContainer>

					{/* Logo */}
					<S.LogoContainer>
						<S.Logo
							src={renderLogo(uiMode)}
							title="Let's Talk Logo"
							alt="Let's Talk Logo"
							className="isNotMobile"
						/>
					</S.LogoContainer>

				</S.Footer>
			</Card>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

TutorialFlowStep.displayName = 'TutorialFlowStep';
TutorialFlowStep.propTypes = {
	updateStep: PropTypes.func.isRequired,
	updateHeight: PropTypes.func,
	className: PropTypes.string,
	role: PropTypes.string
};
TutorialFlowStep.defaultProps = {
	updateHeight: null,
	className: null,
	role: null
};
