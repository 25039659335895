/*
 * File: Sidebar.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 27, 2024 at 4:00 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { LocaleLink as BaseLink } from '../../LocaleLink';
import { IconButton } from '../../IconButton';

// Constants
import { AppNavSidebarWidth, AppNavTopbarHeight, mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Sidebar = styled.div`
	width: 98%;
	max-width: ${AppNavSidebarWidth}px;
	height: 100%;
	background-color: ${({ theme }) => theme.primaryBackground};
	border-right: 1px solid ${({ theme }) => theme.layoutBorder};
	padding: 25px 30px 60px 30px;
	overflow: auto;
	position: fixed;
	z-index: 2;

	& > div {
		flex-direction: column;
	}
	${({ isOverlay }) => (isOverlay && css`
		top: 0px;
	`)}
`;

export const SidebarSection = styled.div`
	padding: 20px 0;
	width: 100%;

	${({ showBorder }) => showBorder !== false && css`
		border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
 	`}
	* {
		text-align: left;
	}
	h5,
	.secondary {
		color: ${({ theme }) => theme.secondaryText};
	}
	button.minimal {
		margin: 15px 0px 0px;
		padding: 0px;
		font-weight: 600;
		font-size: 12px;
	}
	button.full {
		margin: 15px 0px 0px;
		width: 100%;
	}
`;

export const MobileNavigation = styled.div`
	margin-top: -25px;
	height: ${AppNavTopbarHeight}px;
	display: flex;
	align-items: center;
`;

export const NavigationToggle = styled(IconButton)`
	pointer-events: all;
`;

export const Link = styled(BaseLink)`
	text-decoration: none;
	margin: 0px 10px 8px 0px;
	display: flex;
	align-items: center;
	gap: 12px;
	opacity: 1;

	* {
		color: ${({ theme, $isActive }) => ($isActive ? theme.brandPrimaryBase : theme.secondaryText)}
	}
	&:hover {
		opacity: 0.7;
	}
	.promptBadge {
		& * {
			color: ${({ theme }) => theme.primaryBackground};
		}
		svg {
			width: 0.3rem;
			height: 0.3rem;
		}
	}
`;

export const LinkSection = styled.div`
	margin: 0px 0px 12px;
	display: flex;
	flex-direction: column;
	gap: 15px;
`;

export const LogoContainer = styled.div`
	width: 100%;
	text-align: left;
	margin-bottom: 6px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		text-align: center;
	}
`;

export const Logo = styled.img`
	width: 78px;
`;
