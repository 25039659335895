/*
 * File: FunFactModal.styles.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: June 9, 2024 at 8:36 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 0px 0px 30px;
	text-align: center;
	width: 100%;

	h5 {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const IconContainer = styled.div`
	svg {
		color: ${({ theme }) => theme.brandTertiaryBase};
		font-size: 2.2rem;
		margin: 0px 0px 10px;
	}
`;

export const ContentContainer = styled.div`
	min-height: 300px;
`;

export const Content = styled.div`
	margin: 20px 0px 0px;

	img {
		border-radius: 6px;
		max-width: 500px;
		width: 100%;
		margin: 15px auto 25px !important;
		display: flex;
	}
`;
