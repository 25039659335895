/*
 * File: SignUpStep.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 26, 2022 at 6:24 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 24, 2024 at 9:41 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { registerUser } from '../../../../services/authentication';

// Slices
import { updateUser } from '../../../../store/slices/user/user.slice';

// Components
import {
	Button, Checkbox, LocaleLink, PasswordInput, TextInput, Typography
} from '../../../../components';

// Styles
import * as S from './SignUpStep.styles';


/**
 * Component
 */

export const SignUpStep = forwardRef(({
	accessCode, className
}, ref) => {

	// Create state handlers
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [passwordRulesError, setPasswordRulesError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValues, setInputValues] = useState({});

	// Get actions from hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const stateLocale = useSelector((state) => state.locale.value);

	// Handle create account function
	const handleCreateAccount = async () => {

		// Get parameters
		const {
			email, password, confirmPassword, termsAgreed
		} = inputValues;

		// Validate parameters
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email' });
			return;
		}
		if (!password || validator.isEmpty(password, { ignore_whitespace: true }) || passwordError || passwordRulesError) {
			setPasswordError({ message: passwordError?.message ? passwordError?.message : 'Please create a password for your Let\'s Talk account' });
			return;
		}
		if (!confirmPassword || validator.isEmpty(confirmPassword, { ignore_whitespace: true }) || password !== confirmPassword) {
			setConfirmPasswordError({ message: 'Your passwords must match' });
			return;
		}
		if (!termsAgreed) {
			toastError(uiMode, 'You must agree to our Terms of Service before continuing.');
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Create user account
		registerUser({
			email, pass: password, code: accessCode
		}).then(({ data }) => {

			// Set loading state
			setIsLoading(false);

			// Dispatch new user
			dispatch(updateUser(data.user));

			// Move to onboarding
			navigate(`${stateLocale.localePath}/join/onboarding`);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);
			setEmailError(null);
			setPasswordError(null);
			setConfirmPasswordError(null);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble creating your account. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value, checked, type
		} = event.target;
		if (type === 'checkbox') {
			setInputValues({ ...inputValues, [name]: checked });
		} else {
			setInputValues({ ...inputValues, [name]: value });
		}
	};

	// Handle login action function
	const loginFunction = () => {
		navigate(`${stateLocale.localePath}/login`);
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Return component
	return (
		<S.StepContainer ref={ref} className={className}>

			{/* Content */}
			<Typography tag="h2" weight="bold">Sign Up</Typography>
			<Typography tag="p" variation="1" weight="regular">Let’s create a secure account for you. You’ll be able to create an account for your child once you get started in the app.</Typography>

			{/* Inputs */}
			<TextInput
				label="Email Address"
				name="email"
				type="text"
				placeholder="alex@acme.com"
				error={emailError}
				value={inputValues.email || ''}
				containerClassName="inputSpacer"
				autoComplete="email"
				onFocus={() => { setEmailError(null); }}
				onKeyUp={() => { setEmailError(null); }}
				onBlur={() => { setEmailError(null); }}
				onChange={handleOnChange}
			/>
			<PasswordInput
				label="Create a password"
				onChange={handleOnChange}
				setError={setPasswordError}
				setRulesError={setPasswordRulesError}
				autoComplete="new-password"
				containerClassName="inputSpacer"
				rulesError={passwordRulesError}
				value={inputValues.password || ''}
				disallowedContent={[
					inputValues.username,
					inputValues.email
				].filter(Boolean)}
				error={passwordError}
			/>
			<TextInput
				label="Confirm your password"
				type="password"
				name="confirmPassword"
				error={confirmPasswordError}
				value={inputValues.confirmPassword || ''}
				containerClassName="inputSpacer"
				autoComplete="new-password"
				onFocus={() => { setConfirmPasswordError(null); }}
				onKeyUp={() => { setConfirmPasswordError(null); }}
				onBlur={() => { setConfirmPasswordError(null); }}
				onChange={handleOnChange}
			/>

			{/* Checkboxes */}
			<S.CheckboxContainer>
				<S.CheckboxRow>
					<Checkbox
						name="termsAgreed"
						value={inputValues.termsAgreed || false}
						onChange={handleOnChange}
						isDark
					/>
					<Typography variation="2" weight="regular">
						I have read and agree to the Let&apos;s Talk
						{' '}
						<LocaleLink to="/legal/terms" target="_blank">Terms of Service</LocaleLink>
						{' and '}
						<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
					</Typography>
				</S.CheckboxRow>
			</S.CheckboxContainer>

			{/* Continue Button */}
			<Button disabled={isLoading} isLoading={isLoading} onClick={handleCreateAccount} size="large">Sign Up</Button>

			{/* Login Button */}
			<S.BottomActionContainer>
				<Button variant="text" size="small" onClick={loginFunction} disabled={isLoading}>
					<Typography tag="p" variation="2" weight="medium">
						Already have an account?
						{' '}
						<Typography weight="bold">
							Log In
						</Typography>
					</Typography>
				</Button>
			</S.BottomActionContainer>
		</S.StepContainer>
	);
});


/**
 * Configuration
 */

SignUpStep.displayName = 'SignUpStep';
SignUpStep.propTypes = {
	className: PropTypes.string,
	accessCode: PropTypes.string
};
SignUpStep.defaultProps = {
	className: null,
	accessCode: null
};
