/*
 * File: Button.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 27, 2024 at 3:08 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import { flatten } from 'lottie-colorify';
import { Howl } from 'howler';

// Animation
import animationData from '../../assets/animations/spinner.json';

// Constants
import { UI_MODE_OPTIONS } from '../../../Constants';

// Styles
import * as S from './Button.styles';
import { ColorValues, DarkTheme, LightTheme } from '../../styles/colors';


/**
 * Component
 */

export const Button = forwardRef(({
	children, size, variant, variation, type, className, isLoading, onClick, ...rest
}, ref) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Define current theme
	const currentTheme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme : LightTheme;

	// Create sound hook
	const sound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/click-2.mp3`],
		html5: true,
		volume: 0.2
	}), []);

	// Render animation data
	const renderAnimationData = () => {
		switch (variation) {
			case 'default': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.primaryButton, animationData);
				}
				return flatten(currentTheme.primaryButton, animationData);
			}
			case 'light': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryButton, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.primaryButton, animationData);
				}
				return flatten(currentTheme.primaryButton, animationData);
			}
			case 'white': {
				if (variant === 'solid') {
					return flatten(ColorValues.dark, animationData);
				} if (variant === 'outline') {
					return flatten(ColorValues.white, animationData);
				}
				return flatten(ColorValues.white, animationData);
			}
			case 'dark': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.darkButton, animationData);
				}
				return flatten(currentTheme.darkButton, animationData);
			}
			case 'secondary': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.secondaryButton, animationData);
				}
				return flatten(currentTheme.secondaryButton, animationData);
			}
			case 'warning': {
				if (variant === 'solid') {
					return flatten(currentTheme.primaryBackground, animationData);
				} if (variant === 'outline') {
					return flatten(currentTheme.statusDangerBase, animationData);
				}
				return flatten(currentTheme.statusDangerBase, animationData);
			}
			default:
				break;
		}
		return flatten(currentTheme.primaryButton, animationData);
	};


	// Render component
	return (
		<S.Button
			ref={ref}
			size={size}
			type={type}
			variant={variant}
			variation={variation}
			className={className ? `${className} animate` : 'animate'}
			onClick={() => {
				if (onClick) onClick();
				try { sound.play(); } catch (e) {}
			}}
			{...rest}
		>
			{isLoading && (
				<S.SpinnerContainer>
					<Lottie loop play animationData={renderAnimationData()} style={{ height: '100%' }} />
				</S.SpinnerContainer>
			)}
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, { style: { visibility: isLoading ? 'hidden' : 'visible' } });
				}
				return <S.Span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{child}</S.Span>;
			})}
		</S.Button>
	);
});


/**
 * Configuration
 */

Button.displayName = 'Button';
Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	size: PropTypes.oneOf(['large', 'medium', 'small']),
	variant: PropTypes.oneOf(['solid', 'outline', 'text']),
	variation: PropTypes.oneOf(['default', 'light', 'white', 'dark', 'secondary', 'tertiary', 'warning']),
	className: PropTypes.string,
	type: PropTypes.string,
	isLoading: PropTypes.bool,
	onClick: PropTypes.func
};
Button.defaultProps = {
	children: null,
	size: 'medium',
	variant: 'solid',
	variation: 'default',
	className: null,
	type: 'button',
	isLoading: false,
	onClick: null
};
