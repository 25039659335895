/*
 * File: index.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on April 29, 2024 at 2:06 PM
 * Copyright © 2024 Let's Talk. All rights reserved.
 *
 * Last Modified: April 29, 2024 at 2:09 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const validateAccessCode = ({ code }) => api.get('/access/validate', { params: { code } });
